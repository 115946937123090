import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import Collapsible from 'react-collapsible';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { BsChevronLeft } from 'react-icons/bs';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import title from '../../title.js';
import './style.css';

const LevelTwo = ({ category, back, closeSidebar }) => {
    const state = useSelector((state) => state);
    const history = useHistory();

    const storeInfo = state && state.storeInfo;
    const store_code = storeInfo && storeInfo.store_code;

    /** According Url redirect to PLP Or PDP */
    const gotoURL = (url, closeMenu = true) => {
        if (!url) return;
        if (closeMenu) closeSidebar();
        history.push(`/${store_code}/${url}`);
    };

    const createL3FromTagging = (childrens) => {
        if (!childrens || !Array.isArray(childrens)) return null;
        let categoryObject = {
            by_gender: {
                title: title.gender,
                categories: [],
            },
            by_category: {
                title: title.category,
                categories: [],
            },
            by_brand: {
                title: title.brand,
                categories: [],
            },
            by_trend: {
                title: title.trend,
                categories: [],
            },
            null: {
                title: '',
                categories: [],
            },
        };

        childrens &&
            childrens.forEach((cat) => categoryObject[cat.tagging] && categoryObject[cat.tagging].categories.push(cat));

        return (
            <>
                <div className="mob-l3-category-main-container">
                    {Object.values(categoryObject).map((category) => {
                        if (!category.categories || category.categories.length === 0) return null;
                        return (
                            <div className="mob-l3-category-div">
                                {category.title && <h6 className="title">{category.title}</h6>}
                                {category.categories.map((subCat, index) => {
                                    return (
                                        <>
                                            <div
                                                className="category-name py-1 mb-0"
                                                onClick={() => gotoURL(subCat.url_key)}
                                            >
                                                <Link key={`${subCat}-${index}`}>
                                                    <Typography className=" menu-item-label">{subCat.name}</Typography>
                                                </Link>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };

    const createL2 = (category, index) => (
        <>
            <Collapsible
                triggerDisabled={!category.sub_children}
                trigger={
                    <Grid
                        index={`l2-${index}`}
                        className="category-name sub-category-name position-relative menu_sub"
                        onClick={() => {
                            if (!category.sub_children && category.children[0]) gotoURL(category.children[0].url_key);
                        }}
                    >
                        {category.children[0] && category.children[0].name}
                        {category.sub_children && (
                            <span className="position-absolute">
                                <AiOutlineCaretDown />
                            </span>
                        )}
                    </Grid>
                }
            >
                {category.sub_children && createL3FromTagging(category.sub_children)}
                <div className="view-all-l2" onClick={() => gotoURL(category.children[0].url_key)}>
                    <FormattedMessage id="ViewAll" defaultMessage="View all" />
                    &nbsp;{category.children[0] && category.children[0].name}
                </div>
            </Collapsible>
        </>
    );
    const categoryGroupByTag = (category) => {
        let categoryObject = {
            by_gender: {
                title: title.gender,
                categories: [],
            },
            by_category: {
                title: title.category,
                categories: [],
            },
            by_brand: {
                title: title.brand,
                categories: [],
            },
            by_trend: {
                title: title.trend,
                categories: [],
            },
            null: {
                title: title.more,
                categories: [],
            },
        };

        category &&
            category[1] &&
            Object.values(category[1]).forEach((L2category, index) => {
                categoryObject[L2category.children[0].tagging] &&
                    categoryObject[L2category.children[0].tagging].categories.push(L2category);
            });
        const L3_TYPE = [title.gender, title.category, title.brand, title.trend];

        categoryObject.isPureL2 = Object.values(categoryObject).every((item, index) => {
            if (L3_TYPE.includes(item.title) && item.categories.length > 0) {
                return false;
            }
            return true;
        });

        return [categoryObject, categoryObject.isPureL2];
    };

    return (
        <Grid>
            <Grid className="category-container">
                <Grid className="l2-category-header category-name">{category && category[0] && category[0].name}</Grid>
                <IconButton className="menu-back-button" color="inherit" aria-label="back" onClick={() => back('L1')}>
                    <BsChevronLeft></BsChevronLeft>
                </IconButton>
            </Grid>
            <Grid className="view-all-button-container">
                <Button onClick={() => gotoURL(category[0].url_key)} className="view-all-button">
                    <FormattedMessage id="Home.ViewAll.Text" defaultMessage="View all" />
                </Button>
            </Grid>
            <Grid className="category-container">
                <div className="mob-l3-category-main-container">
                    {categoryGroupByTag(category)[1]
                        ? category[1] &&
                          Object.entries(category[1]).map((child, index) => child[1] && createL2(child[1], index))
                        : Object.values(categoryGroupByTag(category)[0]).map((category) => {
                              if (!category.categories || category.categories.length === 0) return null;
                              return (
                                  <>
                                      <div className="mob-l3-category-div">
                                          {category.title && <h6 className="title">{category.title}</h6>}
                                          {category &&
                                              category.categories &&
                                              category.categories.length !== 0 &&
                                              category.categories.map(
                                                  (child, indexs) => child && createL2(child, indexs),
                                              )}
                                      </div>
                                  </>
                              );
                          })}
                </div>
            </Grid>
        </Grid>
    );
};

export default LevelTwo;
