import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../api/index';
import { ApiErrorToast, ApiFalseMessageToast } from '../../../../utils/CommonApiMessage';
import { actions, types } from './actions';

const storeLocatorRequest = function* storeLocatorRequest({ payload }) {
    try {
        const { data } = yield call(api.getstoreLocator, payload);
        if (data && data.status) {
            yield put(actions.storeLocatorRequestSuccess(data && data.data));
        } else {
            ApiFalseMessageToast();
            yield put(actions.storeLocatorRequestFailed());
        }
    } catch (err) {
        yield put(actions.storeLocatorRequestFailed());
        ApiErrorToast(err);
    }
};

export default function* sagas() {
    yield takeLatest(types.STORE_LOCATOR_REQUEST, storeLocatorRequest);
}
