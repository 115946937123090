import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandlers = {
    [types.GET_PDP_DATA_REQ]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_PDP_DATA_REQ_FAILED]: (state) => ({
        ...state,
        loading: false,
        product: null,
    }),
    [types.GET_PDP_DATA_REQ_SUCCESS]: (state, { payload }) => {
        return {
            ...state,
            loading: false,
            product: payload || {},
        }
    },
    [types.GET_PDP_TRENDING_DATA_REQ]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_PDP_TRENDING_DATA_REQ_SUCCESS]: (state, { payload }) => {
        return {
            ...state,
            loading: false,
            product: {
                ...state.product,
                like_data: payload
            }
        }
    },
    [types.GET_PDP_TRENDING_DATA_REQ_FAILED]: (state, { payload }) => {
        return {
            ...state,
            loading: false,
        }
    },
    [types.GET_PDP_BEST_SELLER_DATA_REQ]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_PDP_BEST_SELLER_DATA_REQ_SUCCESS]: (state, { payload }) => {
        return {
            ...state,
            loading: false,
            product: {
                ...state.product,
                bought_data: payload
            }
        }
    },
    [types.GET_PDP_BEST_SELLER_DATA_REQ_FAILED]: (state, { payload }) => {
        return {
            ...state,
            loading: false,
        }
    },
    [types.GET_QUICK_PDP_DATA_REQ]: (state) => ({
        ...state,
        loadingQ: true,
    }),
    [types.GET_QUICK_PDP_DATA_REQ_FAILED]: (state) => ({
        ...state,
        loadingQ: false,
        productQ: null,
    }),
    [types.GET_QUICK_PDP_DATA_REQ_SUCCESS]: (state, { payload }) => ({
        ...state,
        loadingQ: false,
        productQ: payload || {},
    }),
    [types.ADD_TO_CART_REQ]: (state) => ({
        ...state,
        cartLoading: true,
    }),
    [types.ADD_TO_CART_REQ_SUCCESS]: (state) => ({
        ...state,
        cartLoading: false,
    }),
    [types.ADD_TO_CART_REQ_FAILED]: (state) => ({
        ...state,
        cartLoading: false,
    }),

    [types.NOTIFY_ME_REQ]: (state) => ({
        ...state,
        notifyMeLoading: true,
    }),
    [types.NOTIFY_ME_REQ_SUCCESS]: (state) => ({
        ...state,
        notifyMeLoading: false,
    }),
    [types.NOTIFY_ME_REQ_FAILED]: (state) => ({
        ...state,
        notifyMeLoading: false,
    }),
};

export default handleActions(actionHandlers, {
    loading: false,
    product: {},
    loadingQ: false,
    productQ: {},
    cartLoading: false,
    notifyMeLoading: false,
});
