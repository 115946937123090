import { call, put, takeLatest } from 'redux-saga/effects';
import { ProductMeasuresPlp, routesgtmevents } from 'service/GTMEvent';
import { ApiErrorToast, ApiFalseMessageToast } from '../../../utils/CommonApiMessage';
import api from '../api';
import { getUrlKey } from '../utils';
import LocalStorage from './../../../utils/LocalStorage';
import { actions, types } from './actions';
let _ = require('lodash');
let plpProduct = [];
let pervious = 0;

const filterRemove = async function (urlkey) {
    if (!window.localStorage.getItem('url')) {
        window.localStorage.setItem(
            'url',
            urlkey === 'search'
                ? `query=${new URLSearchParams(window.location.search).get('query')}`
                : window.location.pathname.includes('search')
                    ? `query=${new URLSearchParams(window.location.search).get('query')}`
                    : window.location.pathname,
        );
    } else {
        if (
            window.localStorage
                .getItem('url')
                .includes(
                    urlkey === 'search'
                        ? `query=${new URLSearchParams(window.location.search).get('query')}`
                        : window.location.pathname.includes('search')
                            ? `query=${new URLSearchParams(window.location.search).get('query')}`
                            : window.location.pathname,
                )
        ) {
            pervious = 1;
        } else {
            LocalStorage.removeFilter();

            window.localStorage.setItem(
                'url',
                urlkey === 'search'
                    ? `query=${new URLSearchParams(window.location.search).get('query')}`
                    : window.location.pathname.includes('search')
                        ? `query=${new URLSearchParams(window.location.search).get('query')}`
                        : window.location.pathname,
            );
            pervious = 0;
        }
    }
};
const urlkey = getUrlKey();

const getAllProductsReq = function* getAllProductsReq({ payload }) {
    try {
        const {
            url_key,
            storeId,
            page,
            limit,
            gender,
            size,
            price,
            brand,
            brands,
            gender_pim,
            size_pim,
            size_system_pim,
            category,
            product_list_order,
            filter,
            loadMoreButton,
        } = payload;

        filterRemove(url_key);

        let obj = {};

        if (pervious) {
            obj = {
                gender_pim: gender_pim,
                size_system_pim: size_system_pim,
                size_pim: size_pim,
                gender: gender,
                size: size,
                price: price,
                brand: brand,
                brands: brands,
                category: category,
                product_list_order: product_list_order,
                p: page,
                url_key: url_key,
                storeid: storeId,
            };
        } else {
            obj = { product_list_order: product_list_order, p: page, url_key: url_key, storeid: storeId };
        }

        const data = yield call(api.getProducts, obj);

        if (data && data.data.status) {
            if (data.data.data) {
                yield put(actions.getAllProductRequestSuccess(data.data.data));
                const products = data.data.data && data.data.data.product_data;

                if (pervious) {
                    if (loadMoreButton) {
                        plpProduct = plpProduct.concat(products);
                    } else {
                        plpProduct = products.concat([]);
                    }
                } else {
                    plpProduct = products.concat([]);
                }
                plpProduct = _.uniqBy(plpProduct, 'productid');
                const urlkeys = getUrlKey();
                if (products) {
                    if (urlkeys === 'productList') {
                        routesgtmevents(window.location.href, data.data.data && data.data.data.meta_title, 'category');
                        ProductMeasuresPlp(products, 'category');
                        yield put(actions.plpProducts(plpProduct));
                        yield put(actions.plpProductsPage(page));
                    } else if (urlkeys === 'brand') {
                        routesgtmevents(
                            window.location.href,
                            data.data &&
                            data.data.data &&
                            data.data.data.branddata &&
                            data.data.data.branddata.meta_title,
                            'other',
                        );
                        ProductMeasuresPlp(products, 'brand');
                    } else if (urlkeys === 'search') {
                        routesgtmevents(window.location.href, 'Search page', 'searchresults');
                        ProductMeasuresPlp(products, 'searchresults');
                    }
                }
            } else {
                if (pervious) {
                    yield put(actions.perviousProductSaved());
                    // toast.info('No More data found for this filter');
                } else {
                    ApiFalseMessageToast();

                    yield put(actions.getAllProductRequestFailed());
                }
            }
        } else {
            ApiFalseMessageToast();

            yield put(actions.getAllProductRequestFailed());
        }
    } catch (err) {
        yield put(actions.getAllProductRequestFailed());
        ApiErrorToast(err);
    }
};

const getSearchProductReq = function* getSearchProductReq({ payload }) {
    try {
        const { q, storeId, page, limit, gender, size, price, brand, brands, category, product_list_order, loadMoreButton, gender_pim,
            size_pim,size_system_pim } =
            payload;
        let obj = {};
        filterRemove(urlkey);

        if (pervious) {
            obj = {
                gender_pim: gender_pim,
                size_system_pim: size_system_pim,
                size_pim: size_pim,
                gender: gender,
                size: size,
                price: price,
                brand: brand,
                brands: brands,
                category: category,
                product_list_order: product_list_order,
                p: page,
                storeid: storeId,
                q: q,
            };
        } else {
            obj = { p: page, q: q, storeid: storeId };
        }

        const data = yield call(api.getPLPSearch, obj);

        if (data && data.data.status) {
            if (data.data.data) {
                yield put(actions.getAllProductRequestSuccess(data.data.data));

                const products = data.data.data && data.data.data.product_data;
                if (pervious) {
                    if (loadMoreButton) {
                        plpProduct = plpProduct.concat(products);
                    } else {
                        plpProduct = products.concat([]);
                    }
                } else {
                    plpProduct = products.concat([]);
                }
                plpProduct = _.uniqBy(plpProduct, 'productid');
                const urlkeys = getUrlKey();
                if (products) {
                    if (urlkeys === 'productList') {
                        routesgtmevents(window.location.href, data.data.data && data.data.data.meta_title, 'category');
                        ProductMeasuresPlp(products, 'category');
                    } else if (urlkeys === 'brand') {
                        routesgtmevents(
                            window.location.href,
                            data.data &&
                            data.data.data &&
                            data.data.data.branddata &&
                            data.data.data.branddata.meta_title,
                            'other',
                        );
                        ProductMeasuresPlp(products, 'brand');
                    } else if (urlkeys === 'search') {
                        routesgtmevents(window.location.href, 'Search page', 'searchresults');
                        ProductMeasuresPlp(products, 'searchresults');
                        yield put(actions.plpProducts(plpProduct));
                        yield put(actions.plpProductsPage(page));
                    }
                }
            } else {
                if (pervious) {
                    yield put(actions.perviousProductSaved());
                    // toast.info('No More data found for this filter');
                } else {
                    ApiFalseMessageToast();
                    let bestseller = data.data.bestseller;

                    yield put(actions.getAllProductRequestFailed(bestseller));
                }
            }
        }
    } catch (err) {
        yield put(actions.getAllProductRequestFailed());
        ApiErrorToast(err);
    }
};

const getBrandProductReq = function* getBrandProductReq({ payload }) {
    try {
        const {
            url_key,
            storeId,
            page,
            limit,
            gender,
            size,
            price,
            brand,
            brands,
            category,
            product_list_order,
            filter,
            loadMoreButton,
            gender_pim,
            size_system_pim,
            size_pim
        } = payload;

        filterRemove(url_key);

        let obj = {};

        if (pervious) {
            obj = {
                gender_pim: gender_pim,
                size_system_pim: size_system_pim,
                size_pim: size_pim,
                gender: gender,
                size: size,
                price: price,
                brand: brand,
                brands: brands,
                category: category,
                product_list_order: product_list_order,
                p: page,
                url_key: url_key,
                store_id: storeId,
            };
        } else {
            obj = { product_list_order: product_list_order, p: page, url_key: url_key, store_id: storeId };
        }

        const data = yield call(api.getPLPBrand, obj);

        if (data && data.status) {
            if (data.data.data) {
                yield put(actions.getAllProductRequestSuccess(data.data.data));
                const products = data.data.data && data.data.data.product_data;

                if (pervious) {
                    if (loadMoreButton) {
                        plpProduct = plpProduct.concat(products);
                    } else {
                        plpProduct = products.concat([]);
                    }
                } else {
                    plpProduct = products.concat([]);
                }
                plpProduct = _.uniqBy(plpProduct, 'productid');

                const urlkeys = getUrlKey();
                if (products) {
                    if (urlkeys === 'productList') {
                        routesgtmevents(window.location.href, data.data.data && data.data.data.meta_title, 'category');
                        ProductMeasuresPlp(products, 'category');
                    } else if (urlkeys === 'brand') {
                        routesgtmevents(
                            window.location.href,
                            data.data &&
                            data.data.data &&
                            data.data.data.branddata &&
                            data.data.data.branddata.meta_title,
                            'other',
                        );
                        ProductMeasuresPlp(products, 'brand');
                        yield put(actions.plpProducts(plpProduct));
                        yield put(actions.plpProductsPage(page));
                    } else if (urlkeys === 'search') {
                        routesgtmevents(window.location.href, 'Search page', 'searchresults');
                        ProductMeasuresPlp(products, 'searchresults');
                    }
                }
            } else {
                if (pervious) {
                    yield put(actions.perviousProductSaved());
                    // toast.info('No More data found for this filter');
                } else {
                    ApiFalseMessageToast();

                    yield put(actions.getAllProductRequestFailed());
                }
            }
        } else {
            yield put(actions.getAllProductRequestFailed());
        }
    } catch (err) {
        yield put(actions.getAllProductRequestFailed());
        ApiErrorToast(err);
    }
};

export default function* sagas() {
    yield takeLatest(types.GET_ALL_PRODUCTS_REQUEST, getAllProductsReq);
    yield takeLatest(types.GET_BRAND_PLP_PRODUCT_REQUEST, getBrandProductReq);
    yield takeLatest(types.GET_SEARCH_PLP_PRODUCT_REQUEST, getSearchProductReq);
}
