import { Grid, makeStyles, Typography } from '@material-ui/core/';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import L1PopupMenu from './L1PopupMenu';

const useStyles = makeStyles((theme) => ({
    links: {
        textDecoration: 'none',
    },
}));

function MenuList() {
    const [L1Open, setL1Open] = React.useState(false);
    const [category, setcategory] = React.useState('');
    const classes = useStyles();
    const history = useHistory();
    const L1handleClose = (value) => {
        setL1Open(!L1Open);
        setcategory(value);
    };
    const menuList = useSelector((state) => state && state.menu && state.menu.menuList && state.menu.menuList.data);
    const store_code = useSelector((state) => state && state.storeInfo && state.storeInfo.store_code);
    return (
        <div>
            <div className="nav-link" style={{ marginTop: '1rem', backgroundColor: '#00000' }}>
                <Grid container className="hide_scrollBar" style={{ flexWrap: 'inherit', overflow: 'auto' }}>
                    {menuList &&
                        Object.entries(menuList).map((category, index) => {
                            return (
                                <div
                                    style={{ marginBottom: '20px' }}
                                    onClick={() =>
                                        category[1][0].url_key !== 'blog'
                                            ? category[1] &&
                                              category[1][1] &&
                                              category[1][1][1] &&
                                              category[1][1][1].sub_children
                                                ? L1handleClose(category[1])
                                                : history.push(`/${store_code}/${category[1][0].url_key}`)
                                            : window.open(process.env.REACT_APP_BLOG_URL, '_blank')
                                    }
                                >
                                    <div key={index} className="nav-link-dropdown">
                                        {/* <Link
                                                    to={`/${store_code}/${category[1][0].url_key}`}
                                                    className={classes.links}
                                                    style={{ color: '#000' }}
                                                > */}
                                        <Typography
                                            className="nav-link-name"
                                            component="a"
                                            align="left"
                                            display="block"
                                            style={{ color: '#000', width: 'max-content' }}
                                        >
                                            {category[1][0].name}
                                        </Typography>
                                        {/* </Link> */}
                                    </div>
                                </div>
                            );
                        })}
                    <L1PopupMenu open={L1Open} handleClose={L1handleClose} category={category} />
                    <div className="nav-link-dropdown">
                        <Link to={`/${store_code}/brands`} className={classes.links} style={{ color: '#000' }}>
                            <Typography
                                align="left"
                                display="block"
                                className="nav-link-name"
                                component="a"
                                style={{ color: '#000', width: 'max-content' }}
                            >
                                <FormattedMessage id="NavigationBar.Brands.Text" defaultMessage="Brands" />
                            </Typography>
                        </Link>
                    </div>
                </Grid>
            </div>
        </div>
    );
}

export default MenuList;
