import { createAction } from 'redux-actions';

const GET_CONTACT_US_DATA = 'UJ/GET_CONTACT_US_DATA';
const GET_CONTACT_US_DATA_SUCCESS = 'UJ/GET_CONTACT_US_DATA_SUCCESS';
const GET_CONTACT_US_DATA_FAILED = 'UJ/GET_CONTACT_US_DATA_FAILED';

const GET_CMS_PAGE_DATA_REQUEST = 'UJ/GET_CMS_PAGE_DATA_REQUEST';
const GET_CMS_PAGE_DATA_REQUEST_SUCCESS = 'UJ/GET_CMS_PAGE_DATA_REQUEST_SUCCESS';
const GET_CMS_PAGE_DATA_REQUEST_FAILED = 'UJ/GET_CMS_PAGE_DATA_REQUEST_FAILED';

const GET_FAQ_DATA_REQUEST = 'UJ/GET_FAQ_DATA_REQUEST';
const GET_FAQ_DATA_REQUEST_SUCCESS = 'UJ/GET_FAQ_DATA_REQUEST_SUCCESS';
const GET_FAQ_DATA_REQUEST_FAILED = 'UJ/GET_FAQ_DATA_REQUEST_FAILED';

const GET_PRIVACY_DATA = 'UJ/GET_PRIVACY_DATA';
const GET_PRIVACY_DATA_SUCCESS = 'UJ/GET_PRIVACY_SUCCESS';
const GET_PRIVACY_DATA_FAILED = 'UJ/GET_PRIVACY_FAILED';

const GET_ABOUT_US_DATA = 'UJ/GET_ABOUT_US_DATA';
const GET_ABOUT_US_DATA_SUCCESS = 'UJ/GET_ABOUT_US_SUCCESS';
const GET_ABOUT_US_DATA_FAILED = 'UJ/GET_ABOUT_US_FAILED';

const getContactUsData = createAction(GET_CONTACT_US_DATA);
const getContactUsDataSuccess = createAction(GET_CONTACT_US_DATA_SUCCESS);
const getContactUsDataFailed = createAction(GET_CONTACT_US_DATA_FAILED);

const getCmsPageDataReq = createAction(GET_CMS_PAGE_DATA_REQUEST);
const getCmsPageDataReqSuccess = createAction(GET_CMS_PAGE_DATA_REQUEST_SUCCESS);
const getCmsPageDataReqFailed = createAction(GET_CMS_PAGE_DATA_REQUEST_FAILED);

const getFaqDataReq = createAction(GET_FAQ_DATA_REQUEST);
const getFaqDataReqSuccess = createAction(GET_FAQ_DATA_REQUEST_SUCCESS);
const getFaqDataReqFailed = createAction(GET_FAQ_DATA_REQUEST_FAILED);

const getPrivacyData = createAction(GET_PRIVACY_DATA);
const getPrivacyDataSuccess = createAction(GET_PRIVACY_DATA_SUCCESS);
const getPrivacyDataFailed = createAction(GET_PRIVACY_DATA_FAILED);

const getAboutUsData = createAction(GET_ABOUT_US_DATA);
const getAboutUsDataSuccess = createAction(GET_ABOUT_US_DATA_SUCCESS);
const getAboutUsDataFailed = createAction(GET_ABOUT_US_DATA_FAILED);

export const actions = {
    getContactUsData,
    getContactUsDataSuccess,
    getContactUsDataFailed,

    getCmsPageDataReq,
    getCmsPageDataReqSuccess,
    getCmsPageDataReqFailed,

    getFaqDataReq,
    getFaqDataReqSuccess,
    getFaqDataReqFailed,

    getPrivacyData,
    getPrivacyDataSuccess,
    getPrivacyDataFailed,

    getAboutUsData,
    getAboutUsDataSuccess,
    getAboutUsDataFailed,
};

export const types = {
    GET_CONTACT_US_DATA,
    GET_CONTACT_US_DATA_SUCCESS,
    GET_CONTACT_US_DATA_FAILED,

    GET_CMS_PAGE_DATA_REQUEST,
    GET_CMS_PAGE_DATA_REQUEST_SUCCESS,
    GET_CMS_PAGE_DATA_REQUEST_FAILED,

    GET_FAQ_DATA_REQUEST,
    GET_FAQ_DATA_REQUEST_SUCCESS,
    GET_FAQ_DATA_REQUEST_FAILED,

    GET_PRIVACY_DATA,
    GET_PRIVACY_DATA_SUCCESS,
    GET_PRIVACY_DATA_FAILED,

    GET_ABOUT_US_DATA,
    GET_ABOUT_US_DATA_SUCCESS,
    GET_ABOUT_US_DATA_FAILED,
};
