import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandlers = {
    [types.SAVE_GUEST_USER]: (state, { payload }) => {
        return {
            ...state,
            guestUserInfo: {
                ...state.guestUserInfo,
                ...payload
            },
        }
    },
    [types.RESET_GUEST_USER]: (state) => {
        return {
            ...state,
            guestUserInfo: {
                firstName: undefined,
                lastName: undefined,
                email: undefined,
                password: undefined,
                confirmpassword: undefined,
            },
        }
    },
};

export default handleActions(actionHandlers, {
    guestUserInfo: {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        password: undefined,
        confirmpassword: undefined,
    }
});
