import { actions as wishlistActions } from 'components/account/WishList/redux/actions';
import { actions as cartActions } from 'components/main/cart/Basket/redux/actions';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ApiErrorToast, ApiFalseMessageToast } from '../../../../utils/CommonApiMessage';
import { actions as jwtAction } from '../../../main/cart/Checkout/Payment/redux/actions';
import { actions as storeAction } from '../../../storeInfo/redux/actions';
import api from '../../api';
import { actions, types } from './actions';

const changePasswordReq = function* changePasswordReq({ payload }) {
    try {
        const { data } = yield call(api.changePassword, payload.reqData);
        if (data && data.status) {
            toast.success(data.message);
            yield put(actions.changePasswordRequestSuccess());
            yield put(storeAction.setLoginUserDetails(null));
            yield put(storeAction.ecomLogin(false));
            payload.history.push(`/${payload.store_code}/sign-in`);
        } else {
            ApiFalseMessageToast();
            yield put(actions.changePasswordRequestFailed());
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.changePasswordRequestFailed());
    }
};

const updateCustomerRequest = function* updateCustomerRequest({ payload }) {
    try {
        const { data } = yield call(api.updateCustomer, payload[0]);
        if (data && data.status) {
            toast.success(data.message);

            yield put(actions.updateCustomerRequestSuccess());

            if (!payload[1].condition) {
                yield put(storeAction.setLoginUserDetails(null));
                yield put(storeAction.setQuoteId(null));
                yield put(wishlistActions.getAllWishListRequestSuccess(null));
                yield put(cartActions.getMyCartRequestSuccess(null));
                yield put(storeAction.setGDPRData(null));
                yield put(jwtAction.setOrderJWTToken(null));
                yield put(storeAction.ecomLogin(false));

                //payload[1].history.push(`/${payload.store_code}/sign-in`);
            } else {
                yield put(
                    actions.getCustomerRequest({
                        customerid: payload[0].customerid,
                        store_id: payload[0].store_id,
                        quote_id: payload[0].quote_id,
                    }),
                );
            }
        } else {
            ApiFalseMessageToast(data.message, 1);
            yield put(actions.updateCustomerRequestFailed());
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.updateCustomerRequestFailed());
    }
};

const loginUser = (state) => state && state.storeInfo && state.storeInfo.loginUser;
const quoteid = (state) => state && state.storeInfo && state.storeInfo.quote_id;

const getCustomerRequest = function* getCustomerRequest({ payload }) {
    try {
        const { data } = yield call(api.getCustomer, payload);
        const login_user = yield select(loginUser);
        const quote_id = yield select(quoteid);
        if (data && data.status) {
            let custData = {
                ...data.customer_details,
                quote_id: quote_id,
            };
            yield put(storeAction.setLoginUserDetails(custData));
            yield put(actions.getCustomerRequestSuccess());
        } else {
            ApiFalseMessageToast();
            yield put(actions.getCustomerRequestFailed());
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.getCustomerRequestFailed());
    }
};

export default function* sagas() {
    yield takeLatest(types.CHANGE_PASSWORD_REQUEST, changePasswordReq);
    yield takeLatest(types.UPDATE_CUSTOMER_REQUEST, updateCustomerRequest);
    yield takeLatest(types.GET_CUSTOMER_REQUEST, getCustomerRequest);
}
