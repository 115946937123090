import { createAction } from 'redux-actions';

const REGISTER_USER_REQUEST = 'UJ/REGISTER_USER_REQUEST';
const REGISTER_USER_REQUEST_SUCCESS = 'UJ/REGISTER_USER_REQUEST_SUCCESS';
const REGISTER_USER_REQUEST_FAILED = 'UJ/REGISTER_USER_REQUEST_FAILED';

const registerUserRequest = createAction(REGISTER_USER_REQUEST);
const registerUserRequestSuccess = createAction(REGISTER_USER_REQUEST_SUCCESS);
const registerUserRequestFailed = createAction(REGISTER_USER_REQUEST_FAILED);

export const actions = {
    registerUserRequest,
    registerUserRequestSuccess,
    registerUserRequestFailed,
};

export const types = {
    REGISTER_USER_REQUEST,
    REGISTER_USER_REQUEST_SUCCESS,
    REGISTER_USER_REQUEST_FAILED,
};
