import { createAction } from 'redux-actions';

const GET_ORDER_SUMMARY_REQ = 'UJ/GET_ORDER_SUMMARY_REQ';
const GET_ORDER_SUMMARY_REQ_SUCCESS = 'UJ/GET_ORDER_SUMMARY_REQ_SUCCESS';
const GET_ORDER_SUMMARY_REQ_FAILED = 'UJ/GET_ORDER_SUMMARY_REQ_FAILED';

const GET_REDIRECT_ORDER_SUMMARY_REQ = 'UJ/GET_REDIRECT_ORDER_SUMMARY_REQ';

const GET_RETRY_PAYMENT_REQ = 'UJ/GET_RETRY_PAYMENT_REQ';
const GET_RETRY_PAYMENT_REQ_SUCCESS = 'UJ/GET_RETRY_PAYMENT_REQ_SUCCESS';
const GET_RETRY_PAYMENT_REQ_FAILED = 'UJ/GET_RETRY_PAYMENT_REQ_FAILED';

const getRetryPaymentInfoReq = createAction(GET_RETRY_PAYMENT_REQ);
const getRetryPaymentInfoReqSuccess = createAction(GET_RETRY_PAYMENT_REQ_SUCCESS);
const getRetryPaymentInfoReqFailed = createAction(GET_RETRY_PAYMENT_REQ_FAILED);

const getOrderSummaryReq = createAction(GET_ORDER_SUMMARY_REQ);
const getOrderSummaryReqSuccess = createAction(GET_ORDER_SUMMARY_REQ_SUCCESS);
const getOrderSummaryReqFailed = createAction(GET_ORDER_SUMMARY_REQ_FAILED);

const getRedirectOrderSummaryReq = createAction(GET_REDIRECT_ORDER_SUMMARY_REQ);

export const actions = {
    getOrderSummaryReq,
    getOrderSummaryReqSuccess,
    getOrderSummaryReqFailed,
    getRedirectOrderSummaryReq,

    getRetryPaymentInfoReq,
    getRetryPaymentInfoReqSuccess,
    getRetryPaymentInfoReqFailed,
};

export const types = {
    GET_ORDER_SUMMARY_REQ,
    GET_ORDER_SUMMARY_REQ_SUCCESS,
    GET_ORDER_SUMMARY_REQ_FAILED,

    GET_REDIRECT_ORDER_SUMMARY_REQ,

    GET_RETRY_PAYMENT_REQ,
    GET_RETRY_PAYMENT_REQ_SUCCESS,
    GET_RETRY_PAYMENT_REQ_FAILED,
};
