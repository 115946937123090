import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionsHandler = {
    [types.EXTEND_RESERVATION_TIME_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.SAVE_RESERVATION_START_TIME_REQUEST]: (state) => ({
        ...state,
        reservationStartTime: null,
        loader: true,
    }),

    [types.GET_RESERVATION_CONFIG_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        reservationConfig: payload,
        loader: false,
    }),
    [types.GET_RESERVATION_CONFIG_REQUEST_FAILED]: (state) => ({
        ...state,
        reservationConfig: {},
        loader: false,
    }),

    [types.ADD_UPDATE_RESERVATION_REQUEST_SUCCESS]: (state) => ({
        ...state,
        reservationStartTime: new Date(),
        loader: false,
    }),
    [types.ADD_UPDATE_RESERVATION_REQUEST_FAILED]: (state) => ({
        ...state,
        reservationStartTime: null,
        loader: false,
    }),

    [types.REMOVE_RESERVATION_REQUEST_SUCCESS]: (state) => ({
        ...state,
        reservationStartTime: null,
        loader: false,
    }),
    [types.REMOVE_RESERVATION_REQUEST_FAILED]: (state) => ({
        ...state,
        reservationStartTime: null,
        loader: false,
    }),

    [types.EXTEND_RESERVATION_TIME_REQUEST_SUCCESS]: (state) => ({
        ...state,
        reservationStartTime: new Date(),
        loader: false,
    }),
    [types.EXTEND_RESERVATION_TIME_REQUEST_FAILED]: (state) => ({
        ...state,
        reservationStartTime: null,
        loader: false,
    }),
};

export default handleActions(actionsHandler, {
    reservationConfig: {},
    reservationStartTime: null,
    quote_id_created_time: null,
    loader: false,
});
