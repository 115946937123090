import { componentTitle } from './../components/routeevent/componetTitle';
let id_macro = function (value) {
    if (!value.includes('-')) {
        return value;
    } else {
        let spiltname = value.split('-');
        if (spiltname.length < 3) {
            return value;
        } else {
            spiltname.pop();
            return spiltname.toString().replaceAll(',', '-');
        }
    }
    // let spiltname = value.split('-');
};

export const cartPageEvent = (data, tax) => {
    window.google_tag_manager &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY] &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer.reset();
    const dataLayer = window.dataLayer || [];
    dataLayer.pop();
    let products = [];

    data &&
        data.forEach((d) => {
            products.push({
                id: d.sku,
                id_macro: id_macro(d.sku),
                sku: d.sku,
                name: d.name,
                price: d.special_price === '' || parseInt(d.special_price) === 0 ? d.price : d.special_price,
                tax: 0.0,
                taxRate: tax,
                type: d.type,
                category: d.category_names,
                quantity: d.qty,
                brand: d.brand_name,
            });
        });
    dataLayer.push({
        event: 'beginCart',
        pageType: 'cart',
        ecommerce: {
            checkout: {
                actionField: {
                    step: 1,
                },
                products: products,
            },
        },
    });
};

// products click
export const ProductDetails = (data, type) => {
    type !== 'other' &&
        window.google_tag_manager &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY] &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer.reset();
    const dataLayer = window.dataLayer || [];
    dataLayer.pop();
    dataLayer.push({
        event: 'productClick',
        pageType: 'product',
        ecommerce: {
            click: {
                actionField: { list: type === 'search' ? 'Search Results' : type }, // Optional list property.
                products: [
                    {
                        brand: data.brand_name || '',
                        id: data.sku,
                        id_macro: data.sku,
                        name: data.name,
                        price:
                            (data.price && data.price.specialprice && data.price.specialprice === '') ||
                                parseInt(data.price && data.price && data.price.specialprice) === 0
                                ? data.price && data.price.price
                                : data.price && data.price.specialprice || '',
                        sku: data.sku,
                        category: (data.categories_tags && data.categories_tags.en) || '',
                        variant: data.variant || '',
                        // position: productObj.position,
                    },
                ],
            },
        },
    });
};

// products measures
export const ProductMeasuresPlp = (data, type, clearPreviousEvent) => {
    let arr = [];
    window.google_tag_manager &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY] &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer.reset();
    const dataLayer = window.dataLayer || [];
    clearPreviousEvent && dataLayer.pop();

    if (data) {
        data.map(
            (item, index) =>
            (arr = [
                ...arr,
                Object.assign({
                    name: item.name,
                    id: item.sku,
                    id_macro: item.sku,
                    price:
                        (item.price && item.price.specialprice === '') ||
                            parseInt(item.price && item.price.specialprice) === 0
                            ? (item.price && item.price.price) || ''
                            : (item.price && item.price.specialprice) || '',
                    brand: item.brand_name,
                    category: (item.categories_tags && item.categories_tags.en) || '',
                    variant: item.color || '',
                    list: type,
                    position: index,
                }),
            ]),
        );
    }
    dataLayer.push({
        event: 'viewItemList',
        pageType: type,
        ecommerce: {
            currencyCode: 'EUR',
            impressions: arr,
        },
    });
};
// remove Products from cart
export const removeProductCart = (data) => {
    window.google_tag_manager &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY] &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer.reset();
    const dataLayer = window.dataLayer || [];
    dataLayer.pop();
    dataLayer.push({
        event: 'removeFromCart',
        pageType: 'cart',
        ecommerce: {
            remove: {
                products: [
                    {
                        name: data.name,
                        id: data.sku,
                        id_macro: id_macro(data.sku),
                        price:
                            data.special_price === '' || parseInt(data.special_price) === 0
                                ? data.price || ''
                                : data.special_price || '',
                        brand: data.brand_name,
                        quantity: data.qty,
                        category: data.category_names || '',
                        variant: data.color || data.color || '',
                    },
                ],
            },
        },
    });
};

// Measuring a Checkout user
export const measureCheckoutCart = (data) => {
    let arr = [];
    window.google_tag_manager &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY] &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer.reset();
    const dataLayer = window.dataLayer || [];
    dataLayer.pop();
    if (data) {
        data.map(
            (item, index) =>
            (arr = [
                ...arr,
                Object.assign({
                    name: item.name,
                    id: item.sku,
                    id_macro: id_macro(item.sku),
                    price:
                        (item.price && item.special_price === '') ||
                            parseInt(item.price && item.special_price) === 0
                            ? (item.price && item.price) || ''
                            : (item.price && item.special_price) || '',
                    brand: item.brand_name,
                    category: item.category_names,
                    variant: item.color || '',
                    quantity: item.qty,
                }),
            ]),
        );
    }
    dataLayer.push({
        event: 'checkoutOption',
        pageType: 'checkout',
        ecommerce: {
            checkout: {
                actionField: { step: 1, option: 'fillInformation' },
                products: arr,
            },
        },
    });
};
// measere chekoutPayment page
export const measureCheckoutPayment = () => {
    window.google_tag_manager &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY] &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer.reset();
    const dataLayer = window.dataLayer || [];
    dataLayer.pop();
    dataLayer.push({
        event: 'checkoutOption',
        pageType: 'checkout',
        ecommerce: {
            checkout_option: {
                actionField: { step: 2, option: 'payment' },
            },
        },
    });
};
// order Summary page
export const measureOrderSummary = (data) => {
    window.google_tag_manager &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY] &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer.reset();
    const dataLayer = window.dataLayer || [];
    dataLayer.pop();
    let arr = [];
    if (data && data.product_details) {
        data.product_details.map(
            (item, index) =>
            (arr = [
                ...arr,
                Object.assign({
                    name: item.name,
                    id: item.sku,
                    id_macro: id_macro(item.sku),
                    price:
                        item.special_price === '' || parseInt(item.special_price) === 0
                            ? item.price
                            : item.special_price,
                    brand: item.brand_name,
                    category: item.category_names,
                    variant: item.color || '',
                    quantity: item.qty_ordered,
                }),
            ]),
        );
    }

    dataLayer.push({
        event: 'purchase',
        pageType: 'purchase',
        ecommerce: {
            purchase: {
                actionField: {
                    id: data.order_number, // Transaction ID. Required for purchases and refunds.
                    affiliation: 'Online Store',
                    revenue: data.order_summary && data.order_summary.total, // Total transaction value (incl. tax and shipping)
                    tax: data.vat_percentage,
                    shipping: data.order_summary && data.order_summary.shipping,
                    coupon: data.order_summary && data.order_summary.voucher_code,
                },
                products: arr,
            },
        },
    });
};

export const addToCart = (product, size) => {
    window.google_tag_manager &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY] &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer.reset();
    const dataLayer = window.dataLayer || [];
    dataLayer.pop();

    dataLayer.push({
        event: 'addToCart',
        pageType: 'product',
        ecommerce: {
            currencyCode: 'EUR',
            add: {
                products: [
                    {
                        name: product.name,
                        id: product.sku + '-' + size,
                        id_macro: id_macro(product.sku),
                        price:
                            product.special_price === '' || parseInt(product.special_price) === 0
                                ? product.price || ''
                                : product.special_price || '',

                        brand: product.brand_name,
                        // item_list_id: product.sku,
                        quantity: 1,
                        category: product.category_names || '',
                        variant: product.color || '',
                    },
                ],
            },
        },
    });
};

export const productPromotion = (homeDetails) => {
    window.google_tag_manager &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY] &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer.reset();
    const dataLayer = window.dataLayer || [];
    dataLayer.pop(); //Clear the previous ecommerce object.
    let promotionArr = [];
    let bannerImages = homeDetails && homeDetails.banners;
    let blocks = homeDetails && homeDetails.blocks;
    let brand_blocks = homeDetails && homeDetails.brand_blocks;

    if (bannerImages) {
        bannerImages.map(
            (item, index) =>
            (promotionArr = [
                ...promotionArr,
                Object.assign({
                    id: `banner_images_${index + 1}`, // ID or Name is required.
                    name: 'Homepage banner',
                    creative: item.banner_image,
                    position: `slot${index + 1}`,
                }),
            ]),
        );
    }
    if (blocks) {
        blocks.map(
            (item, index) =>
            (promotionArr = [
                ...promotionArr,
                Object.assign({
                    id: `blocks_images_${index + 1}`, // ID or Name is required.
                    name: 'Homepage blocks',
                    creative: item.block_image,
                    position: `slot${index + 1}`,
                }),
            ]),
        );
    }
    if (brand_blocks) {
        brand_blocks.map(
            (item, index) =>
            (promotionArr = [
                ...promotionArr,
                Object.assign({
                    id: `brand_blocks_${index + 1}`, // ID or Name is required.
                    name: 'Homepage brand blocks',
                    creative: item.brand_image,
                    position: `slot${index + 1}`,
                }),
            ]),
        );
    }

    dataLayer.push({
        event: 'promoView',
        pageType: 'home',
        ecommerce: {
            promoView: {
                promotions: promotionArr,
            },
        },
    });
};

export const routesEvent = (pathname, path, pageType) => {
    if (path !== false) {
        window.google_tag_manager &&
            window.google_tag_manager[process.env.REACT_APP_GTM_KEY] &&
            window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer &&
            window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer.reset();
        const dataLayer = window.dataLayer || [];
        dataLayer.pop(); //Clear the previous ecommerce object.
        dataLayer.push({
            event: 'Pageview',
            pagePath: '/' + window.location.href.split('/')[3] + '/' + window.location.href.split('/')[4],
            pageTitle: componentTitle(path),
            pageType: 'other',
        });
    }
};

export const routesgtmevents = (pathname, path, pageType) => {
    window.google_tag_manager &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY] &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer.reset();
    const dataLayer = window.dataLayer || [];
    dataLayer.pop(); //Clear the previous ecommerce object.
    dataLayer.push({
        event: 'Pageview',
        pagePath: '/' + window.location.href.split('/')[3] + '/' + window.location.href.split('/')[4],
        pageTitle: path,
        pageType: pageType,
    });
};

export const onPromoClick = (data) => {
    window.google_tag_manager &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY] &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer.reset();
    const dataLayer = window.dataLayer || [];
    dataLayer.pop(); //Clear the previous ecommerce object.
    data &&
        dataLayer.push({
            event: 'promotionClick',
            ecommerce: {
                promoClick: {
                    promotions: [
                        {
                            id: data.id, // Name or ID is required.
                            name: data.name,
                            creative: data.creative,
                            position: data.position,
                        },
                    ],
                },
            },
        });
};
export const productDetails = (data) => {
    window.google_tag_manager &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY] &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer &&
        window.google_tag_manager[process.env.REACT_APP_GTM_KEY].dataLayer.reset();
    const dataLayer = window.dataLayer || [];
    dataLayer.pop(); //Clear the previous ecommerce object.
    dataLayer.push({
        event: 'productDetail',
        pageType: 'product',
        ecommerce: {
            detail: {
                actionField: { list: data.category_names || '' }, // 'detail' actions have an optional list property.
                products: [
                    {
                        name: data.name || '', // Name or ID is required.
                        id: data.sku || '',
                        id_macro: data.sku || '',
                        price:
                            data.special_price === '' || parseInt(data.special_price) === 0
                                ? data.price || ''
                                : data.special_price || '',
                        brand: data.brand_name || '',
                        category: data.category_names || '',
                        variant: data.color || '',
                    },
                ],
            },
        },
    });
};
