import { Grid, IconButton, SwipeableDrawer, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Icon_User_White_Web from '../../../../../assets/header/Icon_User_White_Web.svg';
import ItalyFlag from '../../../../../assets/italianFlag.png';
import worldFlag from '../../../../../assets/world.png';
import LevelOneMenu from './components/LevelOne';
import LevelTwoMenu from './components/LevelTwo';

const MobileSideBar = ({ drawerState, setDrawerState, setCountryPopup, setIsAssistanceOpen }) => {
    const state = useSelector((state) => state);
    const history = useHistory();

    const menuList = state && state.menu && state.menu.menuList && state.menu.menuList.data;
    const loginUser = useSelector((state) => state && state.storeInfo && state.storeInfo.loginUser);
    const store_code = useSelector((state) => state && state.storeInfo && state.storeInfo.store_code);
    const language = useSelector((state) => state && state.storeInfo && state.storeInfo.language);
    const country = useSelector((state) => state && state.storeInfo && state.storeInfo.country);
    const voidFunc = () => {};

    const [currentLevel, setCurrentLevel] = useState('L1');
    const [currentCategory, setCurrentCategory] = useState();

    const onSelectCategory = (cat) => {
        setCurrentCategory(cat);
        setCurrentLevel('L2');
    };

    const closeSidebar = () => setDrawerState(false);

    return (
        <SwipeableDrawer
            className="mobile_drawer"
            open={drawerState}
            onClose={() => setDrawerState(false)}
            onOpen={voidFunc}
        >
            <Grid className="side-bar-container">
                <Grid container className="side-bar-header">
                    <Grid className="side-bar-header-statement">
                        <Typography className="side-bar-header-font">
                            {loginUser && loginUser.customer_id && (
                                <>
                                    <FormattedMessage id="Hi" defaultMessage="hi" />
                                    ,&nbsp;{loginUser && loginUser.firstname}!
                                </>
                            )}
                            {!loginUser && (
                                <Grid
                                    container
                                    alignItems="center"
                                    onClick={() => {
                                        setDrawerState(false);
                                        history.push(`/${store_code}/sign-in`);
                                    }}
                                >
                                    <img src={Icon_User_White_Web} alt="user" /> &nbsp;
                                    <label
                                        display="inline"
                                        style={{ margin: 0, fontSize: '0.9rem', fontFamily: 'HelveticaNeueBold' }}
                                    >
                                        <FormattedMessage id="Common.Login" defaultMessage="Login" /> /{' '}
                                        <FormattedMessage id="Common.Register.Text" defaultMessage="Register" />
                                    </label>
                                </Grid>
                            )}
                        </Typography>
                    </Grid>
                    <Grid className="side-bar-header-button">
                        <IconButton
                            className="close-button"
                            color="inherit"
                            aria-label="close drawer"
                            onClick={() => setDrawerState(false)}
                        >
                            <RiCloseLine style={{ color: '#fff' }} />
                        </IconButton>
                    </Grid>
                </Grid>
                {currentLevel !== 'L2' && (
                    <Grid className="category-container">
                        <Grid className="category-inner-container" style={{ flexDirection: 'column' }}>
                            <Grid className="category-inner-name youre-in">
                                <FormattedMessage id="YoureIn" defaultMessage="You're in" />
                            </Grid>
                            <Grid container justify="space-between">
                                <div>
                                    <Grid className="flag-container">
                                        <img
                                            src={language === 'it' ? ItalyFlag : worldFlag}
                                            className="italy-flag"
                                            alt="Flag"
                                        />
                                    </Grid>
                                    <Grid className="flag-container">
                                        {language === 'it' ? 'IT' : 'International'}
                                    </Grid>
                                    <Grid className="flag-container">|</Grid>
                                    <Grid className="flag-container">{language === 'en' ? 'English' : 'Italian'}</Grid>
                                </div>
                                <div>
                                    <label
                                        className="Chagen_country"
                                        onClick={() => {
                                            setDrawerState(false);
                                            setCountryPopup(true);
                                        }}
                                    >
                                        <FormattedMessage
                                            id="MSideBar.ChangeCountry.Text"
                                            defaultMessage="Change Country"
                                        />
                                    </label>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {currentLevel === 'L1' && (
                    <LevelOneMenu
                        data={menuList}
                        OnSelect={onSelectCategory}
                        setIsAssistanceOpen={setIsAssistanceOpen}
                        setDrawerState={setDrawerState}
                    />
                )}
                {currentLevel === 'L2' && (
                    <LevelTwoMenu category={currentCategory} back={setCurrentLevel} closeSidebar={closeSidebar} />
                )}
            </Grid>
        </SwipeableDrawer>
    );
};

export default MobileSideBar;
