import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

export function ApiErrorToast() {
    return null;
}
export function ApiFalseMessageToast(message, flag) {
    if (flag) {
        return message && message !== '' && message.length !== 0 && toast.error(message);
    }
}
export function Fillvalidtion() {
    return toast.error(<FormattedMessage defaultMessage="Please fill valid information" id="validInformation" />);
}
export function emailUndefined() {
    return toast.error(<FormattedMessage defaultMessage="Getting email is undefined" id="emailSocialerror" />);
}
export function selectSizeError() {
    return toast.warn(<FormattedMessage defaultMessage="Please select size" id="validationSize" />);
}

export function ApiErrorToastTranslated(message) {
    // return null;
    return toast.error(<FormattedMessage id={message} defaultMessage="Something wrong, Please try after some time" />);
}