import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandlers = {
    [types.SHOW_ALERT]: (state, { payload }) => ({
        ...state,
        show: true,
        message: payload.message,
        messageCode: payload.messageCode,
        type: payload.type,
    }),
    [types.HIDE_ALERT]: (state) => ({
        ...state,
        show: false,
        message: '',
        messageCode: '',
        type: '',
    }),
};

export default handleActions(actionHandlers, {
    show: false,
    message: '',
    messageCode: '',
    type: '',
});
