import { createAction } from 'redux-actions';

const GET_HOMEPAGE_REQUEST = 'UJ/GET_HOMEPAGE_REQUEST';
const GET_HOMEPAGE_REQUEST_SUCCESS = 'UJ/GET_HOMEPAGE_REQUEST_SUCCESS';
const GET_HOMEPAGE_REQUEST_FAILED = 'UJ/GET_HOMEPAGE_REQUEST_FAILED';

const GET_NEW_ARRIWAL_REQUEST = 'UJ/GET_NEW_ARRIWAL_REQUEST';
const GET_NEW_ARRIWAL_REQUEST_SUCCESS = 'UJ/GET_NEW_ARRIWAL_REQUEST_SUCCESS';
const GET_NEW_ARRIWAL_REQUEST_FAILED = 'UJ/GET_NEW_ARRIWAL_REQUEST_FAILED';

const getHomepageRequest = createAction(GET_HOMEPAGE_REQUEST);
const getHomepageRequestSuccess = createAction(GET_HOMEPAGE_REQUEST_SUCCESS);
const getHomepageRequestFailed = createAction(GET_HOMEPAGE_REQUEST_FAILED);

const getNewArriwalRequest = createAction(GET_NEW_ARRIWAL_REQUEST);
const getNewArriwalRequestSuccess = createAction(GET_NEW_ARRIWAL_REQUEST_SUCCESS);
const getNewArriwalRequestFailed = createAction(GET_NEW_ARRIWAL_REQUEST_FAILED);

export const actions = {
    getHomepageRequest,
    getHomepageRequestSuccess,
    getHomepageRequestFailed,

    getNewArriwalRequest,
    getNewArriwalRequestSuccess,
    getNewArriwalRequestFailed,
};

export const types = {
    GET_HOMEPAGE_REQUEST,
    GET_HOMEPAGE_REQUEST_SUCCESS,
    GET_HOMEPAGE_REQUEST_FAILED,

    GET_NEW_ARRIWAL_REQUEST,
    GET_NEW_ARRIWAL_REQUEST_SUCCESS,
    GET_NEW_ARRIWAL_REQUEST_FAILED,
};
