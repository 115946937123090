import CommonDialogBox from 'components/CommonComponent/CommonDialog';
import reactDom from 'react-dom';

export const isEmail = (email = '') => {
    if (!email) {
        email = '';
    }
    const mailformat = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(\.\w{2,3})\s*$/;
    if (email.match(mailformat)) {
        if (email.indexOf('+') !== -1) {
            return false;
        }
        return true;
    }
    return false;
};

export const checkPasswordContain = (password) => {
    const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,20}$/;
    if (password.match(pattern)) {
        return true;
    }

    return false;
};

export const changeBrowserUrl = (storeId) => {
    const array = (window && window.location && window.location.href && window.location.href.split('/')) || [];
    let storeName = 'it';
    // eslint-disable-next-line default-case
    switch (parseInt(storeId)) {
        // case 1:
        //     storeName = 'en';
        //     break;
        case 2:
            storeName = 'it';
            break;
        case 8:
            storeName = 'en';
    }
    const arraylength = array.length;
    // eslint-disable-next-line default-case
    switch (arraylength) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
            window.history.pushState('', '', `/${storeName}/`);
            break;
        case 5:
            let url = `/${storeName}`;
            for (let i = 4; i < array.length; i++) {
                url = `${url}/${array[i]}`;
            }
            window.history.pushState('', '', `${url}`);
            break;
        default:
            let url1 = `/${storeName}`;
            for (let i = 4; i < array.length; i++) {
                url1 = `${url1}/${array[i]}`;
            }
            window.history.pushState('', '', `${url1}`);
    }
};
export function CommonDialog(title, message, buttonTitle, language) {
    reactDom.render(
        <CommonDialogBox title={title} message={message} buttonTitle={buttonTitle} language={language} />,
        document.getElementById('gdpr'),
    );
}

export const CheckWhiteSpace = (string) => {
    const expression = /^[a-z|A-Z]+(?: [a-z|A-Z]+)*$/;

    if (!expression.test(string)) {
        return true;
    } else {
        return false;
    }
};
