import { encryptPayload } from 'utils/Utils';
import api from '../../../api/apiServices';

//My Address
const getAllAddress = (payload) => api.post(`/address/addressbook`, encryptPayload(payload));
const saveAddress = (payload) => api.post(`/delivery/addaddress`, encryptPayload(payload));
const deleteAddress = (payload) => api.post(`/address/deleteaddress`, encryptPayload(payload));

//My Order
const getOrderHistory = (payload) => api.post(`/order/orderhistory`, encryptPayload(payload));
const getOrderDetails = (payload) => api.post(`/order/mkt/orderview`, encryptPayload(payload));
const getResonCode = (payload) => api.post('/order/orob/reasoncode', encryptPayload(payload));

//Return Order
const returnOrderRequest = (payload) => api.post(`/order/mkt/return`, encryptPayload(payload));

//Cancel Order
const cancelOrderRequest = (payload) => api.post(`/order/mkt/cancel`, encryptPayload(payload));

//Order Summary
const orderViewRequest = (payload) => api.post(`/order/orderview`, encryptPayload(payload));

//My Profile
const changePassword = (payload) => api.post(`/profile/changepassword`, encryptPayload(payload));
const updateCustomer = (payload) => api.post(`/profile/editcustomer`, encryptPayload(payload));
const getCustomer = (payload) => api.post(`/profile/getcustomer`, encryptPayload(payload));

//My WishList
const wishlistItems = (payload) => api.post(`/wishlist/wishlistitems/`, encryptPayload(payload));
const removeWishlistItem = (payload) => api.post(`/wishlist/removewishlistitem`, encryptPayload(payload));
const addWishlistItem = (payload) => api.post(`/wishlist/addtowishlist`, encryptPayload(payload));

//Update Email (Communication Preferences)
const updateEmail = (payload) => api.post(`/updateEmail/editcustomeremail`, encryptPayload(payload));
const subscribeEmail = (payload) => api.post(`/updateEmail/editsubscribe`, encryptPayload(payload));
const deleteCustomer = (payload) => api.post(`/updateEmail/deleteaccount`, encryptPayload(payload));

export default {
    getAllAddress,
    saveAddress,
    deleteAddress,
    getOrderHistory,
    getOrderDetails,
    getResonCode,
    orderViewRequest,
    returnOrderRequest,
    cancelOrderRequest,
    changePassword,
    updateCustomer,
    getCustomer,
    wishlistItems,
    removeWishlistItem,
    addWishlistItem,
    updateEmail,
    subscribeEmail,
    deleteCustomer,
};
