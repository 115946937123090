import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandlers = {
    [types.SET_ORDER_JWT_TOKEN]: (state, { payload }) => ({
        ...state,
        orderJWT: payload,
    }),
    
};

export default handleActions(actionHandlers, {
    orderJWT: null
});
