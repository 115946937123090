import { changeBrowserUrl } from 'components/login/utils';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import api from '../../../api';
import { ApiErrorToast, ApiFalseMessageToast } from '../../../utils/CommonApiMessage';
import { actions, types } from './actions';

const storeList = (state) => state && state.storeInfo && state.storeInfo.storeList;
const gdpr = (state) => state && state.storeInfo && state.storeInfo.updateGDPR;

const guestCartReq = function* guestCartReq({ payload }) {
    try {
        const { data } = yield call(api.getGuestCard, {});

        yield put(actions.getGuestCartRequestSuccess(data));
    } catch (err) {
        yield put(actions.getGuestCartRequestFailed());
        ApiErrorToast(err);
    }
};

const getStoreInfoReq = function* getStoreInfoReq() {
    try {
        const { data } = yield call(api.getAllStoreInfo);
        if (data.status) {
            yield put(actions.getStoreInfoRequestSuccess(data.data));
        } else {
            yield put(actions.getStoreInfoRequestFailed());
            ApiFalseMessageToast();
        }
    } catch (err) {
        yield put(actions.getStoreInfoRequestFailed());
        ApiErrorToast(err);
    }
};

const storeChangeReq = function* storeChangeReq({ payload }) {
    try {
        yield put(actions.setHomeLanguagePopUp(false));
        const allStoreList = yield select(storeList);
        const { data } = yield call(api.storeChange, payload);
        if (data.status) {
            let obj = {
                store_id:
                    (window.location.pathname.split('/')[1] === 'en' && 8) ||
                    2,
                language:
                    (window.location.pathname.split('/')[1] === 'en' && 'en') ||
                    'it',
                store_code:
                    (window.location.pathname.split('/')[1] === 'en' && 'en') ||
                    'it',
                country:
                    (window.location.pathname.split('/')[1] === 'en' && 'it') ||
                    'it',
            };
            allStoreList &&
                Object.keys(allStoreList) &&
                Object.keys(allStoreList).map((key) => {
                    if (allStoreList[key] && String(allStoreList[key].store_id) === String(payload.store_id)) {
                        obj = {
                            store_id: allStoreList[key].store_id,
                            language: allStoreList[key].language,
                            store_code: payload.back ? window.location.pathname.split('/')[1] : allStoreList[key].code,
                            country: allStoreList[key].country,
                        };
                    }
                    return obj;
                });

            yield put(actions.storeChangeRequestSuccess(obj));

            changeBrowserUrl(payload.store_id, obj.store_code);
        } else {
            yield put(actions.storeChangeRequestFailed());
            ApiFalseMessageToast();
        }
    } catch (err) {
        yield put(actions.storeChangeRequestFailed());
        ApiErrorToast(err);
    }
};
const updateGDPR = function* updateGDPR({ payload }) {
    try {
        const { data } = yield call(api.updategdpr, payload);

        if (data && data.status) {
            const updateGDPR = yield select(gdpr);
            toast.success(<FormattedMessage defaultMessage="Update successfully" id="Updatesuccess" />);
            yield put(
                actions.setGDPRData({
                    ...data.gdpr_details,
                    subscribed_to_newsletter: updateGDPR.subscribed_to_newsletter,
                }),
            );
        } else {
            const updateGDPR = yield select(gdpr);
            ApiFalseMessageToast();

            yield put(actions.setGDPRData(updateGDPR));
        }
    } catch (err) {
        const updateGDPR = yield select(gdpr);
        ApiErrorToast(err);
        yield put(actions.setGDPRData(updateGDPR));
    }
};

export default function* sagas() {
    yield takeLatest(types.GET_GUEST_CART_REQUEST, guestCartReq);
    yield takeLatest(types.GET_STORE_INFO_REQUEST, getStoreInfoReq);
    yield takeLatest(types.STORE_CHANGE_REQUEST, storeChangeReq);
    yield takeLatest(types.UPDATE_GDPR, updateGDPR);
}
