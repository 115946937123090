import footerRedducer from 'components/common/Footer/Redux/reducers';
import authReducer from 'components/Global/redux/reduces';
import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import CommunicationPreferencesReducer from '../components/account/CommunicationPerderences/redux/reducer';
import MyAddresses from '../components/account/MyAddresses/redux/reducer';
import MyOrders from '../components/account/MyOrder/redux/reducer';
import customerReducer from '../components/account/MyProfile/redux/reducer';
import wishListReducer from '../components/account/WishList/redux/reducer';
import brandReducer from '../components/brands/redux/reducer';
import menuReducer from '../components/common/NavigationBar/redux/reducer';
import loginReducer from '../components/login/redux/reducer';
import cartReducer from '../components/main/cart/Basket/redux/reducer';
import deliveryReducer from '../components/main/cart/Checkout/Delivery/redux/reducer';
import StorePickupReducer from '../components/main/cart/Checkout/Delivery/StorePickup/redux/reducer';
import OrderSummaryReducer from '../components/main/cart/Checkout/OrderConfirmation/redux/reducer';
import checkoutPaymentReducer from '../components/main/cart/Checkout/Payment/redux/reducer';
import homepageReducer from '../components/main/home/redux/reducer';
import PDPReducer from '../components/main/pdp/redux/reducer';
import staticReducer from '../components/main/static/redux/reducer';
import storeLocator from '../components/main/storeLocator/Redux/reducers';
import plpReducer from '../components/plp/redux/reducer';
import registerReducer from '../components/register/redux/reducer';
import storeInfoReducer from '../components/storeInfo/redux/reducer';
import jwtReducer from '../components/main/cart/Checkout/Payment/redux/jwtReducer';
import guestUserReducer from '../components/main/cart/Checkout/SignInSignUp/redux/reducer';
import alertPopupReducer from '../components/common/AlertPopup/redux/reducer';

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        menu: menuReducer,
        home: homepageReducer,
        login: loginReducer,
        storeInfo: storeInfoReducer,
        staticData: staticReducer,
        PDP: PDPReducer,
        plpData: plpReducer,
        register: registerReducer,
        guestUser:guestUserReducer,
        cart: cartReducer,
        brand: brandReducer,
        delivery: deliveryReducer,
        checkoutPayment: checkoutPaymentReducer,
        storePickup: StorePickupReducer,
        orderSummary: OrderSummaryReducer,
        auth: authReducer,
        myAllAddress: MyAddresses,
        myOrders: MyOrders,
        communicationPreferences: CommunicationPreferencesReducer,
        customerAction: customerReducer,
        myWishlist: wishListReducer,
        footer: footerRedducer,
        storeLocator: storeLocator,
        notificationAlert: alertPopupReducer,
        jwt: jwtReducer
    });

export default createRootReducer;
