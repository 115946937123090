import { createAction } from 'redux-actions';

//Actions types
const GET_ALL_PRODUCTS_REQUEST = 'GET_ALL_PRODUCTS_REQUEST';
const GET_ALL_PRODUCTS_REQUEST_SUCCESS = 'GET_ALL_PRODUCTS_REQUEST_SUCCESS';
const GET_ALL_PRODUCTS_REQUEST_FAILED = 'GET_ALL_PRODUCTS_REQUEST_FAILED';
const GET_ALL_PRODUCTS_PREVIOUS_REQUEST_SAVED = 'GET_ALL_PRODUCTS_PREVIOUS_REQUEST_SAVED';

const GET_SEARCH_PLP_PRODUCT_REQUEST = 'GET_SEARCH_PLP_PRODUCT_REQUEST';
const GET_BRAND_PLP_PRODUCT_REQUEST = 'GET_BRAND_PLP_PRODUCT_REQUEST';

const STORE_CAT_URL_KEYS_REQUEST = 'STORE_CAT_URL_KEYS_REQUEST';
const PLP_PRODUCTS = 'PLP_PRODUCTS';
const PLP_PRODUCTS_PAGE = 'PLP_PRODUCTS_PAGE';

//actions methods
const getAllProductRequest = createAction(GET_ALL_PRODUCTS_REQUEST);
const getAllProductRequestSuccess = createAction(GET_ALL_PRODUCTS_REQUEST_SUCCESS);
const getAllProductRequestFailed = createAction(GET_ALL_PRODUCTS_REQUEST_FAILED);

const getSearchPLPProductRequest = createAction(GET_SEARCH_PLP_PRODUCT_REQUEST);
const getBrandPLPProductRequest = createAction(GET_BRAND_PLP_PRODUCT_REQUEST);

const storeCatUrlKeysRequest = createAction(STORE_CAT_URL_KEYS_REQUEST);
const plpProducts = createAction(PLP_PRODUCTS);
const plpProductsPage = createAction(PLP_PRODUCTS_PAGE);
const perviousProductSaved = createAction(GET_ALL_PRODUCTS_PREVIOUS_REQUEST_SAVED);
export const actions = {
    getAllProductRequest,
    getSearchPLPProductRequest,
    getBrandPLPProductRequest,

    getAllProductRequestSuccess,
    getAllProductRequestFailed,

    storeCatUrlKeysRequest,
    plpProducts,
    plpProductsPage,
    perviousProductSaved,
};

export const types = {
    GET_ALL_PRODUCTS_REQUEST,
    GET_SEARCH_PLP_PRODUCT_REQUEST,
    GET_BRAND_PLP_PRODUCT_REQUEST,

    GET_ALL_PRODUCTS_REQUEST_SUCCESS,
    GET_ALL_PRODUCTS_REQUEST_FAILED,

    STORE_CAT_URL_KEYS_REQUEST,
    PLP_PRODUCTS,
    PLP_PRODUCTS_PAGE,
    GET_ALL_PRODUCTS_PREVIOUS_REQUEST_SAVED,
};
