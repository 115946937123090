import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandlers = {
    [types.CHANGE_PASSWORD_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.CHANGE_PASSWORD_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
    }),
    [types.CHANGE_PASSWORD_REQUEST_SUCCESS]: (state) => ({
        ...state,
        loader: false,
    }),

    [types.UPDATE_CUSTOMER_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.UPDATE_CUSTOMER_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
    }),
    [types.UPDATE_CUSTOMER_REQUEST_SUCCESS]: (state) => ({
        ...state,
        loader: false,
    }),

    [types.GET_CUSTOMER_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.GET_CUSTOMER_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
    }),
    [types.GET_CUSTOMER_REQUEST_SUCCESS]: (state) => ({
        ...state,
        loader: false,
    }),
};

export default handleActions(actionHandlers, {
    loader: false,
});
