import { Grid, Typography } from '@material-ui/core';
import LazyImages from 'components/common/LazyImages';
import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ProductDetails, removeProductCart } from 'service/GTMEvent';
import { FixDecimal } from 'utils/Utils';
import { actions as authActions } from '../../../../Global/redux/actions'; //components/Global/redux/actions
import { actions as cartActions } from '../../../../main/cart/Basket/redux/actions';

function MiniCart() {
    const dispatch = useDispatch();
    const history = useHistory();
    const store_id = useSelector((state) => state && state.storeInfo && state.storeInfo.store_id);
    const quote_id = useSelector((state) => state && state.storeInfo && state.storeInfo.quote_id);
    const store_code = useSelector((state) => state && state.storeInfo && state.storeInfo.store_code);
    const cartData = useSelector((state) => state && state.cart && state.cart.myCartData);
    const loginUser = useSelector((state) => state && state.storeInfo && state.storeInfo.loginUser);
    const reservationConfig = useSelector((state) => state && state.auth && state.auth.reservationConfig);
    /** Redirect to cart page */
    const handleNavigation = () => {
        return history.push(`/${store_code}/cart`);
    };

    /** Redirect to delivery or login screen according login user */
    const redirectToCheckout = () => {
        if (loginUser && loginUser.customer_id) {
            history.push(`/${store_code}/delivery-details`);
        } else {
            history.push(`/${store_code}/checkout-signIn`);
        }

        if (
            reservationConfig &&
            Object.keys(reservationConfig).length > 0 &&
            reservationConfig.reservation_status === 'Enable'
        ) {
            dispatch(
                authActions.addUpdateReservationRequest({
                    reqData: {
                        store_id: store_id,
                        quote_id: quote_id,
                        reservation_type: 'checkout',
                    },
                    history,
                }),
            );
        }
        // if (loginUser) {
        //     return history.push(`/${store_code}/delivery-details`);
        // } else {
        //     return history.push(`/${store_code}/checkout-signIn`);
        // }
    };

    const removeProducts = (item) => {
        removeProductCart(item);
        dispatch(
            cartActions.removeCartItemRequest({
                sku: item && item.sku,
                quote_id: quote_id,
                store_id: store_id,
            }),
        );
    };

    /** Created Price design */
    const getPrices = (data) => {
        if (data && data.special_price && parseInt(data.special_price) !== 0 && data.special_price !== '') {
            return (
                <p>
                    <label className="price">
                        {`${data && data.currency} ${data && FixDecimal(data.special_price)}`}{' '}
                    </label>
                    &nbsp;
                    <br />
                    <label className="price_stickout">
                        {' '}
                        <strike>{`${data && data.currency} ${data && FixDecimal(data.price)}`} </strike>
                    </label>
                    &nbsp;
                    <label className="offer" style={{ color: 'red' }}>
                        {Math.round((parseFloat(data.price - data.special_price) / parseFloat(data.price)) * 100)}%
                    </label>
                </p>
            );
        } else {
            return (
                <>
                    <p className="product-price">
                        {`${data && data.currency} ${(data && data.price && FixDecimal(data.price)) || 0}`}{' '}
                    </p>
                </>
            );
        }
    };

    return (
        <div className="miniCartDropDown" id="menuscontainer">
            <div className="shoppingCart">
                <Typography className="shoppingCart-text" variant="h4">
                    <FormattedMessage id="MiniCart.CartTitle.Text" defaultMessage="Shopping Cart" />
                </Typography>
                <Typography className="shoppingCart-items" variant="caption">
                    ({cartData && cartData.products && cartData.products.length}
                    <FormattedMessage id="NavigationBar.Items.Text" defaultMessage=" items" />
                    {')'}
                </Typography>
            </div>
            <div style={{ maxHeight: '200px', overflowY: 'scroll', overflowX: 'hidden' }}>
                {cartData &&
                    cartData.products &&
                    cartData.products.map((item, index) => (
                        <Grid container key={index} className="miniCart-pv-5-ph-15" justify="center" spacing={2}>
                            <Grid className="imgCSS" item xs={3}>
                                <span className="iconCSS" onClick={() => removeProducts(item)} style={{ zIndex: 1 }}>
                                    <IoMdClose />
                                </span>
                                <div
                                    className={'miniCart-p-img'}
                                    onClick={() => {
                                        history.push(`/${store_code}/${item.url_key}`);
                                        dispatch(cartActions.setMiniCartToOpen(false));
                                        ProductDetails(item, 'other');
                                    }}
                                >
                                    <LazyImages
                                        src={item.image}
                                        style={{ cursor: 'pointer' }}
                                        className={'miniCart-p-img'}
                                    />
                                </div>
                                {/* <img
                                className={'miniCart-p-img'}
                                src={item.image}
                                alt="item-1"
                                onClick={() => {
                                    history.push(`/${store_code}/${item.url_key}`);
                                    dispatch(cartActions.setMiniCartToOpen(false));
                                }}
                                style={{ cursor: 'pointer' }}
                            /> */}
                            </Grid>
                            <Grid item xs={9} className="miniCart-p-detail">
                                <div className="content-1">
                                    <Typography className="brand-name" variant="body2" component="h1">
                                        {item.brand_name}
                                    </Typography>
                                    <Typography className="miniCart-p-name" variant="h4" component="h2">
                                        {item.product_name}
                                    </Typography>
                                    <div className="pt-10">
                                        <Typography className="miniCart-p-sizeAndQty" variant="body2">
                                            <span>
                                                {' '}
                                                <FormattedMessage
                                                    id="Myorder.OrderSummary.Size"
                                                    defaultMessage="Size"
                                                />
                                                :
                                            </span>{' '}
                                            {item.size_text}
                                        </Typography>
                                        <Typography className="miniCart-p-sizeAndQty" variant="body2">
                                            <span>
                                                {' '}
                                                <FormattedMessage id="Myorder.OrderSummary.Qty" defaultMessage="Qty" />:
                                            </span>{' '}
                                            {item.qty}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="content-2">{getPrices(item)}</div>
                            </Grid>
                        </Grid>
                    ))}
            </div>
            {cartData && cartData.products && cartData.products.length > 0 && (
                <Grid container className="miniCart-pv-5-ph-15">
                    <div className="horizontal-line" />
                    <Grid item xs={6}>
                        <Typography className="miniCart-p-total" variant="h4">
                            <FormattedMessage id="Checkout.Total.Text" defaultMessage="Total" />
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className="miniCart-p-total text-right" variant="h4">
                            {cartData && cartData.currency}&nbsp; {cartData && FixDecimal(cartData.grand_total)}
                        </Typography>
                    </Grid>
                    <div className="horizontal-line" />
                </Grid>
            )}

            {(!cartData || !cartData.products || cartData.products.length === 0) && (
                <Typography className="title-empty">
                    <FormattedMessage id="Cart.empty" defaultMessage="Your basket is empty" />
                </Typography>
            )}

            <Grid container className="padding-15" spacing={3} justify="center">
                <Grid item xs={5}>
                    <button className="miniCart-viewCartBtn" onClick={handleNavigation} style={{ height: '100%' }}>
                        {' '}
                        <FormattedMessage id="updateDialog.ViewCart" default="View Cart" />
                    </button>
                </Grid>
                {cartData && cartData.products && cartData.products.length > 0 && (
                    <Grid item xs={7}>
                        <button
                            className="miniCart-checkoutBtn"
                            style={{ height: '100%' }}
                            onClick={() => redirectToCheckout()}
                        >
                            {' '}
                            <FormattedMessage
                                id="minicart.proccedtochekout"
                                defaultMessage="Proceed to checkout"
                            />{' '}
                        </button>
                    </Grid>
                )}
            </Grid>
        </div>
    );
}

export default MiniCart;
