import { createAction } from 'redux-actions';

//Actions types
const GET_ALL_BRANDS_REQUEST = 'UJ/GET_ALL_BRANDS_REQUEST';
const GET_ALL_BRANDS_REQUEST_SUCCESS = 'UJ/GET_ALL_BRANDS_REQUEST_SUCCESS';
const GET_ALL_BRANDS_REQUEST_FAILED = 'UJ/GET_ALL_BRANDS_REQUEST_FAILED';

//actions methods
const getAllBrandsRequest = createAction(GET_ALL_BRANDS_REQUEST);
const getAllBrandsRequestSuccess = createAction(GET_ALL_BRANDS_REQUEST_SUCCESS);
const getAllBrandsRequestFailed = createAction(GET_ALL_BRANDS_REQUEST_FAILED);

export const actions = {
    getAllBrandsRequest,
    getAllBrandsRequestSuccess,
    getAllBrandsRequestFailed,
};

export const types = {
    GET_ALL_BRANDS_REQUEST,
    GET_ALL_BRANDS_REQUEST_SUCCESS,
    GET_ALL_BRANDS_REQUEST_FAILED,
};
