import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../api';
import { ApiErrorToast } from '../../../utils/CommonApiMessage';
import { actions, types } from './actions';

const getAllBrandsRequest = function* getAllBrandsRequest({ payload }) {
    try {
        const { store_id } = payload;
        const { data } = yield call(api.getBrands, { store_id });
        if (data && data.status) {
            // yield put(actions.getAllBrandsRequest(data.data.A));
            yield put(actions.getAllBrandsRequestSuccess(data && data.data));
        } else {
            yield put(actions.getAllBrandsRequestFailed());
        }
    } catch (err) {
        yield put(actions.getAllBrandsRequestFailed(err));
        ApiErrorToast(err);
    }
};

export default function* sagas() {
    yield takeLatest(types.GET_ALL_BRANDS_REQUEST, getAllBrandsRequest);
}
