import { createAction } from 'redux-actions';

const SHOW_ALERT = 'UJ/SHOW_ALERT'
const HIDE_ALERT = 'UJ/HIDE_ALERT'

const SHOW_ALERT_REQ = 'UJ/SHOW_ALERT_REQ';
const HIDE_ALERT_REQ = 'UJ/HIDE_ALERT_REQ';

const showAlert = createAction(SHOW_ALERT);
const hideAlert = createAction(HIDE_ALERT);

const showAlertReq = createAction(SHOW_ALERT_REQ);
const hideAlertReq = createAction(HIDE_ALERT_REQ);

export const actions = {
    showAlert,
    hideAlert,
    showAlertReq,
    hideAlertReq
};

export const types = {
    SHOW_ALERT,
    HIDE_ALERT,
    SHOW_ALERT_REQ,
    HIDE_ALERT_REQ
};
