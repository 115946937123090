import api from 'api';
import { actions as storeInfoActions } from 'components/storeInfo/redux/actions';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { ApiErrorToast, ApiFalseMessageToast } from '../../../utils/CommonApiMessage';
import { actions as cartactions } from '../../main/cart/Basket/redux/actions';
import { whereWeRedirectCheckoutCartOrNot, whereWeRedirectContinueShoppingHomeOrNot } from '../utils';
import { actions, types } from './actions';
const auth = (state) => state && state.storeInfo;
const cart = (state) => state && state.cart && state.cart.myCartData;
const store_id = (state) => state && state.storeInfo && state.storeInfo.store_id;
const login_user = (state) =>
    state && state.storeInfo && state.storeInfo.loginUser && state.storeInfo.loginUser.customer_id;
const guest_user = (state) =>
    state && state.storeInfo && state.storeInfo.guest_user && state.storeInfo.guest_user.guestId;
const quote_id = (state) => state && state.storeInfo && state.storeInfo.quote_id;

const getReservationConfig = function* getReservationConfig() {
    const quoteId = yield select(quote_id);
    const customer_id = yield select(login_user);
    const guestId = yield select(guest_user);
    const storeId = yield select(store_id);
    try {
        const { data } = yield call(api.getReservationConfig);
        if (data && data.status) {
            yield put(actions.getReservationConfigRequestSuccess(data.res_config_data));
        } else {
            yield put(actions.getReservationConfigRequestFailed());
            ApiFalseMessageToast();
            yield put(
                cartactions.getMyCartRequest({
                    store_id: storeId,
                    quote_id: quoteId,
                    customer_id: customer_id || guestId,
                }),
            );
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.getReservationConfigRequestFailed());
    }
};

const addUpdateReservationReq = function* addUpdateReservationReq({ payload }) {
    try {
        const authData = yield select(auth);
        const quoteId = yield select(quote_id);
        const customer_id = yield select(login_user);
        const guestId = yield select(guest_user);
        const storeId = yield select(store_id);
        const { data } = yield call(api.addAndupdateReservation, payload.reqData);
        if (data && data.status) {
            yield put(actions.addUpdateReservationRequestSuccess());
            // if (!payload.isNotRedirect) {
            //     if (authData && authData.loginUser && authData.loginUser.customer_id) {
            //         payload.history.push(`/${authData && authData.store_code}/delivery-details`);
            //     } else {
            //         payload.history.push(`/${authData && authData.store_code}/checkout-signIn`);
            //     }
            // }
        } else {
            yield put(actions.addUpdateReservationRequestFailed());
            yield delay(1400);
            payload.history.push(`/${authData && authData.store_code}/cart`);
            ApiFalseMessageToast();
            yield put(
                cartactions.getMyCartRequest({
                    store_id: storeId,
                    quote_id: quoteId,
                    customer_id: customer_id || guestId,
                }),
            );
        }
    } catch (err) {
        const authData = yield select(auth);
        yield put(actions.addUpdateReservationRequestFailed());
        yield delay(1400);
        payload.history.push(`/${authData && authData.store_code}/cart`);
        ApiErrorToast(err);
    }
};

const removeReservationReq = function* removeReservationReq({ payload }) {
    try {
        const authData = yield select(auth);
        const storeId = yield select(store_id);
        const { data } = yield call(api.removeReservation, payload.reqData);
        if (data && data.status) {
            if (whereWeRedirectContinueShoppingHomeOrNot()) {
                payload.history.push(`/`);
                // window.location.reload();
            }
            yield put(actions.removeReservationRequestSuccess());
            if (payload && payload.storeData && payload.storeData.path === 'checkout_store_change') {
                yield put(
                    storeInfoActions.storeChangeRequest({
                        store_id: storeId,
                        quote_id: payload.reqData.quote_id || '',
                    }),
                );

                payload.history.push(`/${storeId === 2 ? 'it' : 'en'}/cart`);
            }
        } else {
            yield put(actions.removeReservationRequestFailed());
            payload.history.push(`/${authData && authData.store_code}/cart`);
            window.location.reload();
            ApiFalseMessageToast();
        }
    } catch (err) {
        const authData = yield select(auth);
        yield put(actions.removeReservationRequestFailed());
        payload.history.push(`/${authData && authData.store_code}/cart`);
        window.location.reload();
        ApiErrorToast(err);
    }
};

const extendReservationTimeReq = function* extendReservationTimeReq({ payload }) {
    try {
        const authData = yield select(auth);
        const cartData = yield select(cart);
        if (cartData && cartData.products && cartData.products.length !== 0) {
            const { data } = yield call(api.extendReservationTime, payload.reqData);
            if (data && data.status) {
                yield put(actions.extendReservationTimeRequestSuccess());
                if (whereWeRedirectCheckoutCartOrNot()) {
                    payload.history.push(`/${authData && authData.store_code}/cart`);
                }
            } else {
                yield put(actions.extendReservationTimeRequestFailed());
                payload.history.push(`/${authData && authData.store_code}/cart`);
                ApiFalseMessageToast();
            }
        }
    } catch (err) {
        const authData = yield select(auth);
        yield put(actions.extendReservationTimeRequestFailed());
        payload.history.push(`/${authData && authData.store_code}/cart`);
        ApiErrorToast(err);
    }
};

export default function* sagas() {
    yield takeLatest(types.GET_RESERVATION_CONFIG_REQUEST, getReservationConfig);
    yield takeLatest(types.ADD_UPDATE_RESERVATION_REQUEST, addUpdateReservationReq);
    yield takeLatest(types.REMOVE_RESERVATION_REQUEST, removeReservationReq);
    yield takeLatest(types.EXTEND_RESERVATION_TIME_REQUEST, extendReservationTimeReq);
}
