import { makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    alertWrapper: {
        position: 'absolute',
        top: '0px',
        right: '2rem'
    },
}));

const AlertPopup = () => {
    const classes = useStyles();
    const notificationAlert = useSelector((state) => state && state.notificationAlert);
    const show = useSelector((state) => state && state.notificationAlert && state.notificationAlert.show);
    const message = useSelector((state) => state && state.notificationAlert && state.notificationAlert.message);
    const messageCode = useSelector((state) => state && state.notificationAlert && state.notificationAlert.messageCode);
    const type = useSelector((state) => state && state.notificationAlert && state.notificationAlert.type);
    console.log('notificationAlert',notificationAlert)
    return (
        <div className={classes.alertWrapper}>
            {show && <Alert severity={'error'}>
                <FormattedMessage id={`Alert.${messageCode}`} defaultMessage={message} />
            </Alert>}
        </div>);
}

export default AlertPopup;
