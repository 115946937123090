import { createAction } from 'redux-actions';

const GET_PDP_DATA_REQ = 'UJ/GET_PDP_DATA_REQ';
const GET_PDP_DATA_REQ_SUCCESS = 'UJ/GET_PDP_DATA_REQ_SUCCESS';
const GET_PDP_DATA_REQ_FAILED = 'UJ/GET_PDP_DATA_REQ_FAILED';

const GET_QUICK_PDP_DATA_REQ = 'UJ/GET_QUICK_PDP_DATA_REQ';
const GET_QUICK_PDP_DATA_REQ_SUCCESS = 'UJ/GET_QUICK_PDP_DATA_REQ_SUCCESS';
const GET_QUICK_PDP_DATA_REQ_FAILED = 'UJ/GET_QUICK_PDP_DATA_REQ_FAILED';

const ADD_TO_CART_REQ = 'UJ/ADD_TO_CART_REQ';
const ADD_TO_CART_REQ_SUCCESS = 'UJ/ADD_TO_CART_REQ_SUCCESS';
const ADD_TO_CART_REQ_FAILED = 'UJ/ADD_TO_CART_REQ_FAILED';

const NOTIFY_ME_REQ = 'UJ/NOTIFY_ME_REQ';
const NOTIFY_ME_REQ_SUCCESS = 'UJ/NOTIFY_ME_REQ_SUCCESS';
const NOTIFY_ME_REQ_FAILED = 'UJ/NOTIFY_ME_REQ_FAILED';

const GET_PDP_TRENDING_DATA_REQ = 'UJ/GET_PDP_TRENDING_DATA_REQ';
const GET_PDP_TRENDING_DATA_REQ_SUCCESS = 'UJ/GET_PDP_TRENDING_DATA_REQ_SUCCESS';
const GET_PDP_TRENDING_DATA_REQ_FAILED = 'UJ/GET_PDP_TRENDING_DATA_REQ_FAILED';

const GET_PDP_BEST_SELLER_DATA_REQ = 'UJ/GET_PDP_BEST_SELLER_DATA_REQ';
const GET_PDP_BEST_SELLER_DATA_REQ_SUCCESS = 'UJ/GET_PDP_BEST_SELLER_DATA_REQ_SUCCESS';
const GET_PDP_BEST_SELLER_DATA_REQ_FAILED = 'UJ/GET_PDP_BEST_SELLER_DATA_REQ_FAILED';

const getPDPDataReq = createAction(GET_PDP_DATA_REQ);
const getPDPDataReqSuccess = createAction(GET_PDP_DATA_REQ_SUCCESS);
const getPDPDataReqFailed = createAction(GET_PDP_DATA_REQ_FAILED);

const getPDPTrendingDataReq = createAction(GET_PDP_TRENDING_DATA_REQ);
const getPDPTrendingDataReqSuccess = createAction(GET_PDP_TRENDING_DATA_REQ_SUCCESS);
const getPDPTrendingDataReqFailed = createAction(GET_PDP_TRENDING_DATA_REQ_FAILED);

const getPDPBestSellerDataReq = createAction(GET_PDP_BEST_SELLER_DATA_REQ);
const getPDPBestSellerDataReqSuccess = createAction(GET_PDP_BEST_SELLER_DATA_REQ_SUCCESS);
const getPDPBestSellerDataReqFailed = createAction(GET_PDP_BEST_SELLER_DATA_REQ_FAILED);

const getQuickPDPDataReq = createAction(GET_QUICK_PDP_DATA_REQ);
const getQuickPDPDataReqSuccess = createAction(GET_QUICK_PDP_DATA_REQ_SUCCESS);
const getQuickPDPDataReqFailed = createAction(GET_QUICK_PDP_DATA_REQ_FAILED);

const addToCartReq = createAction(ADD_TO_CART_REQ);
const addToCartReqSuccess = createAction(ADD_TO_CART_REQ_SUCCESS);
const addToCartReqFailed = createAction(ADD_TO_CART_REQ_FAILED);

const notifyMeReq = createAction(NOTIFY_ME_REQ);
const notifyMeReqSuccess = createAction(NOTIFY_ME_REQ_SUCCESS);
const notifyMeReqFailed = createAction(NOTIFY_ME_REQ_FAILED);

export const actions = {
    getPDPDataReq,
    getPDPDataReqSuccess,
    getPDPDataReqFailed,

    getPDPTrendingDataReq,
    getPDPTrendingDataReqSuccess,
    getPDPTrendingDataReqFailed,

    getPDPBestSellerDataReq,
    getPDPBestSellerDataReqSuccess,
    getPDPBestSellerDataReqFailed,

    addToCartReq,
    addToCartReqSuccess,
    addToCartReqFailed,

    notifyMeReq,
    notifyMeReqSuccess,
    notifyMeReqFailed,

    getQuickPDPDataReq,
    getQuickPDPDataReqSuccess,
    getQuickPDPDataReqFailed,
};

export const types = {
    GET_PDP_DATA_REQ,
    GET_PDP_DATA_REQ_SUCCESS,
    GET_PDP_DATA_REQ_FAILED,

    ADD_TO_CART_REQ,
    ADD_TO_CART_REQ_SUCCESS,
    ADD_TO_CART_REQ_FAILED,

    NOTIFY_ME_REQ,
    NOTIFY_ME_REQ_SUCCESS,
    NOTIFY_ME_REQ_FAILED,

    GET_QUICK_PDP_DATA_REQ,
    GET_QUICK_PDP_DATA_REQ_SUCCESS,
    GET_QUICK_PDP_DATA_REQ_FAILED,

    GET_PDP_TRENDING_DATA_REQ,
    GET_PDP_TRENDING_DATA_REQ_SUCCESS,
    GET_PDP_TRENDING_DATA_REQ_FAILED,

    GET_PDP_BEST_SELLER_DATA_REQ,
    GET_PDP_BEST_SELLER_DATA_REQ_SUCCESS,
    GET_PDP_BEST_SELLER_DATA_REQ_FAILED,
};
