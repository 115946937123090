import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandlers = {
    [types.REGISTER_USER_REQUEST]: (state, { payload }) => ({
        ...state,
        loading: true,
        socialLoading: payload.social,
    }),
    [types.REGISTER_USER_REQUEST_FAILED]: (state) => ({
        ...state,
        loading: false,
        socialLoading: false,
    }),
    [types.REGISTER_USER_REQUEST_SUCCESS]: (state) => ({
        ...state,
        loading: false,
        socialLoading: false,
    }),
};

export default handleActions(actionHandlers, {
    loading: false,
    socialLoading: false,
});
