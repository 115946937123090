import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandler = {
    [types.GET_CART_ITEM_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.GET_CART_ITEM_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
        cartDetails: null,
    }),
    [types.GET_CART_ITEM_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        cartDetails: payload,
    }),

    [types.SAVE_CART_COUNT_REQUEST]: (state, { payload }) => ({
        ...state,
        cartCount: payload,
    }),

    [types.GET_MY_CART]: (state) => ({
        ...state,
        myCartLoader: true,
    }),
    [types.GET_MY_CART_FAILED]: (state) => ({
        ...state,
        myCartLoader: false,
        myCartData: [],
    }),
    [types.GET_MY_CART_SUCCESS]: (state, { payload }) => {
        return {
            ...state,
            myCartLoader: false,
            myCartData: payload,
        }
    },

    [types.GET_MY_CART_WITHOUT_LOADER]: (state) => ({
        ...state,
        myCartLoader: false,
    }),
    [types.GET_MY_CART_WITHOUT_LOADER_FAILED]: (state) => ({
        ...state,
        myCartLoader: false,
        myCartData: [],
    }),
    [types.GET_MY_CART_WITHOUT_LOADER_SUCCESS]: (state, { payload }) => {
        return {
            ...state,
            myCartLoader: false,
            myCartData: payload,
        }
    },

    [types.REMOVE_CART_ITEM_REQUEST]: (state) => ({
        ...state,
        myCartLoader: true,
    }),
    [types.REMOVE_CART_ITEM_REQUEST_FAILED]: (state) => ({
        ...state,
        myCartLoader: false,
    }),

    [types.UPDATE_CART_ITEM_QTY_REQUEST]: (state) => ({
        ...state,
        myCartLoader: true,
    }),
    [types.UPDATE_CART_ITEM_QTY_REQUEST_FAILED]: (state) => ({
        ...state,
        myCartLoader: false,
    }),

    [types.ADD_GIFT_WRAP_REQUEST]: (state) => ({
        ...state,
        giftWrapLoader: true,
    }),
    [types.ADD_GIFT_WRAP_REQUEST_FAILED]: (state) => ({
        ...state,
        giftWrapLoader: false,
    }),
    [types.ADD_GIFT_WRAP_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        giftWrapLoader: false,
    }),
    [types.UPDATE_CART_DIALOG]: (state, { payload }) => ({
        ...state,
        quantityDialog: payload || false,
    }),
    [types.SET_MINI_CART_TO_OPEN]: (state, { payload }) => ({
        ...state,
        isMiniCartOpen: payload || false,
    }),
    [types.SHOULD_OPEN_MINI_CART]: (state, { payload }) => ({
        ...state,
        shouldOpenMiniCart: payload || false,
    }),
};

export default handleActions(actionHandler, {
    loader: false,
    cartDetails: {},
    cartCount: 0,

    myCartLoader: false,
    myCartData: [],

    removeItemLoader: false,

    updateItemLoader: false,

    giftWrapLoader: false,
    quantityDialog: false,

    isMiniCartOpen: false,
    shouldOpenMiniCart: false,
});
