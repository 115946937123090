import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionsHandler = {
    [types.GET_ALL_BRANDS_REQUEST]: (state) => ({
        ...state,
        brandLoader: true,
    }),

    [types.GET_ALL_BRANDS_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        brandLoader: false,
        brands: payload,
    }),

    [types.GET_ALL_BRANDS_REQUEST_FAILED]: (state) => ({
        ...state,
        brandLoader: false,
        brands: [],
    }),
};

export default handleActions(actionsHandler, {
    brandLoader: false,
    brands: [],
});
