var storage = window.localStorage;

const LocalStorage = () => {
    return {
        setFilter(obj) {
            storage.setItem('filter', JSON.stringify(obj));
        },
        getFilter() {
            return JSON.parse(storage.getItem('filter'));
        },
        removeFilter() {
            storage.removeItem('url');
            storage.removeItem('filter');
            storage.removeItem('sortBy');
            storage.removeItem('priceRange');
        },
    };
};

export default LocalStorage();
