import { encryptPayload } from 'utils/Utils';
import api from '../../../api/apiServices';

const getProducts = (payload) => api.post('/plp/productlisting', encryptPayload(payload));

const getPLPSearch = (payload) => api.post(`/plp/searchresult`, encryptPayload(payload));

const getPLPBrand = (payload) => api.post(`/plp/getbrandby`, encryptPayload(payload));

let apis = {
    getProducts,
    getPLPSearch,
    getPLPBrand,
};

export default apis;
