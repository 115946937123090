export const componentTitle = (pathname) => {
    let componentname = null;
    if (
        pathname.includes('faq') ||
        pathname.includes('contact-us') ||
        pathname.includes('terms-and-conditions') ||
        pathname.includes('shipping-and-return') ||
        pathname.includes('cookie-policy') ||
        pathname.includes('privacy') ||
        pathname.includes('not-found') ||
        pathname.includes('sign-in') ||
        pathname.includes('sign-up') ||
        pathname.includes('about-us') ||
        pathname.includes('checkout-signIn') ||
        pathname.includes('password-rest') ||
        pathname.includes('password-reset-success') ||
        pathname.includes('my-addresses') ||
        pathname.includes('wishlist') ||
        pathname.includes('order-history') ||
        pathname.includes('communication-perferences') ||
        pathname.includes('my-account') ||
        pathname.includes('my-privacy') ||
        pathname.includes('profile') ||
        pathname.includes('store-locator') ||
        pathname.includes('brands')
    ) {
        if (pathname.includes('faq')) {
            componentname = 'faq';
        } else if (pathname.includes('contact-us')) {
            componentname = 'contact-us';
        } else if (pathname.includes('terms-and-conditions')) {
            componentname = 'terms-and-conditions';
        } else if (pathname.includes('shipping-and-return')) {
            componentname = 'shipping-and-return';
        } else if (pathname.includes('cookie-policy')) {
            componentname = 'cookie-policy';
        } else if (pathname.includes('privacy')) {
            componentname = 'privacy';
        } else if (pathname.includes('not-found')) {
            componentname = 'not-found';
        } else if (pathname.includes('sign-in')) {
            componentname = 'sign-in';
        } else if (pathname.includes('sign-up')) {
            componentname = 'sign-up';
        } else if (pathname.includes('about-us')) {
            componentname = 'about-us';
        } else if (pathname.includes('brands')) {
            componentname = 'brands';
        } else if (pathname.includes('checkout-signIn')) {
            componentname = 'checkout-signIn';
        } else if (pathname.includes('password-rest')) {
            componentname = 'password-rest';
        } else if (pathname.includes('password-reset-success')) {
            componentname = 'password-reset-success';
        } else if (pathname.includes('my-addresses')) {
            componentname = 'my-addresses';
        } else if (pathname.includes('wishlist')) {
            componentname = 'wishlist';
        } else if (pathname.includes('order-history')) {
            componentname = 'order-history';
        } else if (pathname.includes('communication-perferences')) {
            componentname = 'communication-perferences';
        } else if (pathname.includes('my-account')) {
            componentname = 'my-account';
        } else if (pathname.includes('form')) {
            componentname = 'form';
        } else if (pathname.includes('my-privacy')) {
            componentname = 'my-privacy';
        } else if (pathname.includes('profile')) {
            componentname = 'profile';
        } else if (pathname.includes('store-locator')) {
            componentname = 'store-locator';
        }
    } else {
        componentname = false;
    }

    return componentname ? componentname.replaceAll('-', ' ') : componentname;
};
