import { createAction } from 'redux-actions';

//action types
const GET_CART_ITEM_REQUEST = 'UJ/GET_CART_ITEM_REQUEST';
const GET_CART_ITEM_REQUEST_SUCCESS = 'UJ/GET_CART_ITEM_REQUEST_SUCCESS';
const GET_CART_ITEM_REQUEST_FAILED = 'UJ/GET_CART_ITEM_REQUEST_FAILED';

const SAVE_CART_COUNT_REQUEST = 'UJ/SAVE_CART_COUNT_REQUEST';
const GET_CART_COUNT_REQUEST = 'UJ/GET_CART_COUNT_REQUEST';

const GET_MY_CART = 'UJ/GET_MY_CART';
const GET_MY_CART_SUCCESS = 'UJ/GET_MY_CART_SUCCESS';
const GET_MY_CART_FAILED = 'UJ/GET_MY_CART_FAILED';

const GET_MY_CART_WITHOUT_LOADER = 'UJ/GET_MY_CART_WITHOUT_LOADER';
const GET_MY_CART_WITHOUT_LOADER_SUCCESS = 'UJ/GET_MY_CART_WITHOUT_LOADER_SUCCESS';
const GET_MY_CART_WITHOUT_LOADER_FAILED = 'UJ/GET_MY_CART_WITHOUT_LOADER_FAILED';

const REMOVE_CART_ITEM_REQUEST = 'UJ/REMOVE_CART_ITEM_REQUEST';
const REMOVE_CART_ITEM_REQUEST_SUCCESS = 'UJ/REMOVE_CART_ITEM_REQUEST_SUCCESS';
const REMOVE_CART_ITEM_REQUEST_FAILED = 'UJ/REMOVE_CART_ITEM_REQUEST_FAILED';

const ADD_GIFT_WRAP_REQUEST = 'UJ/ADD_GIFT_WRAP_REQUEST';
const ADD_GIFT_WRAP_REQUEST_SUCCESS = 'UJ/ADD_GIFT_WRAP_REQUEST_SUCCESS';
const ADD_GIFT_WRAP_REQUEST_FAILED = 'UJ/ADD_GIFT_WRAP_REQUEST_FAILED';

const UPDATE_CART_ITEM_QTY_REQUEST = 'UJ/UPDATE_CART_ITEM_QTY_REQUEST';
const UPDATE_CART_ITEM_QTY_REQUEST_SUCCESS = 'UJ/UPDATE_CART_ITEM_QTY_REQUEST_SUCCESS';
const UPDATE_CART_ITEM_QTY_REQUEST_FAILED = 'UJ/UPDATE_CART_ITEM_QTY_REQUEST_FAILED';
const UPDATE_CART_DIALOG = 'UPDATE_CART_DIALOG';

const GET_MY_COUPON_CART = 'UJ/GET_MY_CART_COUPON';
const GET_MY_COUPON_CART_SUCCESS = 'UJ/GET_MY_CART_COUPON_SUCCESS';
const GET_MY_COUPON_CART_FAILED = 'UJ/GET_MY_CART_COUPON_FAILED';

const GET_REMOVE_COUPON = 'UJ/GET_REMOVE_COUPON';
const GET_REMOVE_COUPON_SUCCESS = 'UJ/GET_REMOVE_COUPON_SUCCESS';
const GET_REMOVE_COUPON_FAILED = 'UJ/GET_REMOVE_COUPON_FAILED';

const SET_MINI_CART_TO_OPEN = 'UJ/SET_MINI_CART_TO_OPEN';
const SHOULD_OPEN_MINI_CART = 'UJ/SHOULD_OPEN_MINI_CART';

//action methods
const getCartItemRequest = createAction(GET_CART_ITEM_REQUEST);
const getCartItemRequestSuccess = createAction(GET_CART_ITEM_REQUEST_SUCCESS);
const getCartItemRequestFailed = createAction(GET_CART_ITEM_REQUEST_FAILED);

const saveCartCountRequest = createAction(SAVE_CART_COUNT_REQUEST);
const getCartCountReq = createAction(GET_CART_COUNT_REQUEST);

const getMyCartRequest = createAction(GET_MY_CART);
const getMyCartRequestSuccess = createAction(GET_MY_CART_SUCCESS);
const getMyCartRequestFailed = createAction(GET_MY_CART_FAILED);

const getMyCartWithoutLoaderRequest = createAction(GET_MY_CART_WITHOUT_LOADER);
const getMyCartWithoutLoaderRequestSuccess = createAction(GET_MY_CART_WITHOUT_LOADER_SUCCESS);
const getMyCartWithoutLoaderRequestFailed = createAction(GET_MY_CART_WITHOUT_LOADER_FAILED);

const removeCartItemRequest = createAction(REMOVE_CART_ITEM_REQUEST);
const removeCartItemRequestSuccess = createAction(REMOVE_CART_ITEM_REQUEST_SUCCESS);
const removeCartItemRequestFailed = createAction(REMOVE_CART_ITEM_REQUEST_FAILED);

const addGiftWrapRequest = createAction(ADD_GIFT_WRAP_REQUEST);
const addGiftWrapRequestSuccess = createAction(ADD_GIFT_WRAP_REQUEST_SUCCESS);
const addGiftWrapRequestFailed = createAction(ADD_GIFT_WRAP_REQUEST_FAILED);

const updateCartItemQtyRequest = createAction(UPDATE_CART_ITEM_QTY_REQUEST);
const updateCartItemQtyRequestSuccess = createAction(UPDATE_CART_ITEM_QTY_REQUEST_SUCCESS);
const updateCartItemQtyRequestFailed = createAction(UPDATE_CART_ITEM_QTY_REQUEST_FAILED);
const updateCartDialog = createAction(UPDATE_CART_DIALOG);

const getMyCouponCartRequest = createAction(GET_MY_COUPON_CART);
const getMyCouponCartRequestSuccess = createAction(GET_MY_COUPON_CART_SUCCESS);
const getMyCouponCartRequestFailed = createAction(GET_MY_COUPON_CART_FAILED);

const getRemoveCouponRequest = createAction(GET_REMOVE_COUPON);
const getRemoveCouponRequestSuccess = createAction(GET_REMOVE_COUPON_SUCCESS);
const getRemoveCouponRequestFailed = createAction(GET_REMOVE_COUPON_FAILED);

const setMiniCartToOpen = createAction(SET_MINI_CART_TO_OPEN);
const shouldOpenMiniCart = createAction(SHOULD_OPEN_MINI_CART);

export const actions = {
    getCartItemRequest,
    getCartItemRequestSuccess,
    getCartItemRequestFailed,

    saveCartCountRequest,
    getCartCountReq,

    getMyCartRequest,
    getMyCartRequestSuccess,
    getMyCartRequestFailed,

    getMyCartWithoutLoaderRequest,
    getMyCartWithoutLoaderRequestSuccess,
    getMyCartWithoutLoaderRequestFailed,

    removeCartItemRequest,
    removeCartItemRequestSuccess,
    removeCartItemRequestFailed,

    addGiftWrapRequest,
    addGiftWrapRequestSuccess,
    addGiftWrapRequestFailed,

    updateCartItemQtyRequest,
    updateCartItemQtyRequestSuccess,
    updateCartItemQtyRequestFailed,
    updateCartDialog,

    getMyCouponCartRequest,
    getMyCouponCartRequestSuccess,
    getMyCouponCartRequestFailed,

    getRemoveCouponRequest,
    getRemoveCouponRequestSuccess,
    getRemoveCouponRequestFailed,

    setMiniCartToOpen,
    shouldOpenMiniCart,
};

export const types = {
    GET_CART_ITEM_REQUEST,
    GET_CART_ITEM_REQUEST_SUCCESS,
    GET_CART_ITEM_REQUEST_FAILED,

    SAVE_CART_COUNT_REQUEST,
    GET_CART_COUNT_REQUEST,

    GET_MY_CART,
    GET_MY_CART_SUCCESS,
    GET_MY_CART_FAILED,

    GET_MY_CART_WITHOUT_LOADER,
    GET_MY_CART_WITHOUT_LOADER_SUCCESS,
    GET_MY_CART_WITHOUT_LOADER_FAILED,

    REMOVE_CART_ITEM_REQUEST,
    REMOVE_CART_ITEM_REQUEST_SUCCESS,
    REMOVE_CART_ITEM_REQUEST_FAILED,

    ADD_GIFT_WRAP_REQUEST,
    ADD_GIFT_WRAP_REQUEST_SUCCESS,
    ADD_GIFT_WRAP_REQUEST_FAILED,

    UPDATE_CART_ITEM_QTY_REQUEST,
    UPDATE_CART_ITEM_QTY_REQUEST_SUCCESS,
    UPDATE_CART_ITEM_QTY_REQUEST_FAILED,
    UPDATE_CART_DIALOG,

    GET_MY_COUPON_CART,
    GET_MY_COUPON_CART_SUCCESS,
    GET_MY_COUPON_CART_FAILED,

    GET_REMOVE_COUPON,
    GET_REMOVE_COUPON_SUCCESS,
    GET_REMOVE_COUPON_FAILED,

    SET_MINI_CART_TO_OPEN,
    SHOULD_OPEN_MINI_CART
};
