import { createAction } from 'redux-actions';

//Actions Types
const GET_ALL_ACCOUNT_ADDRESSES_REQUEST = 'UJ/GET_ALL_ACCOUNT_ADDRESSES_REQUEST';
const GET_ALL_ACCOUNT_ADDRESSES_REQUEST_SUCCESS = 'UJ/GET_ALL_ACCOUNT_ADDRESSES_REQUEST_SUCCESS';
const GET_ALL_ACCOUNT_ADDRESSES_REQUEST_FAILED = 'UJ/GET_ALL_ACCOUNT_ADDRESSES_REQUEST_FAILED';

const SAVE_ACCOUNT_ADDRESS_REQUEST = 'UJ/SAVE_ACCOUNT_ADDRESS_REQUEST';
const SAVE_ACCOUNT_ADDRESS_REQUEST_SUCCESS = 'UJ/SAVE_ACCOUNT_ADDRESS_REQUEST_SUCCESS';
const SAVE_ACCOUNT_ADDRESS_REQUEST_FAILED = 'UJ/SAVE_ACCOUNT_ADDRESS_REQUEST_FAILED';

const DELETE_ACCOUNT_ADDRESS_REQUEST = 'UJ/DELETE_ACCOUNT_ADDRESS_REQUEST';
const DELETE_ACCOUNT_ADDRESS_REQUEST_SUCCESS = 'UJ/DELETE_ACCOUNT_ADDRESS_REQUEST_SUCCESS';
const DELETE_ACCOUNT_ADDRESS_REQUEST_FAILED = 'UJ/DELETE_ACCOUNT_ADDRESS_REQUEST_FAILED';

//actions
const getAllAccountAddressRequest = createAction(GET_ALL_ACCOUNT_ADDRESSES_REQUEST);
const getAllAccountAddressRequestSuccess = createAction(GET_ALL_ACCOUNT_ADDRESSES_REQUEST_SUCCESS);
const getAllAccountAddressRequestFailed = createAction(GET_ALL_ACCOUNT_ADDRESSES_REQUEST_FAILED);

const saveAccountAddressRequest = createAction(SAVE_ACCOUNT_ADDRESS_REQUEST);
const saveAccountAddressRequestSuccess = createAction(SAVE_ACCOUNT_ADDRESS_REQUEST_SUCCESS);
const saveAccountAddressRequestFailed = createAction(SAVE_ACCOUNT_ADDRESS_REQUEST_FAILED);

const deleteAccountAddressRequest = createAction(DELETE_ACCOUNT_ADDRESS_REQUEST);
const deleteAccountAddressRequestSuccess = createAction(DELETE_ACCOUNT_ADDRESS_REQUEST_SUCCESS);
const deleteAccountAddressRequestFailed = createAction(DELETE_ACCOUNT_ADDRESS_REQUEST_FAILED);

export const actions = {
    getAllAccountAddressRequest,
    getAllAccountAddressRequestSuccess,
    getAllAccountAddressRequestFailed,

    saveAccountAddressRequest,
    saveAccountAddressRequestSuccess,
    saveAccountAddressRequestFailed,

    deleteAccountAddressRequest,
    deleteAccountAddressRequestSuccess,
    deleteAccountAddressRequestFailed,
};

export const types = {
    GET_ALL_ACCOUNT_ADDRESSES_REQUEST,
    GET_ALL_ACCOUNT_ADDRESSES_REQUEST_SUCCESS,
    GET_ALL_ACCOUNT_ADDRESSES_REQUEST_FAILED,

    SAVE_ACCOUNT_ADDRESS_REQUEST,
    SAVE_ACCOUNT_ADDRESS_REQUEST_SUCCESS,
    SAVE_ACCOUNT_ADDRESS_REQUEST_FAILED,

    DELETE_ACCOUNT_ADDRESS_REQUEST,
    DELETE_ACCOUNT_ADDRESS_REQUEST_SUCCESS,
    DELETE_ACCOUNT_ADDRESS_REQUEST_FAILED,
};
