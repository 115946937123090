import { Backdrop, CircularProgress } from '@material-ui/core';
import { actions as myWishlistAction } from 'components/account/WishList/redux/actions';
import Loader1 from 'components/common/Loader/loader1';
import { actions as authActions } from 'components/Global/redux/actions';
import { componentTitle } from 'components/routeevent/componetTitle';
import jwt_decode from 'jwt-decode';
import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
/**language translation */
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import it from 'react-intl/locale-data/it';
/**end language translation */
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { routesEvent } from 'service/GTMEvent';
import NavBar from '../components/common/NavigationBar';
import { actions as cartActions } from '../components/main/cart/Basket/redux/actions';
import { actions as jwtAction } from '../components/main/cart/Checkout/Payment/redux/actions';
// import Middleware from '../components/main/MiddleWare';
import { actions as staticActions } from '../components/main/static/redux/actions';
import { actions as storeInfoAction } from '../components/storeInfo/redux/actions';
import { actions as guestCheckoutActions } from '../components/main/cart/Checkout/SignInSignUp/redux/actions';
import { actions as deliveryActions } from '../components/main/cart/Checkout/Delivery/redux/actions';
import messages_en from '../translations/en.json';
import messages_it from '../translations/it.json';
import AlertPopup from 'components/common/AlertPopup';

const reservationlogic = require('../inc/reservation-logic');

const Footer = lazy(() => import('../components/common/Footer'));

const HomePage = lazy(() => import('../components/main/home'));
const MyAccount = lazy(() => import('../components/account/MyAccount/index'));
const MyAddresses = lazy(() => import('../components/account/MyAddresses/index'));
const MyOrder = lazy(() => import('../components/account/MyOrder/index'));
const MyProfile = lazy(() => import('../components/account/MyProfile/index'));
const MyWishList = lazy(() => import('../components/account/WishList/index'));
const CommunicationPerderences = lazy(() => import('../components/account/CommunicationPerderences/index'));

const Register = lazy(() => import('../components/register'));
const Login = lazy(() => import('../components/login'));
const Auth = lazy(() => import('../components/auth'));

const ShippingAndReturn = lazy(() => import('../components/main/static/shippingAndReturn'));
const TermsAndConditions = lazy(() => import('../components/main/static/termsAndConditions'));
const AboutUs = lazy(() => import('../components/main/static/aboutUs'));
const ContactUs = lazy(() => import('../components/main/static/contactUs'));
const CookiePolicy = lazy(() => import('../components/main/static/cookiePolicy'));
const FAQ = lazy(() => import('../components/main/static/faq'));
const Privacy = lazy(() => import('../components/main/static/privacy'));
const NotFoundPage = lazy(() => import('../components/main/notFound'));
const OrderConfirmation = lazy(() => import('../components/main/cart/Checkout/OrderConfirmation/index'));
const ScrollToTop = lazy(() => import('../components/common/ScrollToTop'));
const StoreLocator = lazy(() => import('components/main/storeLocator/StoreLocator'));
const ForgotPassword = lazy(() => import('components/login/components/ForgotPassword'));
const NewPassword = lazy(() => import('components/login/components/NewPassword'));
const ResetSuccess = lazy(() => import('components/login/components/ResetSuccess'));
const Form = lazy(() => import('../components/main/cart/Checkout/AdressForm/index'));
const Brands = lazy(() => import('components/brands'));
const ReservationPop = lazy(() => import('components/common/ReservationPop'));
const CheckoutSignIn = lazy(() => import('components/main/cart/Checkout/SignInSignUp/CheckoutSignIn'));
const CheckoutFooter = lazy(() => import('components/common/CheckoutFooter'));
const CheckoutHeader = lazy(() => import('components/common/CheckoutHeader'));
const Basket = lazy(() => import('../components/main/cart/Basket'));

const CheckoutPayment = lazy(() => import('components/main/cart/Checkout/Payment/CheckoutPayment'));
const RetryPayment = lazy(() => import('components/main/cart/Checkout/PaymentRetry/CheckoutPaymentRetry'));

const CheckoutDelivery = lazy(() => import('../components/main/cart/Checkout/Delivery'));
const StorePickup = lazy(() => import('../components/main/cart/Checkout/Delivery/StorePickup/index'));
const PLP = lazy(() => import('../components/plp/index'));
const Middleware = lazy(() => import('./../components/main/MiddleWare'));


let reservationInterval = null;

const AppRouters = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const path = window.location.pathname;
    const pathname = window.location.href;

    const store_id = useSelector((state) => state && state.storeInfo && state.storeInfo.store_id);
    const store_code = useSelector((state) => state && state.storeInfo && state.storeInfo.store_code);
    const guest_user = useSelector((state) => state && state.storeInfo && state.storeInfo.guest_user);
    const loginUser = useSelector((state) => state && state.storeInfo && state.storeInfo.loginUser);
    const quote_id = useSelector((state) => state && state.storeInfo && state.storeInfo.quote_id);
    const reservationConfig = useSelector((state) => state && state.auth && state.auth.reservationConfig);
    const reservationStartTime = useSelector((state) => state && state.auth && state.auth.reservationStartTime);
    const storeChangeLoader = useSelector((state) => state && state.storeInfo && state.storeInfo.storeChangeLoader);
    const jwtToken = useSelector((state) => state && state.jwt && state.orderJWT);
    const guestId = guest_user && guest_user.guestId;
    const customer_id = loginUser && loginUser.customer_id;
    React.useEffect(() => {
        if (componentTitle(path)) {
            routesEvent(pathname, path);
        }
    }, [pathname, store_id]);
    if (jwtToken) {
        let decoded = jwt_decode(jwtToken);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            dispatch(jwtAction.setOrderJWTToken(null));
        }
    }

    const [showReservationPop, setReservationPop] = useState(false);

    /** Language code */
    const language = useSelector((state) => state && state.storeInfo && state.storeInfo.language);
    addLocaleData([...en, ...it]);
    const messages = {
        it: messages_it,
        en: messages_en,
    };
    /**end */

    useEffect(() => {
        dispatch(storeInfoAction.getStoreInfoRequest());
    }, [dispatch]);

    React.useEffect(() => {
        if (path.split('/')[1] !== store_code) {
            dispatch(
                storeInfoAction.storeChangeRequest({
                    store_id: (path.split('/')[1] === 'en' && 8) || 2,
                    quote_id: quote_id || '',
                    back: false,
                }),
            );
        }
    }, []);

    window.onpopstate = () => {
        if (path.split('/')[1] !== store_code) {
            dispatch(
                storeInfoAction.storeChangeRequest({
                    store_id: (path.split('/')[1] === 'en' && 8) || 2,
                    quote_id: quote_id || '',
                    back: true,
                }),
            );
        }
    };

    //contact us
    useEffect(() => {
        dispatch(
            staticActions.getContactUsData({
                store_id: store_id,
            }),
        );
    }, [store_id, dispatch]);

    //cart Request
    useEffect(() => {
        if (quote_id) {
            dispatch(
                cartActions.getMyCartRequest({
                    store_id: store_id,
                    quote_id: quote_id,
                    customer_id: customer_id || guestId,
                }),
            );
        }
    }, [dispatch, store_id, quote_id, loginUser, guest_user]);

    //wishList
    useEffect(() => {
        if (loginUser && loginUser.customer_id) {
            dispatch(
                myWishlistAction.getAllWishListRequest({
                    store_id: store_id,
                    customerid: loginUser.customer_id,
                    quote_id: quote_id,
                }),
            );
        }
    }, [dispatch, store_id, loginUser, quote_id]);

    // guest cart
    useEffect(() => {
        try {
            if (!loginUser) {
                if (!guest_user || !guest_user.guestId || guest_user.guestId === '') {
                    dispatch(storeInfoAction.getGuestCartRequest({}));
                }
            }
        } catch (err) { }
    }, [dispatch, loginUser, guest_user]);

    //Reservation
    useEffect(() => {
        dispatch(authActions.getReservationConfigRequest());
    }, [dispatch]);

    // //changeBrowerUrl
    useEffect(() => {
        if (!history.location.pathname.includes('checkout-signIn') &&
            !history.location.pathname.includes('checkout-payment') &&
            !history.location.pathname.includes('retry-payment') &&
            !history.location.pathname.includes('delivery-details')) {
            dispatch(guestCheckoutActions.resetGuestUser(),);
            dispatch(deliveryActions.saveDeliveryAddress(""),);
        }
    }, [history.location]);

    //Reservation Logic
    useEffect(() => {
        try {
            reservationlogic(
                reservationConfig,
                reservationStartTime,
                reservationInterval,
                showReservationPop,
                setReservationPop,
                store_id,
                quote_id,
                history,
                dispatch,
                authActions,
            );
        } catch (err) { }
    }, [reservationConfig, reservationStartTime, showReservationPop, dispatch, store_id, quote_id]);

    const isCheckout = useMemo(() => {
        if (
            path.includes('checkout-signIn') ||
            path.includes('checkout-payment') ||
            path.includes('retry-payment') ||
            path.includes('delivery-details')
        ) {
            return true;
        }
        return false;
    }, [path]);
    useEffect(() => {
        if (
            path.includes('sign-in') ||
            path.includes('sign-up') ||
            path.includes('checkout-signIn') ||
            path.includes('forgot-password') ||
            path.includes('password-reset-success') ||
            path.includes('password-rest')
        ) {
        } else {
            dispatch(storeInfoAction.lastPathuser(path));
        }
    });

    return (
        <div>
            <IntlProvider
                locale={language}
                messages={messages[language]}
                onError={(err) => {
                    if (err && err.code === 'MISSING_TRANSLATION') {
                        console.warn('Missing translation', err && err.message);
                        return;
                    }
                }}
            >
                <>
                    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={true} />
                    {!isCheckout && <NavBar />}

                    <Suspense fallback={<Loader1 />}>
                        <ReservationPop isOpen={showReservationPop} setOpen={setReservationPop} />
                        {isCheckout && <CheckoutHeader />}
                        <ScrollToTop>
                            <div style={{ minHeight: '300px', paddingTop: `${isMobile ? '0px' : '80px'}`, position: 'relative' }}>
                                {/* <MetaData metaTitle="URBAN JUNGLE STORE" /> */}
                                {/** Home */}
                                <Route path="/home" component={HomePage} />
                                <Route exact path="/" component={HomePage} />
                                <Route exact path="/:locale" component={HomePage} />
                                <Route path="/:locale(it|en|uk|mt)/:category" component={Middleware} />

                                {/* <Route exact path="/:locale(it|en|uk|mt)/sign-in" component={Login} /> */}
                                <Route exact path="/:locale(it|en|uk|mt)/sign-in">
                                    <Auth pathProp={'SIGN_IN'} />
                                </Route>
                                <Route exact path="/:locale(it|en|uk|mt)/sign-up" >
                                    <Auth pathProp={'SIGN_UP'} />
                                </Route>

                                <Route
                                    exact
                                    path="/:locale(it|en|uk|mt)/forgot-password"
                                    component={ForgotPassword}
                                />
                                <Route
                                    exact
                                    path="/:locale(it|en|uk|mt)/password-rest"
                                    component={NewPassword}
                                />
                                <Route
                                    exact
                                    path="/:locale(it|en|uk|mt)/password-reset-success"
                                    component={ResetSuccess}
                                />

                                {/**Static Pages */}
                                <Route exact path="/:locale(it|en|uk|mt)/contact-us" component={ContactUs} />
                                <Route exact path="/:locale(it|en|uk|mt)/faq" component={FAQ} />
                                <Route
                                    exact
                                    path="/:locale(it|en|uk|mt)/terms-and-conditions"
                                    component={TermsAndConditions}
                                />
                                <Route
                                    exact
                                    path="/:locale(it|en|uk|mt)/shipping-and-return"
                                    component={ShippingAndReturn}
                                />
                                <Route
                                    exact
                                    path="/:locale(it|en|uk|mt)/cookie-policy"
                                    component={CookiePolicy}
                                />
                                <Route exact path="/:locale(it|en|uk|mt)/privacy" component={Privacy} />
                                <Route exact path="/:locale(it|en|uk|mt)/about-us" component={AboutUs} />
                                {/** End Static Pages */}
                                <Route exact path="/:locale(it|en|uk|mt)/not-found" component={NotFoundPage} />
                                {/* Cart */}
                                <Route exact path="/:locale(it|en|uk|mt)/cart" component={Basket} />
                                {/* End Cart */}
                                <Route path="/:locale(it|en|uk|mt)/search" component={PLP} />
                                <Route path="/:locale(it|en|uk|mt)/brand/:brand" component={PLP} />

                                {/* Brands */}
                                <Route exact path="/:locale(it|en|uk|mt)/brands" component={Brands} />
                                {/** My Account */}
                                <Route exact path="/:locale(it|en|uk|mt)/my-account" component={MyAccount} />
                                <Route exact path="/:locale(it|en|uk|mt)/profile" component={MyProfile} />
                                <Route exact path="/:locale(it|en|uk|mt)/order-history" component={MyOrder} />
                                <Route exact path="/:locale(it|en|uk|mt)/wishlist" component={MyWishList} />
                                <Route exact path="/:locale(it|en|uk|mt)/my-addresses" component={MyAddresses} />
                                <Route
                                    exact
                                    path="/:locale(it|en|uk|mt)/my-privacy"
                                    component={CommunicationPerderences}
                                />
                                {/** End My Account */}
                                {/** Checkout */}
                                <Route path="/:locale(it|en|uk|mt)/checkout-signIn" component={CheckoutSignIn} />
                                <Route
                                    exact
                                    path="/:locale(it|en|uk|mt)/delivery-details"
                                    component={CheckoutDelivery}
                                />
                                <Route exact path="/:locale(it|en|uk|mt)/form" component={Form} />
                                <Route
                                    exact
                                    path="/:locale(it|en|uk|mt)/delivery-store"
                                    component={StorePickup}
                                />
                                <Route
                                    exact
                                    path="/:locale(it|en|uk|mt)/checkout-payment"
                                    component={CheckoutPayment}
                                />

                                <Route
                                    exact
                                    path="/:locale(it|en|uk|mt)/order-summary"
                                    component={OrderConfirmation}
                                />

                                <Route
                                    exact
                                    path="/:locale(it|en|uk|mt)/retry-payment"
                                    component={RetryPayment}
                                />

                                {/**End Checkout */}

                                {/* Store locator */}
                                <Route
                                    exact
                                    path="/:locale(it|en|uk|mt)/store-locator"
                                    component={StoreLocator}
                                />
                                <AlertPopup />
                            </div>
                        </ScrollToTop>
                        {!isCheckout && <Footer />}
                        {isCheckout && <CheckoutFooter />}
                    </Suspense>
                </>
            </IntlProvider>
            <Backdrop open={storeChangeLoader} style={{ zIndex: 1000000 }}>
                <CircularProgress size={80} style={{ color: '#fff' }} />
            </Backdrop>

        </div>
    );
};

export default AppRouters;
