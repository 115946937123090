import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ApiErrorToast, ApiFalseMessageToast } from '../../../../utils/CommonApiMessage';
import { actions as basketActions } from '../../../main/cart/Basket/redux/actions';
import api from '../../api';
import { actions, types } from './actions';

let myWishlist = (state) => state && state.myWishlist;

const getWishListItemsReq = function* getWishListItemsReq({ payload }) {
    try {
        if (payload) {
            const { data } = yield call(api.wishlistItems, payload);
            if (data && data.status) {
                let arr = [];
                yield put(actions.getAllWishListRequestSuccess(data.data));
                if (data.data) {
                    for (let i in data.data) {
                        arr.push(data.data[i].product_id);
                        yield put(actions.addWishListItemRequestSuccess(arr));
                    }
                } else {
                    yield put(actions.addWishListItemRequestSuccess([]));
                }
            } else {
                ApiFalseMessageToast();
                yield put(actions.getAllWishListRequestFailed());
            }
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.getAllWishListRequestFailed());
    }
};

const removeWishListItemReq = function* removeWishListItemReq({ payload }) {
    try {
        const { customerid, store_id, wishilistitemid, quote_id } = payload;
        const { data } = yield call(api.removeWishlistItem, { wishilistitemid, store_id, quote_id, customerid });
        if (data && data.status) {
            yield put(
                actions.getAllWishListRequest({
                    customerid,
                    store_id,
                    quote_id,
                }),
            );

            let wishlist = yield select(myWishlist);
            let wishListItems = wishlist.wishListItems || [];
            let wishlistArr = wishlist.wishlistArr;
            let { product_id } = wishListItems.filter((product) => product.wishlist_id === wishilistitemid)[0];
            wishlistArr = wishlistArr.filter((productId) => productId !== product_id);
            wishListItems = wishListItems.filter((product) => product.wishlist_id !== wishilistitemid);
            yield put(actions.addWishListItemRequestSuccess(wishlistArr));
            yield put(actions.getAllWishListRequestSuccess(wishListItems));
            yield put(actions.removeWishlistRequestSuccess());
            if (payload && !payload.dontShowMessage) toast.success(data.message);
        } else {
            ApiFalseMessageToast();
            yield put(actions.removeWishlistRequestFailed());
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.removeWishlistRequestFailed());
    }
};

const addToWishListItemReq = function* addToWishListItemReq({ payload }) {
    try {
        const { data } = yield call(api.addWishlistItem, payload.Raqdata);
        if (data && data.status) {
            let wishlist = yield select(myWishlist);

            let wishListItems = wishlist.wishListItems || [];
            wishListItems.push(data.last_wishlist && data.last_wishlist[0]);

            let wishlistArr = wishlist.wishlistArr;
            wishlistArr = wishlistArr || [];
            wishlistArr.push(payload.product_id);

            toast.success(data.message);
            yield put(actions.addWishListItemRequestSuccess(wishlistArr));
            yield put(actions.getAllWishListRequestSuccess(wishListItems));
            if (payload.removeData) {
                yield put(basketActions.removeCartItemRequest(payload.removeData));
            }
        } else {
            ApiFalseMessageToast();
            yield put(actions.addWishListItemRequestFailed());
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.addWishListItemRequestFailed());
    }
};

const addToWishListRemoveCartItemReq = function* addToWishListRemoveCartItemReq({ payload }) {
    const { removeCartPayload, quote_id, store_id } = payload;
    try {
        const { data } = yield call(api.addWishlistItem, payload);
        if (data && data.status) {
            let wishlist = yield select(myWishlist);

            let wishListItems = wishlist.wishListItems || [];
            wishListItems.push({
                wishlist_id: data.wishlist_itemid,
                product_id: payload.product_id,
            });
            let wishlistArr = wishlist.wishlistArr;
            wishlistArr = wishlistArr || [];
            wishlistArr.push(payload.product_id);
            toast.success(data.message);
            yield put(actions.addWishListItemRequestSuccess(wishlistArr));
            yield put(actions.getAllWishListRequestSuccess(wishListItems));
            yield put(
                basketActions.removeCartItemRequest({
                    quote_id: quote_id,
                    sku: removeCartPayload.sku,
                    store_id: store_id,
                    category_names: removeCartPayload.category_names,
                    name: removeCartPayload.name,
                    qty: removeCartPayload.qty,
                    special_price: removeCartPayload.special_price,
                    price: removeCartPayload.price,
                    requestFromWishListRemoveCart: true,
                }),
            );
        } else {
            ApiFalseMessageToast();
            yield put(actions.addWishListItemRequestFailed());
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.addWishListItemRequestFailed());
    }
};

export default function* sagas() {
    yield takeLatest(types.GET_ALL_WISHLIST_REQUEST, getWishListItemsReq);
    yield takeLatest(types.REMOVE_WISHLIST_ITEM_REQUEST, removeWishListItemReq);
    yield takeLatest(types.ADD_WISHLIST_ITEM_REQUEST, addToWishListItemReq);
    yield takeLatest(types.ADD_WISHLIST_REMOVECART_ITEM_REQUEST, addToWishListRemoveCartItemReq);
}
