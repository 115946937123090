import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionsHandler = {
    [types.GET_ALL_PRODUCTS_REQUEST]: (state, { payload }) => ({
        ...state,
        loader: (payload.previous && true) || (payload.filter === false && true) || false,
        smallLoader: (payload && payload.page !== 1 && true) || false,
        fliterLoader: payload.filter === true && true,
        // products: {},
    }),
    [types.GET_SEARCH_PLP_PRODUCT_REQUEST]: (state, { payload }) => ({
        ...state,
        loader: (payload.previous && true) || (payload.filter === false && true) || false,
        smallLoader: (payload && payload.page !== 1 && true) || false,
        fliterLoader: payload.filter === true && true,
    }),
    [types.GET_BRAND_PLP_PRODUCT_REQUEST]: (state, { payload }) => ({
        ...state,
        loader: (payload.previous && true) || (payload.filter === false && true) || false,
        smallLoader: (payload && payload.page !== 1 && true) || false,
        fliterLoader: payload.filter === true && true,
    }),
    [types.GET_ALL_PRODUCTS_PREVIOUS_REQUEST_SAVED]: (state, { payload }) => ({
        ...state,
        loader: false,
        fliterLoader: false,
        smallLoader: false,
        message: true,
        plpProducts: [],
    }),

    [types.GET_ALL_PRODUCTS_REQUEST_FAILED]: (state, { payload }) => ({
        ...state,
        loader: false,
        products: {},
        bestseller: payload || [],
        fliterLoader: false,
        message: false,
    }),
    [types.GET_ALL_PRODUCTS_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        products: payload || {},
        smallLoader: false,
        fliterLoader: false,
        message: false,
    }),
    [types.STORE_CAT_URL_KEYS_REQUEST]: (state, { payload }) =>
        Object.keys(payload).length !== 0
            ? {
                  ...state,
                  cat: payload,
              }
            : {
                  ...state,
                  cat: payload,
                  plpProducts: [],
                  products: {},
                  pageNumber: 1,
                  smallLoader: false,
              },
    [types.PLP_PRODUCTS]: (state, { payload }) => ({
        ...state,
        plpProducts: payload,
    }),
    [types.PLP_PRODUCTS_PAGE]: (state, { payload }) => ({
        ...state,
        pageNumber: payload,
    }),
};

export default handleActions(actionsHandler, {
    loader: false,
    products: {},
    cat: {},
    plpProducts: [],
    pageNumber: 1,
    smallLoader: false,
    fliterLoader: false,
    message: false,
});
