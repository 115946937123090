import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandlers = {
    [types.STORE_LOCATOR_REQUEST]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.STORE_LOCATOR_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loading: false,
        storelocator: payload || {},
    }),
    [types.STORE_LOCATOR_REQUEST_FAILED]: (state) => ({
        ...state,
        loading: false,
        storelocator: [],
    }),
};

export default handleActions(actionHandlers, {
    loading: false,
    storelocator: [],
});
