import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandlers = {
    [types.GET_PAYMENT_OPTION_REQ]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_PAYMENT_OPTION_REQ_SUCCESS]: (state, { payload }) => ({
        ...state,
        loading: false,
        paymentOptions: payload.paymentOption || null,
        cartData: payload.cardData || null,
    }),
    [types.GET_PAYMENT_OPTION_REQ_FAILED]: (state) => ({
        ...state,
        loading: false,
    }),
    [types.SET_PAYMENT_OPTION_REQ]: (state) => ({
        ...state,
        mainLoading: true,
    }),
    [types.SET_PAYMENT_OPTION_REQ_FAILED]: (state) => ({
        ...state,
        mainLoading: false,
    }),
    [types.PLACE_ORDER_REQ]: (state) => ({
        ...state,
        mainLoading: true,
    }),
    [types.PLACE_ORDER_REQ_FAILED]: (state) => ({
        ...state,
        mainLoading: false,
    }),
    [types.PLACE_ORDER_REQ_SUCCESS]: (state) => ({
        ...state,
        mainLoading: false,
    }),
};

export default handleActions(actionHandlers, {
    loading: false,
    paymentOptions: null,
    cartData: null,
    mainLoading: false,
});
