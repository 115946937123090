import { createAction } from 'redux-actions';

const ECOM_LOGIN_REQ = 'UJ/ECOM_LOGIN_REQ';
const ECOM_LOGIN_REQ_SUCCESS = 'UJ/ECOM_LOGIN_REQ_SUCCESS';
const ECOM_LOGIN_REQ_FAILED = 'UJ/ECOM_LOGIN_REQ_FAILED';

const SOCIAL_FACEBOOK_LOGIN_REQ = 'UJ/SOCIAL_FACEBOOK_LOGIN_REQ';
const SOCIAL_FACEBOOK_LOGIN_REQ_SUCCESS = 'UJ/SOCIAL_FACEBOOK_LOGIN_REQ_SUCCESS';
const SOCIAL_FACEBOOK_LOGIN_REQ_FAILED = 'UJ/SOCIAL_FACEBOOK_LOGIN_REQ_FAILED';

const SOCIAL_GOOGLE_LOGIN_REQ = 'UJ/SOCIAL_GOOGLE_LOGIN_REQ';
const SOCIAL_GOOGLE_LOGIN_REQ_SUCCESS = 'UJ/SOCIAL_GOOGLE_LOGIN_REQ_SUCCESS';
const SOCIAL_GOOGLE_LOGIN_REQ_FAILED = 'UJ/SOCIAL_GOOGLE_LOGIN_REQ_FAILED';

const FORGOT_PASSWORD_REQ = 'UJ/FORGOT_PASSWORD_REQ';
const FORGOT_PASSWORD_REQ_SUCCESS = 'UJ/FORGOT_PASSWORD_REQ_SUCCESS';
const FORGOT_PASSWORD_REQ_FAILED = 'UJ/FORGOT_PASSWORD_REQ_FAILED';

const RESET_PASSWORD_REQ = 'UJ/RESET_PASSWORD_REQ';
const RESET_PASSWORD_REQ_SUCCESS = 'UJ/RESET_PASSWORD_REQ_SUCCESS';
const RESET_PASSWORD_REQ_FAILED = 'UJ/RESET_PASSWORD_REQ_FAILED';
const SOCIAL_REGISTER_CHECKOUT = 'UJ/SOCIAL_REGISTER_CHECKOUT';

const ecomLoginReq = createAction(ECOM_LOGIN_REQ);
const ecomLoginReqSuccess = createAction(ECOM_LOGIN_REQ_SUCCESS);
const ecomLoginReqFailed = createAction(ECOM_LOGIN_REQ_FAILED);

const socialFacebookLoginReq = createAction(SOCIAL_FACEBOOK_LOGIN_REQ);
const socialFacebookLoginReqSuccess = createAction(SOCIAL_FACEBOOK_LOGIN_REQ_SUCCESS);
const socialFacebookLoginReqFailed = createAction(SOCIAL_FACEBOOK_LOGIN_REQ_FAILED);

const socialGoogleLoginReq = createAction(SOCIAL_GOOGLE_LOGIN_REQ);
const socialGoogleLoginReqSuccess = createAction(SOCIAL_GOOGLE_LOGIN_REQ_SUCCESS);
const socialGoogleLoginReqFailed = createAction(SOCIAL_GOOGLE_LOGIN_REQ_FAILED);

const forgotPasswordReq = createAction(FORGOT_PASSWORD_REQ);
const forgotPasswordReqSuccess = createAction(FORGOT_PASSWORD_REQ_SUCCESS);
const forgotPasswordReqFailed = createAction(FORGOT_PASSWORD_REQ_FAILED);

const resetPasswordReq = createAction(RESET_PASSWORD_REQ);
const resetPasswordReqSuccess = createAction(RESET_PASSWORD_REQ_SUCCESS);
const resetPasswordReqFailed = createAction(RESET_PASSWORD_REQ_FAILED);
const socialRegisterCheckout = createAction(SOCIAL_REGISTER_CHECKOUT);

export const actions = {
    ecomLoginReq,
    ecomLoginReqSuccess,
    ecomLoginReqFailed,

    socialFacebookLoginReq,
    socialFacebookLoginReqSuccess,
    socialFacebookLoginReqFailed,

    socialGoogleLoginReq,
    socialGoogleLoginReqSuccess,
    socialGoogleLoginReqFailed,

    forgotPasswordReq,
    forgotPasswordReqSuccess,
    forgotPasswordReqFailed,

    resetPasswordReq,
    resetPasswordReqSuccess,
    resetPasswordReqFailed,
    socialRegisterCheckout,
};

export const types = {
    ECOM_LOGIN_REQ,
    ECOM_LOGIN_REQ_SUCCESS,
    ECOM_LOGIN_REQ_FAILED,

    SOCIAL_FACEBOOK_LOGIN_REQ,
    SOCIAL_FACEBOOK_LOGIN_REQ_SUCCESS,
    SOCIAL_FACEBOOK_LOGIN_REQ_FAILED,

    SOCIAL_GOOGLE_LOGIN_REQ,
    SOCIAL_GOOGLE_LOGIN_REQ_SUCCESS,
    SOCIAL_GOOGLE_LOGIN_REQ_FAILED,

    FORGOT_PASSWORD_REQ,
    FORGOT_PASSWORD_REQ_SUCCESS,
    FORGOT_PASSWORD_REQ_FAILED,

    RESET_PASSWORD_REQ,
    RESET_PASSWORD_REQ_SUCCESS,
    RESET_PASSWORD_REQ_FAILED,
    SOCIAL_REGISTER_CHECKOUT,
};
