import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandlers = {
    [types.GET_STORE_INFO_REQUEST]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_STORE_INFO_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loading: false,
    }),
    [types.GET_STORE_INFO_REQUEST_FAILED]: (state, { payload }) => ({
        ...state,
        loading: false,
    }),
    [types.GET_GUEST_CART_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        guest_user: {
            guestId: payload || null,
        },
    }),
    [types.GET_GUEST_CART_REQUEST_FAILED]: (state) => ({
        ...state,
        guest_user: {
            guestId: null,
        },
    }),
    [types.SET_LOGIN_USER_DETAILS]: (state, { payload }) => ({
        ...state,
        loginUser: payload || null,
        quote_id: payload && payload.quote_id,
        guest_user: {
            guestId: null,
        },
    }),
    [types.SET_QUOTE_ID]: (state, { payload }) => ({
        ...state,
        quote_id: payload,
    }),
    [types.GET_STORE_INFO_REQUEST]: (state) => ({
        ...state,
        storeList: {},
    }),
    [types.GET_STORE_INFO_REQUEST_FAILED]: (state) => ({
        ...state,
        storeList: {},
    }),
    [types.GET_STORE_INFO_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        storeList: payload || {},
    }),
    [types.STORE_CHANGE_REQUEST]: (state) => ({
        ...state,
        storeChangeLoader: false,
    }),
    [types.STORE_CHANGE_REQUEST_FAILED]: (state) => ({
        ...state,
        store_id: 2,
        language: 'en',
        store_code: 'en',
        country: 'it',
        storeChangeLoader: false,
    }),
    [types.STORE_CHANGE_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        store_id: parseInt(payload.store_id),
        language: payload.language,
        store_code: payload.store_code,
        country: payload.country,
        storeChangeLoader: false,
    }),
    [types.UPDATE_GDPR]: (state) => ({
        ...state,
        updateGDPRLoader: true,
    }),
    [types.SET_GDPR_DATA]: (state, { payload }) => ({
        ...state,
        updateGDPRLoader: false,
        updateGDPR: payload,
    }),
    [types.SET_HOME_LANGUAGE_POP_UP]: (state, { payload }) => ({
        ...state,
        hompageLanguagePopUp: payload,
    }),
    [types.GET_LAST_PATH_USER]: (state, { payload }) => ({
        ...state,
        lastPathUser: payload,
    }),
    [types.SET_ECOM_LOGIN]: (state, { payload }) => ({
        ...state,
        ecomLogin: payload,
    }),
};

export default handleActions(actionHandlers, {
    loading: false,
    store_id:
        (window.location.pathname.split('/')[1] === 'en' && 8) ||
        2,
    language:
        (window.location.pathname.split('/')[1] === 'en' && 'en') ||
        'it',
    store_code:
        (window.location.pathname.split('/')[1] === 'en' && 'en') ||
        'it',
    country:
        (window.location.pathname.split('/')[1] === 'en' && 'it') ||
        'it',

    customer: null,
    guest_user: null,
    loginUser: null,
    quote_id: null,
    storeList: {},
    storeChangeLoader: false,
    updateGDPR: null,
    updateGDPRLoader: false,
    hompageLanguagePopUp: false,
    lastPathUser: '/',
    ecomLogin: false,
});
