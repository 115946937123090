import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandlers = {
    [types.GET_ORDER_SUMMARY_REQ]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_ORDER_SUMMARY_REQ_SUCCESS]: (state, { payload }) => {
        return {
            ...state,
            loading: false,
            orderSummary: payload || null,
        }
    },
    [types.GET_ORDER_SUMMARY_REQ_FAILED]: (state) => ({
        ...state,
        loading: false,
        orderSummary: null,
    }),
    [types.GET_REDIRECT_ORDER_SUMMARY_REQ]: state => ({
        ...state,
        loading: true
    }),
    [types.GET_RETRY_PAYMENT_REQ]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_RETRY_PAYMENT_REQ_SUCCESS]: (state, { payload }) => {
        let currentTime = new Date();
        return {
            ...state,
            loading: false,
            burst_time: payload.burst_time,
            available_retry_attempts: payload.available_retry_attempts,
            currentTime: currentTime.getTime(),
            remainingTime: currentTime.setSeconds(currentTime.getSeconds() + payload.burst_time),
            retryPaymentAvailable: (payload.status === "success" ? true : false) || null,
        }
    },
    [types.GET_RETRY_PAYMENT_REQ_FAILED]: (state) => ({
        ...state,
        loading: false,
        retryPaymentAvailable: null,
    })
};

export default handleActions(actionHandlers, {
    loading: false,
    orderSummary: null,
    retryPaymentAvailable: null,
    currentTime: 0,
    remainingTime: 0,
    burst_time: 0,
    available_retry_attempts: 0
});
