const reservationlogic = (
    reservationConfig,
    reservationStartTime,
    reservationInterval,
    showReservationPop,
    setReservationPop,
    store_id,
    quote_id,
    history,
    dispatch,
    authActions,
) => {
    if (
        reservationConfig &&
        Object.keys(reservationConfig).length > 0 &&
        reservationConfig.reservation_status === 'Enable'
    ) {
        if (reservationStartTime) {
            let reservationStartDate = new Date(reservationStartTime);
            let reservationLimitDate = new Date(reservationStartTime);

            let reservation20MinDate = new Date(reservationStartTime);

            reservationStartDate.setMinutes(
                reservationStartDate.getMinutes() + (parseInt(reservationConfig.reservation_checkout_time || 10) - 2),
            );
            reservationStartDate = new Date(reservationStartDate);

            reservationLimitDate.setMinutes(reservationLimitDate.getMinutes() + 40);
            reservationLimitDate = new Date(reservationLimitDate);

            reservation20MinDate.setMinutes(
                reservation20MinDate.getMinutes() + parseInt(reservationConfig.reservation_checkout_time || 10),
            );
            reservation20MinDate = new Date(reservation20MinDate);

            //Close POP after reservation_checkout_time completed
            if (reservation20MinDate <= new Date()) {
                if (reservationInterval) {
                    clearInterval(reservationInterval);
                }

                if (showReservationPop) {
                    setReservationPop(false);
                }

                dispatch(
                    authActions.removeReservationRequest({
                        reqData: {
                            store_id: store_id,
                            quote_id: quote_id,
                            reservation_type: 'checkout',
                        },
                        history,
                    }),
                );
            }

            if (reservationLimitDate <= new Date()) {
                if (reservationInterval) {
                    clearInterval(reservationInterval);
                }
                if (showReservationPop) {
                    setReservationPop(false);
                }
            } else {
                if (reservationStartDate > new Date() && showReservationPop) {
                    setReservationPop(false);
                }

                reservationInterval = setInterval(() => {
                    if (!reservationStartDate) {
                        clearInterval(reservationInterval);
                    }
                    if (reservationStartDate <= new Date() && !showReservationPop) {
                        setReservationPop(true);
                    }
                }, 1000);
            }
        } else {
            if (reservationInterval) {
                clearInterval(reservationInterval);
            }
            if (showReservationPop) {
                setReservationPop(false);
            }
        }
    } else {
        if (showReservationPop) {
            setReservationPop(false);
        }
        if (reservationInterval) {
            clearInterval(reservationInterval);
        }
    }
};
module.exports = reservationlogic;
