import { createAction } from 'redux-actions';

const GET_PAYMENT_OPTION_REQ = 'UJ/GET_PAYMENT_OPTION_REQ';
const GET_PAYMENT_OPTION_REQ_SUCCESS = 'UJ/GET_PAYMENT_OPTION_REQ_SUCCESS';
const GET_PAYMENT_OPTION_REQ_FAILED = 'UJ/GET_PAYMENT_OPTION_REQ_FAILED';

const SET_PAYMENT_OPTION_REQ = 'UJ/SET_PAYMENT_OPTION_REQ';
const SET_PAYMENT_OPTION_REQ_FAILED = 'UJ/SET_PAYMENT_OPTION_REQ_FAILED';

const PLACE_ORDER_REQ = 'UJ/PLACE_ORDER_REQ';
const PLACE_ORDER_REQ_SUCCESS = 'UJ/PLACE_ORDER_REQ_SUCCESS';
const PLACE_ORDER_REQ_FAILED = 'UJ/PLACE_ORDER_REQ_FAILED';

const RETRY_PAYMENT_REQ = 'UJ/RETRY_PAYMENT_REQ';
const RETRY_PAYMENT_REQ_SUCCESS = 'UJ/RETRY_PAYMENT_REQ_SUCCESS';
const RETRY_PAYMENT_REQ_FAILED = 'UJ/RETRY_PAYMENT_REQ_FAILED';

const SET_ORDER_JWT_TOKEN = 'UJ/SET_ORDER_JWT_TOKEN';

const getPaymentOptionReq = createAction(GET_PAYMENT_OPTION_REQ);
const getPaymentOptionReqSuccess = createAction(GET_PAYMENT_OPTION_REQ_SUCCESS);
const getPaymentOptionReqFailed = createAction(GET_PAYMENT_OPTION_REQ_FAILED);

const setPaymentOptionReq = createAction(SET_PAYMENT_OPTION_REQ);
const setPaymentOptionReqFailed = createAction(SET_PAYMENT_OPTION_REQ_FAILED);

const placeOrderReq = createAction(PLACE_ORDER_REQ);
const placeOrderReqSuccess = createAction(PLACE_ORDER_REQ_SUCCESS);
const placeOrderReqFailed = createAction(PLACE_ORDER_REQ_FAILED);

const retryPaymentOrderReq = createAction(RETRY_PAYMENT_REQ);
const retryPaymentOrderReqSuccess = createAction(RETRY_PAYMENT_REQ_SUCCESS);
const retryPaymentOrderReqFailed = createAction(RETRY_PAYMENT_REQ_FAILED);

const setOrderJWTToken = createAction(SET_ORDER_JWT_TOKEN);

export const actions = {
    getPaymentOptionReq,
    getPaymentOptionReqSuccess,
    getPaymentOptionReqFailed,

    setPaymentOptionReq,
    setPaymentOptionReqFailed,

    placeOrderReq,
    placeOrderReqSuccess,
    placeOrderReqFailed,

    setOrderJWTToken,

    retryPaymentOrderReq,
    retryPaymentOrderReqSuccess,
    retryPaymentOrderReqFailed
};

export const types = {
    GET_PAYMENT_OPTION_REQ,
    GET_PAYMENT_OPTION_REQ_SUCCESS,
    GET_PAYMENT_OPTION_REQ_FAILED,

    SET_PAYMENT_OPTION_REQ,
    SET_PAYMENT_OPTION_REQ_FAILED,

    PLACE_ORDER_REQ,
    PLACE_ORDER_REQ_SUCCESS,
    PLACE_ORDER_REQ_FAILED,

    SET_ORDER_JWT_TOKEN,

    RETRY_PAYMENT_REQ,
    RETRY_PAYMENT_REQ_SUCCESS,
    RETRY_PAYMENT_REQ_FAILED,
};
