import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../api/index';
import { ApiErrorToast, ApiFalseMessageToast } from '../../../../utils/CommonApiMessage';
import { actions, types } from './actions';

const SubscribeEmailGuestRequest = function* SubscribeEmailGuestRequest({ payload }) {
    try {
        const { data } = yield call(api.guestsubscribeEmail, payload);
        if (data && data.status) {
            yield put(actions.SubscribeEmailGuestRequestSuccess());
            data.message && toast.success(data.message);
        } else {
            yield put(actions.SubscribeEmailRequestFailed());
            ApiFalseMessageToast();
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.SubscribeEmailGuestRequestFailed());
    }
};

export default function* sagas() {
    yield takeLatest(types.SUBSCRIBE_EMAIL_GUEST_REQUEST, SubscribeEmailGuestRequest);
}
