import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandlers = {
    [types.CHECK_DELIVERY_OPTIONS]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.CHECK_DELIVERY_OPTIONS_SUCCESS]: (state, { payload }) => ({
        ...state,
        loading: false,
        deliveryOptions: payload || {},
    }),
    [types.CHECK_DELIVERY_OPTIONS_FAILED]: (state) => ({
        ...state,
        loading: false,
    }),

    [types.GET_DELIVERY]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_DELIVERY_SUCCESS]: (state, { payload }) => ({
        ...state,
        loading: false,
        delivery: payload || [],
    }),
    [types.GET_DELIVERY_FAILED]: (state) => ({
        ...state,
        loading: false,
    }),
    [types.GET_COUNTRY_LIST]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_COUNTRY_LIST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loading: false,
        CountryList: payload || [],
    }),
    [types.GET_COUNTRY_LIST_FAILED]: (state) => ({
        ...state,
        loading: false,
    }),
    [types.GET_HOME_DELIVERY_OPTIONS]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_HOME_DELIVERY_OPTIONS_SUCCESS]: (state, { payload }) => ({
        ...state,
        loading: false,
        homeDeliveryOption: payload || {},
    }),
    [types.GET_HOME_DELIVERY_OPTIONS_FAILED]: (state) => ({
        ...state,
        loading: false,
    }),
    [types.SAVE_DELIVERY_ADDRESS]: (state, { payload }) => ({
        ...state,
        selectedAddress: payload
    }),
    [types.SET_DELIVERY]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.SET_DELIVERY_SUCCESS]: (state, { payload }) => ({
        ...state,
        loading: false,
    }),
    [types.SET_DELIVERY_FAILED]: (state) => ({
        ...state,
        loading: false,
    }),

    [types.SAVE_DELIVERY_ADDRESS_REQUEST]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.SAVE_DELIVERY_ADDRESS_REQUEST_FAILED]: (state) => ({
        ...state,
        loading: false,
    }),
    [types.SAVE_DELIVERY_ADDRESS_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loading: false,
    }),
    [types.UPDATE_ADDRESS_REQUEST]: (state, { payload }) => {
        return {
            ...state,
            newAddress: payload,
        }
    },
};

export default handleActions(actionHandlers, {
    loading: false,
    deliveryOptions: {},
    delivery: {},
    CountryList: [],
    newAddress: {
        state: '',
        city: '',
        zipCode: '',
        area: '',
        unitNumber: '',
        address: '',
        phoneNumber: '',
        countryCode: '',
        country: ''
    },
    homeDeliveryOption: {},
    selectedAddress: ''
});
