import { createAction } from 'redux-actions';

const GET_MENU_REQUEST = 'UJ/GET_MENU_REQUEST';
const GET_MENU_REQUEST_SUCCESS = 'UJ/GET_MENU_REQUEST_SUCCESS';
const GET_MENU_REQUEST_FAILED = 'UJ/GET_MENU_REQUEST_FAILED';

const GET_AUTOSEARCH_REQUEST = 'UJ/GET_AUTOSEARCH_REQUEST';
const GET_AUTOSEARCH_REQUEST_SUCCESS = 'UJ/GET_AUTOSEARCH_REQUEST_SUCCESS';
const GET_AUTOSEARCH_REQUEST_FAILED = 'UJ/GET_AUTOSEARCH_REQUEST_FAILED';

const getMenuRequest = createAction(GET_MENU_REQUEST);
const getMenuRequestSuccess = createAction(GET_MENU_REQUEST_SUCCESS);
const getMenuRequestFailed = createAction(GET_MENU_REQUEST_FAILED);

const getAutoSearchRequest = createAction(GET_AUTOSEARCH_REQUEST);
const getAutoSearchRequestSuccess = createAction(GET_AUTOSEARCH_REQUEST_SUCCESS);
const getAutoSearchRequestFailed = createAction(GET_AUTOSEARCH_REQUEST_FAILED);
const clearSearchResult = createAction(GET_AUTOSEARCH_REQUEST_FAILED);
export const actions = {
    getMenuRequest,
    getMenuRequestSuccess,
    getMenuRequestFailed,

    getAutoSearchRequest,
    getAutoSearchRequestSuccess,
    getAutoSearchRequestFailed,
    clearSearchResult,
};

export const types = {
    GET_MENU_REQUEST,
    GET_MENU_REQUEST_SUCCESS,
    GET_MENU_REQUEST_FAILED,

    GET_AUTOSEARCH_REQUEST,
    GET_AUTOSEARCH_REQUEST_SUCCESS,
    GET_AUTOSEARCH_REQUEST_FAILED,
};
