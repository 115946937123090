import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandlers = {
    [types.GET_MENU_REQUEST]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_MENU_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loading: false,
        menuList: payload || [],
    }),
    [types.GET_MENU_REQUEST_FAILED]: (state) => ({
        ...state,
        loading: false,
    }),
    [types.GET_AUTOSEARCH_REQUEST]: (state) => ({
        ...state,
        searchLoading: true,
    }),
    [types.GET_AUTOSEARCH_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        searchLoading: false,
        autoSearchList: payload || {},
    }),
    [types.GET_AUTOSEARCH_REQUEST_FAILED]: (state) => ({
        ...state,
        searchLoading: false,
        autoSearchList: {
            data: { status: 'error' }
        },
    }),
};

export default handleActions(actionHandlers, {
    loading: false,
    menuList: [],
    searchLoading: false,
    autoSearchList: {},
});
