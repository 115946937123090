import { createAction } from 'redux-actions';

//actions types
const UPDATE_CUSTOMER_EMAIL_REQUEST = 'ELC@OROB/UPDATE_CUSTOMER_EMAIL_REQUEST';
const UPDATE_CUSTOMER_EMAIL_REQUEST_SUCCESS = 'ELC@OROB/UPDATE_CUSTOMER_EMAIL_REQUEST_SUCCESS';
const UPDATE_CUSTOMER_EMAIL_REQUEST_FAILED = 'ELC@OROB/UPDATE_CUSTOMER_EMAIL_REQUEST_FAILED';

const DELETE_CUSTOMER_REQUEST = 'ELC@OROB/DELETE_CUSTOMER_REQUEST';
const DELETE_CUSTOMER_REQUEST_SUCCESS = 'ELC@OROB/DELETE_CUSTOMER_REQUEST_SUCCESS';
const DELETE_CUSTOMER_REQUEST_FAILED = 'ELC@OROB/DELETE_CUSTOMER_REQUEST_FAILED';

const SUBSCRIBE_EMAIL_REQUEST = 'ELC@OROB/SUBSCRIBE_EMAIL_REQUEST';
const SUBSCRIBE_EMAIL_REQUEST_SUCCESS = 'ELC@OROB/SUBSCRIBE_EMAIL_REQUEST_SUCCESS';
const SUBSCRIBE_EMAIL_REQUEST_FAILED = 'ELC@OROB/SUBSCRIBE_EMAIL_REQUEST_FAILED';

//actions methods
const UpdateCustomerEmailRequest = createAction(UPDATE_CUSTOMER_EMAIL_REQUEST);
const UpdateCustomerEmailRequestSuccess = createAction(UPDATE_CUSTOMER_EMAIL_REQUEST_SUCCESS);
const UpdateCustomerEmailRequestFailed = createAction(UPDATE_CUSTOMER_EMAIL_REQUEST_FAILED);

const DeleteCustomerRequest = createAction(DELETE_CUSTOMER_REQUEST);
const DeleteCustomerRequestSuccess = createAction(DELETE_CUSTOMER_REQUEST_SUCCESS);
const DeleteCustomerRequestFailed = createAction(DELETE_CUSTOMER_REQUEST_FAILED);

const SubscribeEmailRequest = createAction(SUBSCRIBE_EMAIL_REQUEST);
const SubscribeEmailRequestSuccess = createAction(SUBSCRIBE_EMAIL_REQUEST_SUCCESS);
const SubscribeEmailRequestFailed = createAction(SUBSCRIBE_EMAIL_REQUEST_FAILED);

//actions
export const actions = {
    UpdateCustomerEmailRequest,
    UpdateCustomerEmailRequestSuccess,
    UpdateCustomerEmailRequestFailed,

    DeleteCustomerRequest,
    DeleteCustomerRequestSuccess,
    DeleteCustomerRequestFailed,

    SubscribeEmailRequest,
    SubscribeEmailRequestSuccess,
    SubscribeEmailRequestFailed,
};

//types
export const types = {
    UPDATE_CUSTOMER_EMAIL_REQUEST,
    UPDATE_CUSTOMER_EMAIL_REQUEST_SUCCESS,
    UPDATE_CUSTOMER_EMAIL_REQUEST_FAILED,

    DELETE_CUSTOMER_REQUEST,
    DELETE_CUSTOMER_REQUEST_SUCCESS,
    DELETE_CUSTOMER_REQUEST_FAILED,

    SUBSCRIBE_EMAIL_REQUEST,
    SUBSCRIBE_EMAIL_REQUEST_SUCCESS,
    SUBSCRIBE_EMAIL_REQUEST_FAILED,
};
