import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ApiErrorToast, ApiFalseMessageToast } from '../../../../utils/CommonApiMessage';
import { actions as cartActions } from '../../../main/cart/Basket/redux/actions';
import { actions as jwtaction } from '../../../main/cart/Checkout/Payment/redux/actions';
import { actions as storeAction } from '../../../storeInfo/redux/actions';
import api from '../../api';
import { actions as customerActions } from '../../MyProfile/redux/actions';
import { actions as wishlistActions } from '../../WishList/redux/actions';
import { actions, types } from './actions';

const gdpr = (state) => state && state.storeInfo && state.storeInfo.updateGDPR;

const updateEmailReq = function* updateEmailReq({ payload }) {
    try {
        const { data } = yield call(api.updateEmail, payload);
        if (data && data.status) {
            toast.success(data.message);
            yield put(
                customerActions.getCustomerRequest({
                    customerid: payload.customerid,
                    store_id: payload.store_id,
                }),
            );
            yield put(actions.UpdateCustomerEmailRequestSuccess());
        } else {
            ApiFalseMessageToast();
            yield put(actions.UpdateCustomerEmailRequestFailed());
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.UpdateCustomerEmailRequestFailed());
    }
};

const deleteCustomerReq = function* deleteCustomerReq({ payload }) {
    try {
        const { data } = yield call(api.deleteCustomer, payload.reqData);
        if (data && data.status) {
            toast.success(data.message);
            yield put(actions.DeleteCustomerRequestSuccess());
            yield put(storeAction.setLoginUserDetails(null));
            yield put(storeAction.setQuoteId(null));
            yield put(wishlistActions.getAllWishListRequestSuccess([]));
            yield put(cartActions.getMyCartRequestSuccess(null));
            yield put(storeAction.setGDPRData(null));
            yield put(jwtaction.setOrderJWTToken(null));
            payload.history.push(`/${payload.store_code}/`);
        } else {
            ApiFalseMessageToast();
            yield put(actions.DeleteCustomerRequestFailed());
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.DeleteCustomerRequestFailed());
    }
};

const SubscribeEmailReq = function* SubscribeEmailReq({ payload }) {
    try {
        const updateGDPR = yield select(gdpr);
        const { data } = yield call(api.subscribeEmail, payload);
        if (data && data.status) {
            yield put(actions.SubscribeEmailRequestSuccess());
            yield put(
                storeAction.setGDPRData({
                    ...updateGDPR,
                    subscribed_to_newsletter: payload.subscribe,
                }),
            );
            toast.success(data.message);
        } else {
            ApiFalseMessageToast();
            yield put(actions.SubscribeEmailRequestFailed());
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.SubscribeEmailRequestFailed());
    }
};

export default function* sagas() {
    yield takeLatest(types.UPDATE_CUSTOMER_EMAIL_REQUEST, updateEmailReq);
    yield takeLatest(types.DELETE_CUSTOMER_REQUEST, deleteCustomerReq);
    yield takeLatest(types.SUBSCRIBE_EMAIL_REQUEST, SubscribeEmailReq);
}
