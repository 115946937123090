import { AppBar, Container, Fade, Grid, Hidden, IconButton, Menu, MenuItem, Toolbar, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { actions as wishlistActions } from 'components/account/WishList/redux/actions';
import { isEmail } from 'components/login/utils';
import { actions as cartActions } from 'components/main/cart/Basket/redux/actions';
import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { BsSearch } from 'react-icons/bs';
import { FcMenu } from 'react-icons/fc';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ProductDetails } from 'service/GTMEvent';
import AssistanceIcon from '../../../assets/header/Icon_Assistance_White_Web.svg';
import Icon_Cart_Bag_White_Web from '../../../assets/header/Icon_Cart_Bag_White_Web.svg';
import Icon_User_White_Web from '../../../assets/header/Icon_User_White_Web.svg';
import Icon_Wishlist_White_Web from '../../../assets/header/Icon_Wishlist_White_Web.svg';
import itelyFlage from '../../../assets/itely.png';
import UjLogo from '../../../assets/uj_logo.svg';
import worldFlag from '../../../assets/world.png';
import { actions as jwtaction } from '../../main/cart/Checkout/Payment/redux/actions';
import { actions as storeActions } from '../../storeInfo/redux/actions';
import { actions as deliveryActions } from '../../main/cart/Checkout/Delivery/redux/actions';
import MenuList from './components/HompageL1PopUp/MenuList';
import MiniCart from './components/MiniCart/index';
import MobileSideBar from './components/MobileSideBar';
import ReactTab from './components/reactTab';
// import ReactTab from './components/reactTab';
import { actions as MenuActions } from './redux/actions';

// const ReactTab = lazy(() => import('./components/reactTab'));
// const MobileSideBar = lazy(() => import('./components/MobileSideBar'));
// const MiniCart = lazy(() => import('./components/MiniCart/index'));
// const MenuList = lazy(() => import('./components/HompageL1PopUp/MenuList'));
const AssistancePopup = lazy(() => import('./components/AssistancePopup/index'));
const CountryPopup = lazy(() => import('./components/CountryPopup/index'));

const useStyles = makeStyles((theme) => ({
    grow: {},
    menuButton: {
        marginRight: theme.spacing(0.5),
    },
    navBar: {
        backgroundColor: '#000',
        position: 'fixed',

        // justifyContent: 'space-between !important',
        // [theme.breakpoints.up('xl')]: {import { LocalStorage } from 'utils/LocalStorage';

        //     padding: '00px 40px 00px',
        // },
    },
    iconButton: {
        display: 'flex',
        flexDirection: 'column',
    },
    search: {
        position: 'relative',
        borderRadius: 30,
        backgroundColor: '#fff',
        color: '#000',
        marginRight: theme.spacing(1),

        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
        //padding: '0px 56px 0px 3px',
        height: '37px',
    },
    searchIcon: {
        padding: theme.spacing(0, 0.6),
        height: '100%',
        position: 'absolute',
        top: 0,
        right: 0,
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    overlay: {
        top: 0,
        right: 0,
        height: '100vh',
        width: '100vw',
        position: 'fixed',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        borderRadius: 10,
    },
    links: {
        textDecoration: 'none',
        width: 'max-content',
    },
}));

const NavBar = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const pathname = window.location.pathname;
    const path = pathname.split('/');

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const menuList = useSelector((state) => state && state.menu && state.menu.menuList && state.menu.menuList.data);
    const store_code = useSelector((state) => state && state.storeInfo && state.storeInfo.store_code);
    const loginUser = useSelector((state) => state && state.storeInfo && state.storeInfo.loginUser);
    const language = useSelector((state) => state && state.storeInfo && state.storeInfo.language);

    const store_id = useSelector((state) => state && state.storeInfo && state.storeInfo.store_id);
    const cartData = useSelector((state) => state && state.cart && state.cart.myCartData);
    const country = useSelector((state) => state && state.storeInfo && state.storeInfo.country);
    const hompageLanguagePopUp = useSelector(
        (state) => state && state.storeInfo && state.storeInfo.hompageLanguagePopUp,
    );

    let AutoSuggestionData = useSelector(
        (state) =>
            state &&
            state.menu &&
            state.menu.autoSearchList &&
            state.menu.autoSearchList.data
    );

    let searchLoading = useSelector(
        (state) =>
            state &&
            state.menu &&
            state.menu.searchLoading
    );

    let AutoSuggestionCat = useSelector(
        (state) =>
            state &&
            state.menu &&
            state.menu.autoSearchList &&
            state.menu.autoSearchList.data &&
            state.menu.autoSearchList.data.category_data,
    );

    let isMiniCartOpen = useSelector(
        (state) => state && state.cart && state.cart.isMiniCartOpen
    );

    /** Get menu list */
    useEffect(() => {
        dispatch(
            MenuActions.getMenuRequest({
                store_id: store_id,
            }),
        );
    }, [dispatch, store_id]);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (
                (document.getElementById('minicart-web') && document.getElementById('minicart-web').contains(e.target)) ||
                (document.getElementById('minicart-mob') && document.getElementById('minicart-mob').contains(e.target)) ||
                (document.getElementById('add-to-cart-drawer-button') && document.getElementById('add-to-cart-drawer-button').contains(e.target)) ||
                (document.getElementById('add-to-cart-button') && document.getElementById('add-to-cart-button').contains(e.target))
            ) {
                // Clicked in the box or in the "Add to Cart" button
            } else {
                isMiniCartOpen && dispatch(cartActions.setMiniCartToOpen(false));
            }
        }
        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('click', handleClickOutside);
        }
    });

    const [drawerState, setDrawerState] = useState(false);
    const [isAssistanceOpen, setIsAssistanceOpen] = React.useState(false);
    const [isCountryPopup, setCountryPopup] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const [showL2, setShowL2] = React.useState(false);
    var [cursorPointer, setCursorPointer] = useState(-1);

    const [predictiveSearch, setPredictiveSearch] = React.useState('');
    const [showProductDropDown, setShowProductDropDown] = React.useState(false);

    /** Redirect To login screen */
    const onClickLogin = () => {
        if (!loginUser) {
            return history.push(`/${store_code}/sign-in`);
        }
    };

    React.useEffect(() => {
        if (hompageLanguagePopUp && !isCountryPopup) {
            setCountryPopup(true);
        }
    }, [hompageLanguagePopUp, isCountryPopup]);

    /** According user status move wishlist or login screen */
    const onClickWishlist = () => {
        if (!loginUser) {
            return history.push(`/${store_code}/sign-in`);
        } else {
            return history.push(`/${store_code}/wishlist`);
        }
    };

    /**Redirect To Home screen
     * after click on logo
     */
    const onClickLogo = () => {
        return history.push(`/${store_code}`);
    };

    const getApiCall = (value) => {
        setPredictiveSearch(value);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (predictiveSearch.length > 2) {
                dispatch(
                    MenuActions.getAutoSearchRequest({
                        q: predictiveSearch,
                        storeid: store_id,
                    }),
                );
            } else if (predictiveSearch.length === 0) {
                dispatch(MenuActions.clearSearchResult({
                    q: predictiveSearch,
                    storeid: store_id,
                }));
            }
        }, 1000);
        return () => {
            clearTimeout(timer);
        }
    }, [predictiveSearch])

    const gotoSearchResult = () => {
        dispatch(
            MenuActions.clearSearchResult({
                q: predictiveSearch,
                storeid: store_id,
            }),
        );
        setShowProductDropDown(false)
        document?.getElementById('clearFilter')?.click('clearFilter');
        history.push(`/${store_code}/search?query=${predictiveSearch}`);
        setPredictiveSearch('');
    };

    useEffect(() => {
        if (predictiveSearch.length > 2) {
            setShowProductDropDown(true)
        }
    }, [predictiveSearch])

    /** Redirect to PDP page
     * after select any product on search list
     */
    const gotoProductPage = (url_key, page) => {
        // if (page === 'PDP') {
        //     localStorage.setItem('backButton', true);
        // }
        history.push(`/${store_code}/${url_key.toLowerCase()}`);
        dispatch(
            MenuActions.clearSearchResult({
                q: predictiveSearch,
                storeid: store_id,
            }),
        );
        setPredictiveSearch('');
    };

    /** Redirect to Cart page */
    const handleNavigation = () => {
        // history.push(`/${store_code}/cart`);
        dispatch(cartActions.setMiniCartToOpen(!isMiniCartOpen));
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // To hide search auto suggession
    function HandleOutsideClick(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setSearch('');
                }
            }

            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    const wrapperRefMob = useRef(null);
    !isMobile && HandleOutsideClick(wrapperRef);
    isMobile && HandleOutsideClick(wrapperRefMob);

    const clickL2 = (e) => {
        e === false && setShowL2(e);
    };

    const handleKeyPress = (e) => {
        let searchResult = e.target.value;
        if (e.keyCode === 13) {
            dispatch(
                MenuActions.clearSearchResult({
                    q: searchResult,
                    storeid: store_id,
                }),
            );
            document?.getElementById('clearFilter')?.click('clearFilter');
            if (predictiveSearch.length > 0) {
                history.push(`/${store_code}/search?query=${predictiveSearch}`);
            }
            setPredictiveSearch('');
        }
    };

    return (
        <div className={classes.grow}>
            <AppBar position="static" className={classes.navBar}>
                <div className="container_main">
                    <Toolbar style={{ height: '84px', position: 'static' }}>
                        <div className="logo_image">
                            <Hidden only={['xl', 'lg', 'md']}>
                                <IconButton
                                    edge="start"
                                    className={classes.menuButton}
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={() => setDrawerState(true)}
                                >
                                    <FcMenu style={{ color: '#fff' }} />
                                </IconButton>
                            </Hidden>
                            <Hidden only={['xl', 'lg', 'md']}>
                                <MobileSideBar
                                    drawerState={drawerState}
                                    setDrawerState={setDrawerState}
                                    setCountryPopup={setCountryPopup}
                                    setIsAssistanceOpen={setIsAssistanceOpen}
                                ></MobileSideBar>
                            </Hidden>
                            <img
                                src={UjLogo}
                                alt="Urban Jungle"
                                className="header-logo"
                                onClick={() => onClickLogo()}
                            />
                        </div>
                        <Hidden only={['xs', 'sm']}>
                            <div className="nav-link" style={{ overflowX: 'scroll', width: '37%' }}>
                                <ul style={{ overflowX: 'scroll' }}>
                                    {menuList &&
                                        Object.entries(menuList).map((category, index) => {
                                            if (category[1][0].url_key === 'blog')
                                                return (
                                                    <li key={index} className="nav-link-dropdown">
                                                        <a
                                                            href={process.env.REACT_APP_BLOG_URL}
                                                            style={{ textDecoration: 'none' }}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className={classes.links}
                                                        >
                                                            <Typography className="nav-link-name" component="a">
                                                                {category[1][0].name}
                                                            </Typography>
                                                        </a>
                                                    </li>
                                                );

                                            return (
                                                <li key={index} className="nav-link-dropdown">
                                                    <Link
                                                        to={`/${store_code}/${category[1][0].url_key}`}
                                                        className={classes.links}
                                                    >
                                                        <Typography
                                                            className="nav-link-name"
                                                            component="a"
                                                            onMouseOver={() => setShowL2(true)}
                                                            onClick={() => setShowL2(false)}
                                                        >
                                                            {category[1][0].name}
                                                        </Typography>
                                                    </Link>
                                                    {category[1].length > 1 && (
                                                        <div
                                                            className="dropdown-menu-v1"
                                                            aria-labelledby="navbarDropdownMenuLink"
                                                        >
                                                            {showL2 && (
                                                                <ReactTab
                                                                    category={category}
                                                                    L2cat={(e) => clickL2(e)}
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </li>
                                            );
                                        })}
                                    {/* Brands */}
                                    <li key="brands" className="nav-link-dropdown category-name">
                                        <Link to={`/${store_code}/brands`} className={classes.links}>
                                            <Typography className="nav-link-name" component="a">
                                                <FormattedMessage
                                                    id="NavigationBar.Brands.Text"
                                                    defaultMessage="Brands"
                                                />
                                            </Typography>
                                        </Link>
                                    </li>

                                    {/* Brand end */}
                                </ul>
                            </div>
                        </Hidden>
                        <Hidden only={['lg']}>
                            <div className={classes.grow} />
                        </Hidden>

                        <div className="icon-container" ref={wrapperRef}>
                            <Hidden only={['xs', 'sm', 'md']}>
                                <div className={`${classes.search} header-search-box`}>
                                    <FormattedMessage
                                        id="Placeholder.Whatare"
                                        defaultMessage="What are you looking for?"
                                    >
                                        {(placeholder) => (
                                            <input
                                                placeholder={placeholder}
                                                className="header-search"
                                                style={{ padding: 1, margin: 8 }}
                                                // inputProps={{ 'aria-label': 'search' }}
                                                onChange={(e) => {
                                                    getApiCall(e.target.value);
                                                }}
                                                onClick={() => { setShowProductDropDown(true) }}
                                                onKeyDown={(e) => handleKeyPress(e)}
                                                value={predictiveSearch}
                                            />
                                        )}
                                    </FormattedMessage>

                                    <div className={classes.searchIcon}>
                                        <BsSearch size={10} />
                                    </div>
                                    {{
                                        "ok-true": !searchLoading ? <>
                                            <Container fixed className={classes.overlay}
                                                onClick={() => {
                                                    setShowProductDropDown(false)
                                                }}></Container>
                                            <Grid className="auto-suggestion-products">
                                                {AutoSuggestionData &&
                                                    AutoSuggestionData.product_data &&
                                                    AutoSuggestionData.product_data.map((product, index) => {
                                                        return (
                                                            <Grid
                                                                container
                                                                className={'product-data'}
                                                                alignItems="center"
                                                                alignContent="center"
                                                            >
                                                                <Grid
                                                                    onClick={() => {
                                                                        gotoProductPage(product.url, 'PDP');
                                                                        ProductDetails(product, 'Search Results');
                                                                    }}
                                                                    key={index}
                                                                    container
                                                                    item
                                                                    md={8}
                                                                    className="product-data-tab"
                                                                    id={`AutoSuggestionData${index}`}
                                                                    style={
                                                                        cursorPointer === index
                                                                            ? { background: '#eeeeee' }
                                                                            : null
                                                                    }
                                                                >
                                                                    <Grid item md={3}>
                                                                        <img
                                                                            className="product-image"
                                                                            src={product.image}
                                                                            alt="product"
                                                                        />
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        className="product-name-container"
                                                                        md={8}
                                                                    >
                                                                        <Typography
                                                                            className="product-name"
                                                                            align="center"
                                                                        >
                                                                            {product.name}
                                                                        </Typography>
                                                                        {/* <Typography className="product-name">{product.name}</Typography> */}
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item md={1} />
                                                                <Grid
                                                                    onClick={() =>
                                                                        gotoProductPage(product.categories[0].name, 'PLP')
                                                                    }
                                                                    key={index}
                                                                    container
                                                                    item
                                                                    md={3}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        className="product-name-container"
                                                                        md={8}
                                                                    >
                                                                        <Typography className="product-name">
                                                                            {product.categories && product.categories[0] && product.categories[0].name}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                                <Grid onClick={() => gotoSearchResult()} className="show-all-button">
                                                    <Typography className="show-all-text">
                                                        <FormattedMessage
                                                            id="NavigationBar.AllResult.Text"
                                                            defaultMessage="All Search Result"
                                                        />
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </> :
                                            <>
                                                <div fixed className={classes.overlay}
                                                    onClick={() => {
                                                        setPredictiveSearch('')
                                                    }}></div>
                                                <Grid className="auto-suggestion-products">
                                                    <Grid className="show-all-button">
                                                        <Typography className="show-all-text">
                                                            <div className={classes.circularIcon}>
                                                                {" "}
                                                                <CircularProgress
                                                                    style={{ border: "solid transparent 2px" }}
                                                                    size={30}
                                                                />
                                                            </div>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </>,
                                        "error-true":
                                            <>
                                                <Container fixed className={classes.overlay}
                                                    onClick={() => {
                                                        setShowProductDropDown(false)
                                                    }}></Container>
                                                <Grid className="auto-suggestion-products">
                                                    <Grid className="show-all-button">
                                                        <Typography className="show-all-text">
                                                            <FormattedMessage
                                                                id="NavigationBar.NoProduct.Text"
                                                                defaultMessage="No Results Found"
                                                            />
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </>
                                    }[`${AutoSuggestionData && AutoSuggestionData.status}-${showProductDropDown}`]}
                                </div>
                            </Hidden>
                            <Hidden only={['xs', 'sm']}>
                                <div className="icon-item-container" onClick={() => setCountryPopup(true)}>
                                    <IconButton className="icon-item country-container" size="small">
                                        <img
                                            className="flag-image"
                                            src={language === 'it' ? itelyFlage : worldFlag}
                                            alt=""
                                        />
                                    </IconButton>
                                    <Typography
                                        className="icon-title t-marg"
                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                        {/* <FormattedMessage
                                        id="NavigationBar.Italy.Text"
                                        defaultMessage={country === 'it' ? 'Italy' : 'International'}
                                    /> */}
                                        {language === 'it' ? (
                                            <FormattedMessage
                                                id="NavigationBar.Italy.Text"
                                                defaultMessage="Italy"
                                            />
                                        ) : (
                                            'International'
                                        )}
                                    </Typography>
                                </div>
                            </Hidden>
                            <div className="icon-item-container" onClick={() => setIsAssistanceOpen(true)}>
                                <IconButton className="icon-item assistance_m" color="inherit" size="small">
                                    <img src={AssistanceIcon} alt="assistanceIcon" />
                                </IconButton>
                                <Hidden only={['xs', 'sm']}>
                                    <Typography className="icon-title" style={{ cursor: 'pointer' }}>
                                        <FormattedMessage
                                            id="NavigationBar.Assistance.Text"
                                            defaultMessage="Assistance"
                                        />
                                    </Typography>
                                </Hidden>
                            </div>
                            <Hidden only={['xs', 'sm']}>
                                <div className="icon-item-container" onClick={() => onClickWishlist()}>
                                    <IconButton className="icon-item" size="small" color="inherit">
                                        <img src={Icon_Wishlist_White_Web} alt="userIcon" />
                                    </IconButton>

                                    <Typography className="icon-title" style={{ cursor: 'pointer' }}>
                                        <FormattedMessage id="NavigationBar.Wishlist.Text" defaultMessage="Wishlist" />
                                    </Typography>
                                </div>
                            </Hidden>
                            <Hidden only={['xs', 'sm']}>
                                <div
                                    className="icon-item-container"
                                    aria-controls="fade-menu"
                                    aria-haspopup="true"
                                    onClick={(e) => {
                                        if (loginUser && loginUser.customer_id) {
                                            handleClick(e);
                                        }
                                    }}
                                >
                                    <IconButton
                                        className="icon-item"
                                        onClick={() => onClickLogin()}
                                        size="small"
                                        color="inherit"
                                    >
                                        <img src={Icon_User_White_Web} alt="userIcon" />
                                    </IconButton>
                                    <Typography
                                        className="icon-title userLoginName"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => onClickLogin()}
                                    >
                                        {loginUser && loginUser.customer_id ? (
                                            <>
                                                <FormattedMessage id="Hi" defaultMessage="hi" />
                                                ,&nbsp;{loginUser.firstname}
                                            </>
                                        ) : (
                                            <FormattedMessage id="Common.Login" defaultMessage="Login" />
                                        )}
                                    </Typography>
                                </div>
                                <Menu
                                    id="fade-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={open}
                                    onClose={handleClose}
                                    TransitionComponent={Fade}
                                    className="account_dropDown"
                                >
                                    <MenuItem
                                        onClick={() => {
                                            handleClose();
                                            history.push(`/${store_code}/my-account`);
                                        }}
                                    >
                                        <FormattedMessage id="Common.AccountTitle.Text" defaultMessage="My Account" />
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            handleClose();
                                            dispatch(storeActions.setLoginUserDetails(null));
                                            dispatch(storeActions.setQuoteId(null));
                                            dispatch(wishlistActions.getAllWishListRequestSuccess([]));
                                            dispatch(cartActions.getMyCartRequestSuccess(null));
                                            dispatch(storeActions.setGDPRData(null));
                                            dispatch(jwtaction.setOrderJWTToken(null));
                                            dispatch(storeActions.ecomLogin(false));
                                            dispatch(deliveryActions.saveDeliveryAddress(""),);
                                            history.push(`/${store_code}/sign-in`);
                                        }}
                                    >
                                        {' '}
                                        <FormattedMessage id="Logout" defaultMessage="Log out" />
                                    </MenuItem>
                                </Menu>
                            </Hidden>
                            <Hidden only={['md', 'xs', 'sm']}>
                                <div
                                    id="minicart-web"
                                    className="flex-align-center"
                                    onClick={() => dispatch(cartActions.setMiniCartToOpen(!isMiniCartOpen))}
                                    style={{ height: '100%', minHeight: '80px' }}
                                >
                                    <div
                                        className="icon-item-container"
                                        style={{ margin: `6px 6px 6px 0px`, position: 'relative' }}
                                    >
                                        <IconButton className="icon-item" color="inherit" size="small">
                                            <img src={Icon_Cart_Bag_White_Web} alt="cartIcon" />
                                        </IconButton>
                                        <Hidden only={['xs', 'sm']}>
                                            <Typography className="icon-title" style={{ cursor: 'pointer' }}>
                                                <FormattedMessage id="NavigationBar.Cart.Text" defaultMessage="Cart" />
                                            </Typography>
                                        </Hidden>
                                        {isMiniCartOpen && (
                                            <div>
                                                <MiniCart />
                                            </div>
                                        )}
                                    </div>
                                    <div className="Cart-count-container">
                                        <Typography className="cart-count">
                                            {(cartData &&
                                                cartData.products &&
                                                cartData.products.reduce(function (a, b) {
                                                    return a + b.qty;
                                                }, 0)) ||
                                                0}
                                        </Typography>
                                    </div>
                                </div>
                            </Hidden>
                            <Hidden only={['lg', 'xl']}>
                                <div
                                    id="minicart-mob"
                                    className="flex-align-center"
                                    onClick={() => handleNavigation()}
                                    style={{ height: '100%', minHeight: '80px' }}
                                >
                                    <div
                                        className="icon-item-container"
                                        style={{ margin: `6px 6px 6px 0px`, position: 'relative' }}
                                    >
                                        <IconButton className="icon-item" color="inherit" size="small">
                                            <img src={Icon_Cart_Bag_White_Web} alt="cartIcon" />
                                        </IconButton>
                                        <Hidden only={['xs', 'sm']}>
                                            <Typography className="icon-title" style={{ cursor: 'pointer' }}>
                                                <FormattedMessage id="NavigationBar.Cart.Text" defaultMessage="Cart" />
                                            </Typography>
                                        </Hidden>
                                        {isMiniCartOpen && (
                                            <div>
                                                <MiniCart />
                                            </div>
                                        )}
                                    </div>
                                    <div className="Cart-count-container">
                                        <Typography className="cart-count">
                                            {(cartData &&
                                                cartData.products &&
                                                cartData.products.reduce(function (a, b) {
                                                    return a + b.qty;
                                                }, 0)) ||
                                                0}
                                        </Typography>
                                    </div>
                                </div>
                            </Hidden>
                        </div>
                        <Suspense fallback={''}>
                            <AssistancePopup isOpen={isAssistanceOpen} setIsAssistanceOpen={setIsAssistanceOpen} />
                            <CountryPopup isOpen={isCountryPopup} setCountryPopup={setCountryPopup} />
                        </Suspense>
                    </Toolbar>
                </div>
            </AppBar>
            <Hidden only={['lg', 'xl']}>
                <div className="mobile-nav" ref={wrapperRefMob}>
                    <Grid container justify="center">
                        <Grid item xs={11}>
                            <div className="mobile_search_div">
                                <FormattedMessage id="Placeholder.Whatare" defaultMessage="What are you looking for?">
                                    {(placeholder) => (
                                        <input
                                            placeholder={placeholder}
                                            className="header-search"
                                            // inputProps={{ 'aria-label': 'search' }}
                                            onChange={(e) => getApiCall(e.target.value)}
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            onClick={() => { setShowProductDropDown(true) }}
                                            value={predictiveSearch}
                                        />
                                    )}
                                </FormattedMessage>

                                <div className={classes.searchIcon}>
                                    <BsSearch />
                                </div>


                                {{
                                    "ok-true": !searchLoading ? <>
                                        <Container fixed className={classes.overlay}
                                            onClick={() => {
                                                setShowProductDropDown(false)
                                            }}></Container>
                                        <Grid className="auto-suggestion-products">
                                            {AutoSuggestionData &&
                                                AutoSuggestionData.product_data &&
                                                AutoSuggestionData.product_data.map((product, index) => {
                                                    return (
                                                        <Grid
                                                            container
                                                            className={'product-data'}
                                                            alignItems="center"
                                                            alignContent="center"
                                                        >
                                                            <Grid
                                                                onClick={() => {
                                                                    gotoProductPage(product.url, 'PDP');
                                                                    ProductDetails(product, 'Search Results');
                                                                }}
                                                                key={index}
                                                                container
                                                                item
                                                                md={8}
                                                                className="product-data-tab"
                                                                id={`AutoSuggestionData${index}`}
                                                                style={
                                                                    cursorPointer === index
                                                                        ? { background: '#eeeeee' }
                                                                        : null
                                                                }
                                                            >
                                                                <Grid item md={3}>
                                                                    <img
                                                                        className="product-image"
                                                                        src={product.image}
                                                                        alt="product"
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    className="product-name-container"
                                                                    md={8}
                                                                >
                                                                    <Typography
                                                                        className="product-name"
                                                                        align="center"
                                                                    >
                                                                        {product.name}
                                                                    </Typography>
                                                                    {/* <Typography className="product-name">{product.name}</Typography> */}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item md={1} />
                                                            <Grid
                                                                onClick={() =>
                                                                    gotoProductPage(product.categories[0].name, 'PLP')
                                                                }
                                                                key={index}
                                                                container
                                                                item
                                                                md={3}
                                                            >
                                                                <Grid
                                                                    item
                                                                    className="product-name-container"
                                                                    md={8}
                                                                >
                                                                    <Typography className="product-name">
                                                                        {product.categories && product.categories[0] && product.categories[0].name}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}
                                            <Grid onClick={() => gotoSearchResult()} className="show-all-button">
                                                <Typography className="show-all-text">
                                                    <FormattedMessage
                                                        id="NavigationBar.AllResult.Text"
                                                        defaultMessage="All Search Result"
                                                    />
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </> :
                                        <>
                                            <div fixed className={classes.overlay}
                                                onClick={() => {
                                                    setPredictiveSearch('')
                                                }}></div>
                                            <Grid className="auto-suggestion-products">
                                                <Grid className="show-all-button">
                                                    <Typography className="show-all-text">
                                                        <div className={classes.circularIcon}>
                                                            {" "}
                                                            <CircularProgress
                                                                style={{ border: "solid transparent 2px" }}
                                                                size={30}
                                                            />
                                                        </div>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </>,
                                    "error-true":
                                        <>
                                            <Container fixed className={classes.overlay}
                                                onClick={() => {
                                                    setShowProductDropDown(false)
                                                }}></Container>
                                            <Grid className="auto-suggestion-products">
                                                <Grid className="show-all-button">
                                                    <Typography className="show-all-text">
                                                        <FormattedMessage
                                                            id="NavigationBar.NoProduct.Text"
                                                            defaultMessage="No Results Found"
                                                        />
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </>
                                }[`${AutoSuggestionData && AutoSuggestionData.status}-${showProductDropDown}`]}
                            </div>
                        </Grid>
                    </Grid>
                    {path && path.length === 3 && path[2] !== 'home' && path[2] !== '' && (
                        <Hidden only={['md', 'lg', 'xl']}>
                            <div>
                                <div className="line" />
                            </div>
                        </Hidden>
                    )}

                    {path && (path.length === 2 || (path.length === 3 && (path[2] === 'home' || path[2] === ''))) && (
                        <Hidden only={['md']}>
                            <MenuList />
                        </Hidden>
                    )}
                </div>
            </Hidden >
        </div >
    );
};
export default NavBar;
