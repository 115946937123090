import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandlers = {
    [types.ECOM_LOGIN_REQ]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.ECOM_LOGIN_REQ_SUCCESS]: (state) => ({
        ...state,
        loading: false,
    }),
    [types.ECOM_LOGIN_REQ_FAILED]: (state) => ({
        ...state,
        loading: false,
    }),
    [types.SOCIAL_FACEBOOK_LOGIN_REQ]: (state) => ({
        ...state,
        fb_loading: true,
    }),
    [types.SOCIAL_FACEBOOK_LOGIN_REQ_SUCCESS]: (state) => ({
        ...state,
        fb_loading: false,
    }),
    [types.SOCIAL_FACEBOOK_LOGIN_REQ_FAILED]: (state) => ({
        ...state,
        fb_loading: false,
    }),
    [types.SOCIAL_GOOGLE_LOGIN_REQ]: (state) => ({
        ...state,
        gl_loading: true,
    }),
    [types.SOCIAL_GOOGLE_LOGIN_REQ_SUCCESS]: (state) => ({
        ...state,
        gl_loading: false,
    }),
    [types.SOCIAL_GOOGLE_LOGIN_REQ_FAILED]: (state) => ({
        ...state,
        gl_loading: false,
    }),
    [types.FORGOT_PASSWORD_REQ]: (state) => ({
        ...state,
        fp_loading: true,
    }),
    [types.FORGOT_PASSWORD_REQ_SUCCESS]: (state) => ({
        ...state,
        fp_loading: false,
    }),
    [types.FORGOT_PASSWORD_REQ_FAILED]: (state) => ({
        ...state,
        fp_loading: false,
    }),
    [types.RESET_PASSWORD_REQ]: (state) => ({
        ...state,
        rp_loading: true,
    }),
    [types.RESET_PASSWORD_REQ_SUCCESS]: (state) => ({
        ...state,
        rp_loading: false,
    }),
    [types.RESET_PASSWORD_REQ_FAILED]: (state) => ({
        ...state,
        rp_loading: false,
    }),
    [types.SOCIAL_REGISTER_CHECKOUT]: (state, payload) => ({
        ...state,
        social_register: payload.payload,
    }),
};

export default handleActions(actionHandlers, {
    loading: false,
    fb_loading: false,
    gl_loading: false,
    fp_loading: false,
    rp_loading: false,
    social_register: false,
});
