import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import 'react-web-tabs/dist/react-web-tabs.css';
import { v4 as uuidv4 } from 'uuid';
import title from '../MobileSideBar/title.js';
import './style.css';

const ReactTab = ({ category, L2cat }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const state = useSelector((state) => state);

    const ImageComponent = () => {
        let categoryImages = category;
        categoryImages && categoryImages[1][0] && Object.values(categoryImages[1][0]);
        let categoryImage =
            categoryImages && categoryImages[1] && categoryImages && categoryImages[1][0] && categoryImages[1][0];
        return (
            <Grid item xs={5}>
                <div className="images-item">
                    <Grid container spacing={2} alignItems="center" justify="center" direction="row">
                        <Grid item xs={6}>
                            <center>
                                <div className="product-image">
                                    <Link
                                        onClick={() => L2cat(false)}
                                        to={`/${store_code}/${categoryImage && categoryImage.cat_desk_banner_one_url}`}
                                    >
                                        <img
                                            src={
                                                `${categoryImage.cat_desk_banner_one}` ||
                                                'https://source.unsplash.com/400x300/?nike'
                                            }
                                            alt="category banner one"
                                        />

                                        <Typography align="center" variant="caption" className="product-title">
                                            {categoryImage.cat_desk_banner_one_title}
                                        </Typography>
                                    </Link>
                                </div>
                            </center>
                        </Grid>
                        <Grid item xs={6}>
                            <center>
                                <div className="product-image">
                                    <Link
                                        onClick={() => {
                                            L2cat(false);
                                        }}
                                        to={`/${store_code}/${categoryImage && categoryImage.cat_desk_banner_two_url}`}
                                    >
                                        <img
                                            src={
                                                `${categoryImage.cat_desk_banner_two}` ||
                                                'https://source.unsplash.com/400x300/?addidas'
                                            }
                                            alt="category banner two"
                                        />

                                        <Typography align="center" variant="caption" className="product-title">
                                            {categoryImage.cat_desk_banner_two_title}
                                        </Typography>
                                    </Link>
                                </div>
                            </center>
                        </Grid>
                        <Grid item xs={6}>
                            <center>
                                <div className="product-image">
                                    <Link
                                        onClick={() => L2cat(false)}
                                        to={`/${store_code}/${
                                            categoryImage && categoryImage.cat_desk_banner_three_url
                                        }`}
                                    >
                                        <img
                                            src={
                                                `${categoryImage.cat_desk_banner_three}` ||
                                                'https://source.unsplash.com/400x300/?shoes'
                                            }
                                            alt="category banner three"
                                        />

                                        <Typography align="center" variant="caption" className="product-title">
                                            {categoryImage.cat_desk_banner_three_title}
                                        </Typography>
                                    </Link>
                                </div>
                            </center>
                        </Grid>
                        <Grid item xs={6}>
                            <center>
                                <div className="product-image">
                                    <Link
                                        onClick={() => L2cat(false)}
                                        to={`/${store_code}/${categoryImage && categoryImage.cat_desk_banner_four_url}`}
                                    >
                                        <img
                                            src={
                                                `${categoryImage.cat_desk_banner_four}` ||
                                                'https://source.unsplash.com/400x300/?jackets'
                                            }
                                            alt="category banner four"
                                        />

                                        <Typography align="center" variant="caption" className="product-title">
                                            {categoryImage.cat_desk_banner_four_title}
                                        </Typography>
                                    </Link>
                                </div>
                            </center>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        );
    };

    const storeInfo = state && state.storeInfo;
    const store_code = storeInfo && storeInfo.store_code;

    const convertL2ToL3 = (childrens) => {
        let categoryObject = {
            by_gender: {
                title: title.gender,
                categories: [],
            },
            by_category: {
                title: title.category,
                categories: [],
            },
            by_brand: {
                title: title.brand,
                categories: [],
            },
            by_trend: {
                title: title.trend,
                categories: [],
            },
            null: {
                title: '',
                categories: [],
            },
        };

        childrens &&
            childrens.forEach((cat) => categoryObject[cat.tagging] && categoryObject[cat.tagging].categories.push(cat));

        return (
            <>
                <div className="l3-category-main-container">
                    {Object.values(categoryObject).map((category) => {
                        if (!category.categories || category.categories.length === 0) return null;
                        return (
                            <div className="l3-category-div" key={uuidv4()}>
                                {category.title && <h6 className="title">{category.title}</h6>}
                                {category.categories.map((subCat, index) => {
                                    return (
                                        <div key={uuidv4()}>
                                            <div className="category-name">
                                                <Link
                                                    key={`${subCat}-${index}`}
                                                    to={`/${store_code}/${subCat.url_key}`}
                                                    onClick={() => L2cat(false)}
                                                >
                                                    <Typography className=" menu-item-label">{subCat.name}</Typography>
                                                </Link>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };

    const categorySeperationHelper = (category) => {
        let categoryObject = {
            by_gender: {
                title: title.gender,
                categories: [],
            },
            by_category: {
                title: title.category,
                categories: [],
            },
            by_brand: {
                title: title.brand,
                categories: [],
            },
            by_trend: {
                title: title.trend,
                categories: [],
            },
            null: {
                title: title.more,
                categories: [],
            },
        };

        category &&
            category[1][1] &&
            Object.values(category[1][1]).forEach((L2category, index) => {
                categoryObject[L2category.children[0].tagging] &&
                    categoryObject[L2category.children[0].tagging].categories.push(L2category);
            });
        const L3_TYPE = [title.gender, title.category, title.brand, title.trend];

        categoryObject.isPureL2 = Object.values(categoryObject).every((item, index) => {
            if (L3_TYPE.includes(item.title) && item.categories.length > 0) {
                return false;
            }
            return true;
        });

        if (categoryObject.isPureL2) {
            return (
                <div className="container_main">
                    <div className="row h-100">
                        <div className="col-sm-2 yellow-background">
                            {categoryObject.null &&
                                categoryObject.null.categories.map((category, index) => {
                                    return (
                                        <Link
                                            onClick={() => {
                                                L2cat(false);
                                            }}
                                            className="tab-links"
                                            onMouseOver={() => setTabIndex(index)}
                                            key={uuidv4()}
                                            data-active={tabIndex === index}
                                            to={`/${store_code}/${category.children[0].url_key}`}
                                        >
                                            <span>{category.children[0].name}</span>
                                        </Link>
                                    );
                                })}
                        </div>
                        <div className="col-sm-10 l2-main">
                            {categoryObject.null &&
                                categoryObject.null.categories.map((category, index) => {
                                    return (
                                        <div
                                            id={category.children[0].name}
                                            key={uuidv4()}
                                            className={tabIndex === index ? 'show' : 'hide'}
                                        >
                                            <Grid container spacing={2} className="l2-conatiner">
                                                <Grid item xs={7} style={{ paddingLeft: '15px' }}>
                                                    <div className="menu-item">
                                                        <Grid container spacing={2} className="h-100">
                                                            {category.sub_children &&
                                                            category.sub_children.length > 0 ? (
                                                                <Grid item xs={12}>
                                                                    {convertL2ToL3(category.sub_children)}
                                                                </Grid>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    className="d-flex justify-center align-items-center"
                                                                >
                                                                    <Typography className="No-category-msg">
                                                                        <FormattedMessage
                                                                            id="Tab.NoCategory.Text"
                                                                            defaultMessage="No sub categories available for"
                                                                        />
                                                                        &nbsp;
                                                                        {category.children[0].name}
                                                                    </Typography>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </div>
                                                </Grid>

                                                {ImageComponent()}
                                            </Grid>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="container_main">
                    <div className="row h-100">
                        <div className="col-sm-2 yellow-background"></div>
                        <div
                            className="l3-category-main-container col-sm-10 p-4 overflow-auto pure-l2"
                            style={{ maxHeight: '70vh' }}
                        >
                            <div className="col-sm-7" style={{ display: 'flex' }}>
                                {Object.values(categoryObject).map((category) => {
                                    if (!category.categories || category.categories.length === 0) return null;
                                    return (
                                        <div className="l3-category-div" key={uuidv4()}>
                                            {category.title && <h6 className="title">{category.title}</h6>}
                                            {category.categories.map((subCat, index) => {
                                                return (
                                                    <div className="category-name" key={uuidv4()}>
                                                        <Link
                                                            key={`${subCat.children[0].url_key}-${index}`}
                                                            to={`/${store_code}/${subCat.children[0].url_key}`}
                                                            onClick={() => L2cat(false)}
                                                        >
                                                            <Typography className="menu-item-label">
                                                                {subCat.children[0].name}
                                                            </Typography>
                                                        </Link>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </div>

                            {ImageComponent()}
                        </div>
                    </div>
                </div>
            );
        }
    };

    return categorySeperationHelper(category);
};
export default ReactTab;
