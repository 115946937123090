import { createAction } from 'redux-actions';

const GET_STORE_LIST = 'UJ/GET_STORE_LIST';
const GET_STORE_LIST_SUCCESS = 'UJ/GET_STORE_LIST_SUCCESS';
const GET_STORE_LIST_FAILED = 'UJ/GET_STORE_LIST_FAILED';

const getStoreList = createAction(GET_STORE_LIST);
const getStoreListSuccess = createAction(GET_STORE_LIST_SUCCESS);
const getStoreListFailed = createAction(GET_STORE_LIST_FAILED);

export const actions = {
    getStoreList,
    getStoreListSuccess,
    getStoreListFailed,
};

export const types = {
    GET_STORE_LIST,
    GET_STORE_LIST_SUCCESS,
    GET_STORE_LIST_FAILED,
};
