import { Skeleton } from '@material-ui/lab';
import Image from 'material-ui-image';
import React from 'react';
import LazyLoad from 'react-lazyload';
import notfound from '../../../assets/noimage.png';

export default function LazyImages({ src, className, className1, style, index }) {
    const onErrorImage = (ev) => {
        ev.target.src = notfound;
    };

    if (index && index !== undefined && index < 6) {
        return (
            <div style={style} className={`${className1} image_gray_color_div`}>
                <Image
                    src={src}
                    className={`${className} image_gray_color`}
                    alt="plpItem"
                    style={{ backgroundColor: '#f7f7f7' }}
                    onError={onErrorImage}
                    loading={<Skeleton variant="rect" style={{ width: '100%', minHeight: '100%' }} />}
                />
            </div>
        );
    } else {
        return (
            <LazyLoad style={style} className={`${className1} image_gray_color_div`}>
                <Image
                    src={src}
                    className={`${className} image_gray_color`}
                    alt="plpItem"
                    style={{ backgroundColor: '#f7f7f7' }}
                    onError={onErrorImage}
                    loading={<Skeleton variant="rect" style={{ width: '100%', minHeight: '100%' }} />}
                />
            </LazyLoad>
        );
    }
}
