import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { routesgtmevents } from 'service/GTMEvent';
import api from '../../../../../../api';
import { ApiErrorToast, ApiFalseMessageToast } from '../../../../../../utils/CommonApiMessage';
import { actions, types } from './actions';

const storeCode = (state) => state && state.storeInfo && state.storeInfo.store_code;

const checkDeliveryOptionsRequest = function* checkDeliveryOptionsRequest({ payload }) {
    routesgtmevents(window.location.href, 'Delivery details', 'checkout');
    try {
        const { data } = yield call(api.checkDeliveryOptions, payload);

        if (data && data.status) {
            yield put(actions.checkDeliveryOptionsSuccess(data && data.delivery_options));
        } else {
            yield put(actions.checkDeliveryOptionsFailed());
            ApiFalseMessageToast();
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.checkDeliveryOptionsFailed());
    }
};

const getDeliveryRequest = function* getDeliveryRequest({ payload }) {
    try {
        const { data } = yield call(api.getDelivery, payload);
        if (data && data.status) {
            yield put(actions.getDeliverySuccess(data));
        } else {
            yield put(actions.getDeliveryFailed());
            ApiFalseMessageToast();
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.getDeliveryFailed());
    }
};

const getCountryList = function* getCountryList() {
    try {
        const { data } = yield call(api.getCountryList);
        if (data) {
            yield put(actions.getCountryListSuccess(data));
        } else {
            yield put(actions.getCountryListFailed());
            ApiFalseMessageToast();
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.getCountryListFailed());
    }
};

const getHomeDeliveryOption = function* getHomeDeliveryOption({ payload }) {
    try {
        const { data } = yield call(api.getHomeDeliveryOption, payload);
        if (data && data.status) {
            yield put(actions.getHomeDeliveryOptionsSuccess(data && data.data && data.data.delivery_options));
        } else {
            yield put(actions.getHomeDeliveryOptionsFailed());
            ApiFalseMessageToast();
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.getHomeDeliveryOptionsFailed());
    }
};

const setDelivery = function* setDelivery({ payload }) {
    try {
        const store_code = yield select(storeCode);
        const { data } = yield call(api.setDelivery, payload.reqData);
        if (data && data.status) {
            payload.history.push(`/${store_code}/checkout-payment`);
            yield put(actions.setDeliverySuccess(data));
        } else {
            yield put(actions.setDeliveryFailed());
            ApiFalseMessageToast();
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.setDeliveryFailed());
    }
};

const saveOrEditDeliveryAddressReq = function* saveOrEditDeliveryAddressReq({ payload }) {
    try {
        // const { UserId, store_id } = payload;
        const store_code = yield select(storeCode);
        const { data } = yield call(api.saveAddress, payload.reqData);
        yield put(actions.saveDeliveryAddressRequestSuccess());
        yield put(
            actions.getDeliveryRequest({
                customer_id: payload.reqData.customerid,
                store_id: payload.reqData.store_id,
            }),
        );
        payload.history.push(`/${store_code}/delivery-details`);
        if (data && data.status) {
            data && data.message && toast.success(data && data.message);
        } else {
            ApiFalseMessageToast();
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.saveDeliveryAddressRequestFailed());
    }
};

export default function* sagas() {
    yield takeLatest(types.CHECK_DELIVERY_OPTIONS, checkDeliveryOptionsRequest);
    yield takeLatest(types.GET_DELIVERY, getDeliveryRequest);
    yield takeLatest(types.GET_COUNTRY_LIST, getCountryList);
    yield takeLatest(types.GET_HOME_DELIVERY_OPTIONS, getHomeDeliveryOption);
    yield takeLatest(types.SET_DELIVERY, setDelivery);
    yield takeLatest(types.SAVE_DELIVERY_ADDRESS_REQUEST, saveOrEditDeliveryAddressReq);
}
