import { createAction } from 'redux-actions';

const GET_STORE_INFO_REQUEST = 'UJ/GET_STORE_INFO_REQUEST';
const GET_STORE_INFO_REQUEST_SUCCESS = 'UJ/SET_STORE_INFO_REQUEST_SUCCESS';
const GET_STORE_INFO_REQUEST_FAILED = 'UJ/GET_STORE_INFO_REQUEST_FAILED';

const GET_GUEST_CART_REQUEST = 'UJ/GET_GUEST_CART_REQUEST';
const GET_GUEST_CART_REQUEST_SUCCESS = 'UJ/GET_GUEST_CART_REQUEST_SUCCESS';
const GET_GUEST_CART_REQUEST_FAILED = 'UJ/GET_GUEST_CART_REQUEST_FAILED';

const SET_LOGIN_USER_DETAILS = 'UJ/SET_LOGIN_USER_DETAILS';
const SET_QUOTE_ID = 'UJ/SET_QUOTE_ID';

const STORE_CHANGE_REQUEST = 'UJ/STORE_CHANGE_REQUEST';
const STORE_CHANGE_REQUEST_SUCCESS = 'UJ/STORE_CHANGE_REQUEST_SUCCESS';
const STORE_CHANGE_REQUEST_FAILED = 'UJ/STORE_CHANGE_REQUEST_FAILED';
const UPDATE_GDPR = 'UPDATE_GDPR';
const SET_GDPR_DATA = 'SET_GDPR_DATA';
const SET_HOME_LANGUAGE_POP_UP = 'SET_HOME_LANGUAGE_POP_UP';
const GET_LAST_PATH_USER = 'GET_LAST_PATH_USER';
const SET_ECOM_LOGIN = 'SET_ECOM_LOGIN';
const storeChangeRequest = createAction(STORE_CHANGE_REQUEST);
const storeChangeRequestSuccess = createAction(STORE_CHANGE_REQUEST_SUCCESS);
const storeChangeRequestFailed = createAction(STORE_CHANGE_REQUEST_FAILED);

const getStoreInfoRequest = createAction(GET_STORE_INFO_REQUEST);
const getStoreInfoRequestSuccess = createAction(GET_STORE_INFO_REQUEST_SUCCESS);
const getStoreInfoRequestFailed = createAction(GET_STORE_INFO_REQUEST_FAILED);

const getGuestCartRequest = createAction(GET_GUEST_CART_REQUEST);
const getGuestCartRequestSuccess = createAction(GET_GUEST_CART_REQUEST_SUCCESS);
const getGuestCartRequestFailed = createAction(GET_GUEST_CART_REQUEST_FAILED);

const setLoginUserDetails = createAction(SET_LOGIN_USER_DETAILS);
const setQuoteId = createAction(SET_QUOTE_ID);

const updateGDPR = createAction(UPDATE_GDPR);
const setGDPRData = createAction(SET_GDPR_DATA);
const setHomeLanguagePopUp = createAction(SET_HOME_LANGUAGE_POP_UP);
const lastPathuser = createAction(GET_LAST_PATH_USER);
const ecomLogin = createAction(SET_ECOM_LOGIN);
export const actions = {
    getStoreInfoRequest,
    getStoreInfoRequestSuccess,
    getStoreInfoRequestFailed,

    getGuestCartRequest,
    getGuestCartRequestSuccess,
    getGuestCartRequestFailed,

    setLoginUserDetails,
    setQuoteId,

    storeChangeRequest,
    storeChangeRequestSuccess,
    storeChangeRequestFailed,
    updateGDPR,
    setGDPRData,
    setHomeLanguagePopUp,
    lastPathuser,
    ecomLogin,
};

export const types = {
    GET_STORE_INFO_REQUEST,
    GET_STORE_INFO_REQUEST_SUCCESS,
    GET_STORE_INFO_REQUEST_FAILED,

    GET_GUEST_CART_REQUEST,
    GET_GUEST_CART_REQUEST_SUCCESS,
    GET_GUEST_CART_REQUEST_FAILED,

    SET_LOGIN_USER_DETAILS,
    SET_QUOTE_ID,

    STORE_CHANGE_REQUEST,
    STORE_CHANGE_REQUEST_FAILED,
    STORE_CHANGE_REQUEST_SUCCESS,
    UPDATE_GDPR,
    SET_GDPR_DATA,
    SET_HOME_LANGUAGE_POP_UP,
    GET_LAST_PATH_USER,
    SET_ECOM_LOGIN,
};
