import { call, put, takeLatest } from 'redux-saga/effects';
import { productPromotion } from 'service/GTMEvent';
import api from '../../../../api';
import { ApiErrorToast, ApiFalseMessageToast } from '../../../../utils/CommonApiMessage';
import { actions, types } from './actions';

const getHomePageReq = function* getHomePageReq({ payload }) {
    try {
        const { data } = yield call(api.getHomepage, payload);
        if (data && data.status) {
            yield put(actions.getHomepageRequestSuccess(data && data.data));

            productPromotion(data && data.data);
        } else {
            ApiFalseMessageToast();
            yield put(actions.getHomepageRequestFailed());
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.getHomepageRequestFailed());
    }
};

const getNewArriwal = function* getNewArriwalReq({ payload }) {
    try {
        const { data } = yield call(api.getNewArriwal, payload);
        if (data && data.status) {
            yield put(actions.getNewArriwalRequestSuccess(data && data.data));
        } else {
            yield put(actions.getNewArriwalRequestFailed());
            ApiFalseMessageToast();
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.getNewArriwalRequestFailed());
    }
};

export default function* sagas() {
    yield takeLatest(types.GET_HOMEPAGE_REQUEST, getHomePageReq);
    yield takeLatest(types.GET_NEW_ARRIWAL_REQUEST, getNewArriwal);
}
