import { createAction } from 'redux-actions';

//action types
const GET_ORDER_HISTROY_REQUEST = 'UJ/GET_ORDER_HISTROY_REQUEST';
const GET_ORDER_HISTROY_REQUEST_SUCCESS = 'UJ/GET_ORDER_HISTROY_REQUEST_SUCCESS';
const GET_ORDER_HISTROY_REQUEST_FAILED = 'UJ/GET_ORDER_HISTROY_REQUEST_FAILED';

const GET_ORDER_DETAIL_REQUEST = 'UJ/GET_ORDER_DETAIL_REQUEST';
const GET_ORDER_DETAIL_REQUEST_SUCCESS = 'UJ/GET_ORDER_DETAIL_REQUEST_SUCCESS';
const GET_ORDER_DETAIL_REQUEST_FAILED = 'UJ/GET_ORDER_DETAIL_REQUEST_FAILED';

const GET_ORDER_VIEW_DETAIL_REQUEST = 'UJ/GET_ORDER_VIEW_DETAIL_REQUEST';
const GET_ORDER_VIEW_DETAIL_REQUEST_SUCCESS = 'UJ/GET_ORDER_VIEW_DETAIL_REQUEST_SUCCESS';
const GET_ORDER_VIEW_DETAIL_REQUEST_FAILED = 'UJ/GET_ORDER_VIEW_DETAIL_REQUEST_FAILED';

//action method
const getOrderHistroyRequest = createAction(GET_ORDER_HISTROY_REQUEST);
const getOrderHistroyRequestSuccess = createAction(GET_ORDER_HISTROY_REQUEST_SUCCESS);
const getOrderHistroyRequestFailed = createAction(GET_ORDER_HISTROY_REQUEST_FAILED);

const getOrderDetailRequest = createAction(GET_ORDER_DETAIL_REQUEST);
const getOrderDetailRequestSuccess = createAction(GET_ORDER_DETAIL_REQUEST_SUCCESS);
const getOrderDetailRequestFailed = createAction(GET_ORDER_DETAIL_REQUEST_FAILED);

const getOrderViewDetailRequest = createAction(GET_ORDER_VIEW_DETAIL_REQUEST);
const getOrderViewDetailRequestSuccess = createAction(GET_ORDER_VIEW_DETAIL_REQUEST_SUCCESS);
const getOrderViewDetailRequestFailed = createAction(GET_ORDER_VIEW_DETAIL_REQUEST_FAILED);

//actions
export const actions = {
    getOrderHistroyRequest,
    getOrderHistroyRequestSuccess,
    getOrderHistroyRequestFailed,

    getOrderDetailRequest,
    getOrderDetailRequestSuccess,
    getOrderDetailRequestFailed,

    getOrderViewDetailRequest,
    getOrderViewDetailRequestSuccess,
    getOrderViewDetailRequestFailed,
};

//types
export const types = {
    GET_ORDER_HISTROY_REQUEST,
    GET_ORDER_HISTROY_REQUEST_SUCCESS,
    GET_ORDER_HISTROY_REQUEST_FAILED,

    GET_ORDER_DETAIL_REQUEST,
    GET_ORDER_DETAIL_REQUEST_SUCCESS,
    GET_ORDER_DETAIL_REQUEST_FAILED,

    GET_ORDER_VIEW_DETAIL_REQUEST,
    GET_ORDER_VIEW_DETAIL_REQUEST_SUCCESS,
    GET_ORDER_VIEW_DETAIL_REQUEST_FAILED,
};
