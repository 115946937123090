import { createAction } from 'redux-actions';

//action types
const GET_ALL_WISHLIST_REQUEST = 'UJ/GET_ALL_WISHLIST_REQUEST';
const GET_ALL_WISHLIST_REQUEST_SUCCESS = 'UJ/GET_ALL_WISHLIST_REQUEST_SUCCESS';
const GET_ALL_WISHLIST_REQUEST_FAILED = 'UJ/GET_ALL_WISHLIST_REQUEST_FAILED';

const REMOVE_WISHLIST_ITEM_REQUEST = 'UJ/REMOVE_WISHLIST_ITEM_REQUEST';
const REMOVE_WISHLIST_ITEM_REQUEST_SUCCESS = 'UJ/REMOVE_WISHLIST_ITEM_REQUEST_SUCCESS';
const REMOVE_WISHLIST_ITEM_REQUEST_FAILED = 'UJ/REMOVE_WISHLIST_ITEM_REQUEST_FAILED';

const ADD_WISHLIST_REMOVECART_ITEM_REQUEST = 'ELC@OROB/ADD_WISHLIST_REMOVECART_ITEM_REQUEST';
const ADD_WISHLIST_REMOVECART_ITEM_REQUEST_SUCCESS = 'ELC@OROB/ADD_WISHLIST_REMOVECART_ITEM_REQUEST_SUCCESS';
const ADD_WISHLIST_REMOVECART_ITEM_REQUEST_FAILED = 'ELC@OROB/ADD_WISHLIST_REMOVECART_ITEM_REQUEST_FAILED';

const ADD_WISHLIST_ITEM_REQUEST = 'ELC@OROB/ADD_WISHLIST_ITEM_REQUEST';
const ADD_WISHLIST_ITEM_REQUEST_SUCCESS = 'ELC@OROB/ADD_WISHLIST_ITEM_REQUEST_SUCCESS';
const ADD_WISHLIST_ITEM_REQUEST_FAILED = 'ELC@OROB/ADD_WISHLIST_ITEM_REQUEST_FAILED';

//methods
const getAllWishListRequest = createAction(GET_ALL_WISHLIST_REQUEST);
const getAllWishListRequestSuccess = createAction(GET_ALL_WISHLIST_REQUEST_SUCCESS);
const getAllWishListRequestFailed = createAction(GET_ALL_WISHLIST_REQUEST_FAILED);

const removeWishlistRequest = createAction(REMOVE_WISHLIST_ITEM_REQUEST);
const removeWishlistRequestSuccess = createAction(REMOVE_WISHLIST_ITEM_REQUEST_SUCCESS);
const removeWishlistRequestFailed = createAction(REMOVE_WISHLIST_ITEM_REQUEST_FAILED);

const addWishListItemRequest = createAction(ADD_WISHLIST_ITEM_REQUEST);
const addWishListItemRequestSuccess = createAction(ADD_WISHLIST_ITEM_REQUEST_SUCCESS);
const addWishListItemRequestFailed = createAction(ADD_WISHLIST_ITEM_REQUEST_FAILED);

const addWishListItemRemoveCartRequest = createAction(ADD_WISHLIST_REMOVECART_ITEM_REQUEST);
const addWishListItemRemoveCartRequestSuccess = createAction(ADD_WISHLIST_REMOVECART_ITEM_REQUEST_SUCCESS);
const addWishListItemRemoveCartRequestFailed = createAction(ADD_WISHLIST_REMOVECART_ITEM_REQUEST_FAILED);
//actions
export const actions = {
    getAllWishListRequest,
    getAllWishListRequestSuccess,
    getAllWishListRequestFailed,

    removeWishlistRequest,
    removeWishlistRequestSuccess,
    removeWishlistRequestFailed,

    addWishListItemRequest,
    addWishListItemRequestSuccess,
    addWishListItemRequestFailed,

    addWishListItemRemoveCartRequest,
    addWishListItemRemoveCartRequestSuccess,
    addWishListItemRemoveCartRequestFailed,
};

//types
export const types = {
    GET_ALL_WISHLIST_REQUEST,
    GET_ALL_WISHLIST_REQUEST_SUCCESS,
    GET_ALL_WISHLIST_REQUEST_FAILED,

    REMOVE_WISHLIST_ITEM_REQUEST,
    REMOVE_WISHLIST_ITEM_REQUEST_SUCCESS,
    REMOVE_WISHLIST_ITEM_REQUEST_FAILED,

    ADD_WISHLIST_ITEM_REQUEST,
    ADD_WISHLIST_ITEM_REQUEST_SUCCESS,
    ADD_WISHLIST_ITEM_REQUEST_FAILED,

    ADD_WISHLIST_REMOVECART_ITEM_REQUEST,
    ADD_WISHLIST_REMOVECART_ITEM_REQUEST_SUCCESS,
    ADD_WISHLIST_REMOVECART_ITEM_REQUEST_FAILED,
};
