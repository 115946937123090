import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandler = {
    [types.GET_ORDER_HISTROY_REQUEST]: (state) => ({
        ...state,
        loader: true,
        orders: { allOrders: [] },
    }),
    [types.GET_ORDER_HISTROY_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
        orders: { allOrders: [] },
    }),
    [types.GET_ORDER_HISTROY_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        orders: payload || { allOrders: [] },
    }),

    [types.GET_ORDER_DETAIL_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.GET_ORDER_DETAIL_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
    }),
    [types.GET_ORDER_DETAIL_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        orderDetails: payload || {},
    }),
    [types.GET_ORDER_VIEW_DETAIL_REQUEST]: (state) => ({
        ...state,
        loader: true,
        viewOrderDetails: {},
    }),
    [types.GET_ORDER_VIEW_DETAIL_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
        viewOrderDetails: {},
    }),
    [types.GET_ORDER_VIEW_DETAIL_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        viewOrderDetails: payload || {},
    }),
};

export default handleActions(actionHandler, {
    loader: false,
    orderDetails: {},
    orders: { allOrders: [] },
    viewOrderDetails: {},
});
