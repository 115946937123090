import { createAction } from 'redux-actions';

const STORE_LOCATOR_REQUEST = 'UJ/STORE_LOCATOR_REQUEST';
const STORE_LOCATOR_REQUEST_SUCCESS = 'UJ/STORE_LOCATOR_REQUEST_SUCCESS';
const STORE_LOCATOR_REQUEST_FAILED = 'UJ/STORE_LOCATOR_REQUEST_FAILED';

const storeLocatorRequest = createAction(STORE_LOCATOR_REQUEST);
const storeLocatorRequestSuccess = createAction(STORE_LOCATOR_REQUEST_SUCCESS);
const storeLocatorRequestFailed = createAction(STORE_LOCATOR_REQUEST_FAILED);

export const actions = {
    storeLocatorRequest,
    storeLocatorRequestSuccess,
    storeLocatorRequestFailed,
};

export const types = {
    STORE_LOCATOR_REQUEST,
    STORE_LOCATOR_REQUEST_SUCCESS,
    STORE_LOCATOR_REQUEST_FAILED,
};
