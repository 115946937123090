import { actions as authActions } from 'components/Global/redux/actions';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import APIList from '../../../api';
import { ApiErrorToast, ApiFalseMessageToast } from '../../../utils/CommonApiMessage';
import { actions as storeAction, actions as storeChangeActions } from '../../storeInfo/redux/actions';
import { CommonDialog } from '../utils';
import { actions, types } from './actions';

const storeCode = (state) => state && state.storeInfo && state.storeInfo.store_code;
const storeId = (state) => state && state.storeInfo && state.storeInfo.store_id;
const store_language = (state) => state && state.storeInfo && state.storeInfo.language;
const lastURLPath = (state) => state && state.storeInfo && state.storeInfo.lastPathUser;
export function store_id_Check(store_id, account_store_id) {
    let condition = true;
    if (store_id === account_store_id) {
        condition = false;
    }
    return condition;
}
const store_changeActions = function (data, store_Id) {
    return storeChangeActions.storeChangeRequest({
        store_id: store_Id || 2,
        quote_id: (data.customer_details && data.customer_details.quote_id) || '',
    });
};

const ecomLoginReq = function* ecomLoginReq({ payload }) {
    try {
        const store_code = yield select(storeCode);
        const store_Id = yield select(storeId);
        const lastURL = yield select(lastURLPath);

        const { data } = yield call(APIList.ecomLogin, payload.reqData);

        if (data.status) {
            toast.success(data.message || `Login successfully!`);

            let gdpr = data.customer_details && data.customer_details.gdpr_details;
            yield put(
                storeAction.setGDPRData({
                    ...gdpr,
                    subscribed_to_newsletter: data.customer_details && data.customer_details.subscribed_to_newsletter,
                }),
            );
            yield put(storeAction.setLoginUserDetails(data.customer_details));
            yield put(storeAction.ecomLogin(true));
            yield put(storeAction.setQuoteId(data.customer_details && data.customer_details.quote_id));
            if (payload.isCheckout) {
                yield put(
                    authActions.addUpdateReservationRequest({
                        reqData: {
                            store_id: payload.reqData && payload.reqData.store_id,
                            quote_id: data.customer_details && data.customer_details.quote_id,
                            reservation_type: 'checkout',
                        },
                        history: payload.history,
                        isNotRedirect: true,
                    }),
                );
                payload.history.push(`/${store_code}/delivery-details`);
            } else {
                const checkPayload = {
                    customerid: data.customer_details.customer_id,
                    email: data.customer_details.email,
                    store_id: payload.store_id,
                    quote_id: data.customer_details.quote_id,
                };
                const checkPdr = yield call(APIList.checkgdpr, checkPayload);
                if (checkPdr) {
                    if (checkPdr.data.status === true) {
                        payload.history.push(`/${store_code}/my-privacy`);
                        CommonDialog(
                            <FormattedMessage id="GDPR.head" defaultMessage="PLease Confirm GDPR" />,
                            checkPdr.data.message,
                            <FormattedMessage id="gdpr.confirm" defaultMessage="Confirm" />,
                            yield select(store_language),
                        );
                    } else {
                        payload.history.push(`${lastURL}`);
                    }
                } else {
                    data.message && data.message !== '' && data.message.length !== 0 && toast.error(data.message);
                }
            }
            if (store_id_Check(store_Id, data.customer_details.store_id)) {
                yield put(store_changeActions(data, store_Id));
            }
        } else {
            data.message && data.message !== '' && data.message.length !== 0 && toast.error(data.message);
        }
        yield put(actions.ecomLoginReqSuccess());
    } catch (err) {
        yield put(actions.ecomLoginReqFailed());
        ApiErrorToast(err);
    }
};

const socialGoogleLoginReq = function* socialGoogleLoginReq({ payload }) {
    try {
        const store_code = yield select(storeCode);
        const store_Id = yield select(storeId);
        const lastURL = yield select(lastURLPath);
        const { data } = yield call(APIList.socialGoogleLogin, payload.reqData);
        if (data.status) {
            toast.success(data.message || `Login successfully!`);
            yield put(storeAction.ecomLogin(false));

            yield put(actions.socialGoogleLoginReqSuccess());

            let gdpr = data.customer_details && data.customer_details.gdpr_details;
            yield put(
                storeAction.setGDPRData({
                    ...gdpr,
                    subscribed_to_newsletter: data.customer_details && data.customer_details.subscribed_to_newsletter,
                }),
            );
            yield put(storeAction.setLoginUserDetails(data.customer_details));
            yield put(storeAction.setQuoteId(data.customer_details && data.customer_details.quote_id));
            if (payload.isCheckout) {
                yield put(
                    authActions.addUpdateReservationRequest({
                        reqData: {
                            store_id: payload.reqData && payload.reqData.store_id,
                            quote_id: data.customer_details && data.customer_details.quote_id,
                            reservation_type: 'checkout',
                        },
                        history: payload.history,
                        isNotRedirect: true,
                    }),
                );
                payload.history.push(`/${store_code}/delivery-details`);
            } else {
                const checkPayload = {
                    customerid: data.customer_details.customer_id,
                    email: data.customer_details.email,
                    store_id: payload.store_id,
                    quote_id: data.customer_details.quote_id,
                };
                const checkPdr = yield call(APIList.checkgdpr, checkPayload);
                if (checkPdr) {
                    if (checkPdr.data.status === true) {
                        payload.history.push(`/${store_code}/my-privacy`);
                        CommonDialog(
                            <FormattedMessage id="GDPR.head" defaultMessage="PLease Confirm GDPR" />,
                            checkPdr.data.message,
                            <FormattedMessage id="gdpr.confirm" defaultMessage="Confirm" />,
                            yield select(store_language),
                        );
                    } else {
                        payload.history.push(`${lastURL}`);
                    }
                } else {
                    data.message && data.message !== '' && data.message.length !== 0 && toast.error(data.message);
                }
            }
            if (store_id_Check(store_Id, data.customer_details.store_id)) {
                yield put(store_changeActions(data, store_Id));
            }
            yield put(actions.socialGoogleLoginReqSuccess());
        } else {
            yield put(actions.socialGoogleLoginReqSuccess());
            let register_check = data.customer_details && data.customer_details.is_register;
            if (register_check === 'false' && !payload.isCheckout) {
                payload.history.push(`/${store_code}/sign-up`);
                yield put(actions.socialRegisterCheckout(true));
            } else if (payload.isCheckout) {
                yield put(actions.socialRegisterCheckout(true));
            } else {
                ApiFalseMessageToast();
            }
        }
    } catch (err) {
        yield put(actions.socialGoogleLoginReqSuccess());
    }
};

const socialFacebookLoginReq = function* socialFacebookLoginReq({ payload }) {
    try {
        const store_code = yield select(storeCode);
        const store_Id = yield select(storeId);
        const lastURL = yield select(lastURLPath);
        const { data } = yield call(APIList.socialFacebookLogin, payload.reqData);

        if (data.status) {
            yield put(actions.socialFacebookLoginReqSuccess());

            toast.success(data.message || `Login successfully!`);
            yield put(storeAction.ecomLogin(false));
            if (store_id_Check(store_Id, data.customer_details.store_id)) {
                yield put(store_changeActions(data, store_Id));
            }

            let gdpr = data.customer_details && data.customer_details.gdpr_details;
            yield put(
                storeAction.setGDPRData({
                    ...gdpr,
                    subscribed_to_newsletter: data.customer_details && data.customer_details.subscribed_to_newsletter,
                }),
            );
            yield put(storeAction.setLoginUserDetails(data.customer_details));
            yield put(storeAction.setQuoteId(data.customer_details && data.customer_details.quote_id));
            if (payload.isCheckout) {
                yield put(
                    authActions.addUpdateReservationRequest({
                        reqData: {
                            store_id: payload.reqData && payload.reqData.store_id,
                            quote_id: data.customer_details && data.customer_details.quote_id,
                            reservation_type: 'checkout',
                        },
                        history: payload.history,
                        isNotRedirect: true,
                    }),
                );
                payload.history.push(`/${store_code}/delivery-details`);
            } else {
                const checkPayload = {
                    customerid: data.customer_details.customer_id,
                    email: data.customer_details.email,
                    store_id: payload.store_id,
                    quote_id: data.customer_details.quote_id,
                    payload,
                };
                const checkPdr = yield call(APIList.checkgdpr, checkPayload);
                if (checkPdr) {
                    if (checkPdr.data.status === true) {
                        payload.history.push(`/${store_code}/my-privacy`);
                        CommonDialog(
                            <FormattedMessage id="GDPR.head" defaultMessage="PLease Confirm GDPR" />,
                            checkPdr.data.message,
                            <FormattedMessage id="gdpr.confirm" defaultMessage="Confirm" />,

                            yield select(store_language),
                        );
                    } else {
                        payload.history.push(`${lastURL}`);
                    }
                } else {
                    data.message &&
                        data.message &&
                        data.message !== '' &&
                        data.message.length !== 0 &&
                        toast.error(data.message);
                }
            }
            yield put(actions.socialFacebookLoginReqSuccess());
        } else {
            yield put(actions.socialFacebookLoginReqSuccess());
            let register_check = data.customer_details && data.customer_details.is_register;
            if (register_check === 'false' && !payload.isCheckout) {
                payload.history.push(`/${store_code}/sign-up`);
                yield put(actions.socialRegisterCheckout(true));
            } else if (payload.isCheckout) {
                yield put(actions.socialRegisterCheckout(true));
            } else {
                ApiFalseMessageToast();
            }
        }
    } catch (err) {
        yield put(actions.socialFacebookLoginReqFailed());
        ApiErrorToast(err);
    }
};

const forgotPasswordReq = function* forgotPasswordReq({ payload }) {
    try {
        const { data } = yield call(APIList.forgotPassword, payload);
        if (data.status) {
            yield put(actions.forgotPasswordReqSuccess());
            data.message && toast.success(`${data.message}`);
        } else {
            ApiFalseMessageToast();
        }
        yield put(actions.forgotPasswordReqSuccess());
    } catch (err) {
        yield put(actions.forgotPasswordReqFailed());
        ApiErrorToast(err);
    }
};

const resetPasswordReq = function* resetPasswordReq({ payload }) {
    try {
        const store_code = yield select(storeCode);
        const { data } = yield call(APIList.resetPassword, payload.reqData);
        if (data.status) {
            yield put(actions.resetPasswordReqSuccess());
            payload.history.push(`/${store_code}/password-reset-success`);
        } else {
            ApiFalseMessageToast(data.message, 1);
        }
        yield put(actions.resetPasswordReqSuccess());
    } catch (err) {
        yield put(actions.resetPasswordReqFailed());
        ApiErrorToast(err);
    }
};

export default function* sagas() {
    yield takeLatest(types.ECOM_LOGIN_REQ, ecomLoginReq);
    yield takeLatest(types.SOCIAL_GOOGLE_LOGIN_REQ, socialGoogleLoginReq);
    yield takeLatest(types.SOCIAL_FACEBOOK_LOGIN_REQ, socialFacebookLoginReq);
    yield takeLatest(types.FORGOT_PASSWORD_REQ, forgotPasswordReq);
    yield takeLatest(types.RESET_PASSWORD_REQ, resetPasswordReq);
}
