import { createAction } from 'redux-actions';

const SAVE_GUEST_USER = 'UJ/SAVE_GUEST_USER';
const RESET_GUEST_USER = 'UJ/RESET_GUEST_USER';

const saveGuestUser = createAction(SAVE_GUEST_USER);
const resetGuestUser = createAction(RESET_GUEST_USER);

export const actions = {
    saveGuestUser,
    resetGuestUser
};

export const types = {
    SAVE_GUEST_USER,
    RESET_GUEST_USER
};
