import { Button, Container, Grid, IconButton, makeStyles, Typography, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Close from '@material-ui/icons/Close';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),

        fontWeight: 1000,
    },
    typographyHeading: {
        fontFamily: 'HelveticaNeueBold',
    },

    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        backgroundColor: '#000000',
        color: '#FFFFFF',
        textTransform: 'capitalize',

        '&:hover': {
            backgroundColor: '#000000',
            color: '#FFFFFF',
        },
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography className={classes.typographyHeading} variant="h6" align="center">
                {children}
            </Typography>
            {onClose ? (
                <IconButton size="small" aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <Close fontSize="small" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
    typography: {
        fontFamily: 'HelveticaNeueRegular',
        cursor: 'pointer',
    },
    button: {
        backgroundColor: '#000000',
        color: '#FFFFFF',
        height: '3rem',
        width: '8rem',
        fontSize: '1rem',
        textTransform: 'capitalize',
        fontFamily: 'HelveticaNeueMedium',
        '&:hover': {
            backgroundColor: '#000000',
            color: '#FFFFFF',
        },
        borderRadius: 0,
    },

    dialogAction: {
        justifyContent: 'center',
    },
}));

function L1PopupMenu({ open, handleClose, classes, category }) {
    const classcss = useStyles();
    const store_code = useSelector((state) => state && state.storeInfo && state.storeInfo.store_code);

    const categorys = category && Object.values(category[1]).map((item, index) => [item][0].children[0]);
    const categoryImage = category && category[0];
    return (
        <Dialog fullWidth open={open} onClose={() => handleClose()} className="l1_mobile_pop">
            <DialogTitle id="customized-dialog-title" onClose={() => handleClose()}>
                {category && category[0].name}
            </DialogTitle>
            <DialogContent>
                {categorys &&
                    categorys.map((items, indexs) => (
                        <>
                            <Typography
                                className={classcss.typography}
                                key={indexs}
                                align="center"
                                onClick={() => handleClose()}
                            >
                                {' '}
                                <Link
                                    to={`/${store_code}/${items.url_key}`}
                                    className={classes}
                                    style={{ color: '#000' }}
                                >
                                    {items.name}{' '}
                                </Link>
                            </Typography>

                            <br />
                        </>
                    ))}

                <DialogActions className={classcss.dialogAction}>
                    <Link
                        to={`/${store_code}/${category && category[0].url_key}`}
                        className={classes}
                        style={{ color: '#000' }}
                    >
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => handleClose()}
                            className={classcss.button}
                        >
                            <FormattedMessage id="L1.ShopAll" default="Shop All" />
                        </Button>
                    </Link>
                </DialogActions>
                <br />

                <Container>
                    <div className="images-item">
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <div className="product-image">
                                    <Link
                                        to={`/${store_code}/${
                                            category && categoryImage && categoryImage.cat_desk_banner_one_url
                                        }`}
                                        onClick={() => handleClose()}
                                    >
                                        <img
                                            src={
                                                (categoryImage && categoryImage.cat_desk_banner_one) ||
                                                'https://source.unsplash.com/400x300/?nike'
                                            }
                                            alt="category banner one"
                                        />

                                        <Typography className="product-title" align="center">
                                            {' '}
                                            {categoryImage && categoryImage.cat_desk_banner_one_title}
                                        </Typography>
                                    </Link>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="product-image">
                                    <Link
                                        to={`/${store_code}/${
                                            category && categoryImage && categoryImage.cat_desk_banner_two_url
                                        }`}
                                        onClick={() => handleClose()}
                                    >
                                        <img
                                            src={
                                                (categoryImage && categoryImage.cat_desk_banner_two) ||
                                                'https://source.unsplash.com/400x300/?addidas'
                                            }
                                            alt="category banner two"
                                        />

                                        <Typography className="product-title" align="center">
                                            {' '}
                                            {categoryImage && categoryImage.cat_desk_banner_two_title}{' '}
                                        </Typography>
                                    </Link>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="product-image">
                                    <Link
                                        to={`/${store_code}/${
                                            category && categoryImage && categoryImage.cat_desk_banner_three_url
                                        }`}
                                        onClick={() => handleClose()}
                                    >
                                        <img
                                            src={
                                                (categoryImage && categoryImage.cat_desk_banner_three) ||
                                                'https://source.unsplash.com/400x300/?shoes'
                                            }
                                            alt="category banner three"
                                        />
                                        <Typography className="product-title" align="center">
                                            {' '}
                                            {categoryImage && categoryImage.cat_desk_banner_three_title}
                                        </Typography>
                                    </Link>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="product-image">
                                    <Link
                                        to={`/${store_code}/${
                                            category && categoryImage && categoryImage.cat_desk_banner_four_url
                                        }`}
                                        onClick={() => handleClose()}
                                    >
                                        <img
                                            src={
                                                (categoryImage && categoryImage.cat_desk_banner_four) ||
                                                'https://source.unsplash.com/400x300/?jackets'
                                            }
                                            alt="category banner four"
                                        />
                                        <Typography className="product-title" align="center">
                                            {' '}
                                            {categoryImage && categoryImage.cat_desk_banner_four_title}
                                        </Typography>
                                    </Link>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </DialogContent>
        </Dialog>
    );
}

export default L1PopupMenu;
