import { FormattedMessage } from 'react-intl';
export function VatShow(data) {
    let percentage = true;
    if (data && parseInt(data.store_id) === 8) {
        percentage = false;
    }
    return (
        <>
            {percentage && data && parseInt(data.vat_percentage) !== 0 ? (
                <small>
                    (
                    <FormattedMessage id="vat.inclusive" default="Inclusive of" />
                    &nbsp;
                    {data && data.vat_percentage}% VAT)
                </small>
            ) : (
                <small>
                    <FormattedMessage id="Myorder.OrderSummary.Taxes" default="Inclusive of all. Taxes" />
                </small>
            )}
        </>
    );
}

const NodeRSA = require('node-rsa');
let public_key = NodeRSA(process.env.REACT_APP_CRYPTR_KEY);
const env = process.env.REACT_APP_ENVIRONMENT;

export function encryptPayload(value) {
    if (env !== 'PROD') {
        return { payload: value };
    }
    return { payload: public_key.encrypt(JSON.stringify(value), 'base64') };
}

export const FixDecimal = (inputValue) => {
    // return parseFloat(inputValue).toFixed(parseFloat(inputValue).toFixed(2).split('.')[1] === '00' ? 0 : 2);
    return parseFloat(inputValue).toFixed(2);
};
export const FixDecimalInt = (inputValue) => {
    // return parseInt(inputValue).toFixed(parseInt(inputValue).toFixed(2).split('.')[1] === '00' ? 0 : 2);
    return parseInt(inputValue).toFixed(2);
};
