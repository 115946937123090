import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import ReactDOM from 'react-dom';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import it from 'react-intl/locale-data/it';
import messages_en from '../../translations/en.json';
import messages_it from '../../translations/it.json';

const useStyles = makeStyles((theme) => ({
    typographyHeading: {
        fontFamily: 'HelveticaNeueBold',
        fontSize: '1rem',
    },
    typography: {
        fontFamily: 'HelveticaNeueRegular',
    },
    button: {
        backgroundColor: '#000000',
        color: '#FFFFFF',
        height: '3rem',
        width: '8rem',
        fontSize: '1rem',
        textTransform: 'capitalize',
        fontFamily: 'HelveticaNeueMedium',
        '&:hover': {
            backgroundColor: '#000000',
            color: '#FFFFFF',
        },
        borderRadius: 0,
    },
    buttonClose: {
        backgroundColor: '#000000',
        color: '#FFFFFF',
        textTransform: 'capitalize',

        '&:hover': {
            backgroundColor: '#000000',
            color: '#FFFFFF',
        },
    },
    dialogAction: {
        justifyContent: 'center',
    },
}));

const CommonDialogBox = (props) => {
    const classes = useStyles();
    addLocaleData([...en, ...it]);
    const language = props.language;
    const messages = {
        it: messages_it,
        en: messages_en,
    };
    const handleNo = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById('gdpr'));
    };

    return (
        <div>
            <IntlProvider
                locale={language}
                messages={messages[language]}
                onError={(err) => {
                    if (err && err.code === 'MISSING_TRANSLATION') {
                        console.warn('Missing translation', err && err.message);
                        return;
                    }
                }}
            >
                <Dialog open={true} onClose={handleNo} fullWidth maxWidth="xs">
                    <DialogTitle>
                        <Typography align="right" variant={'h6'} className={classes.typography}>
                            <IconButton className={classes.buttonClose} size="small" onClick={handleNo}>
                                <Close fontSize="small" />
                            </IconButton>
                        </Typography>
                        <Typography align="center" variant={'h6'} className={classes.typographyHeading}>
                            {props.title}
                        </Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText id="alert-dialog-description">
                            <Typography align="center" variant={'h6'} className={classes.typographyHeading}>
                                {props.message}
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={classes.dialogAction}>
                        <Button
                            onClick={handleNo}
                            autoFocus
                            className={classes.button}
                            size="small"
                            variant="contained"
                        >
                            <span style={{ fontFamily: 'HelveticaNeueMedium' }}>{props.buttonTitle}</span>
                        </Button>
                    </DialogActions>
                </Dialog>
            </IntlProvider>
        </div>
    );
};
export default CommonDialogBox;
