import { call, put, takeLatest, delay } from 'redux-saga/effects';
// import { delay } from 'redux-saga'
import { actions, types } from './actions';

const ToastDisplayTime = 4000;

const showAlertReq = function* showAlertReq({ payload }) {
    yield put(actions.showAlert(payload));
    yield delay(ToastDisplayTime)
    yield put(actions.hideAlert());
};

const hideAlertReq = function* hideAlertReq() {
    yield put(actions.hideAlert());
};

export default function* sagas() {
    yield takeLatest(types.SHOW_ALERT_REQ, showAlertReq);
    yield takeLatest(types.HIDE_ALERT_REQ, hideAlertReq);
}
