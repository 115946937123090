import api from 'api';
import { actions as authActions } from 'components/Global/redux/actions';
import { actions as storeInfoAction } from 'components/storeInfo/redux/actions';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest, delay } from 'redux-saga/effects';
import { ApiErrorToast, ApiFalseMessageToast, ApiErrorToastTranslated } from '../../../../../utils/CommonApiMessage';
import { actions, types } from './actions';

const login_user = (state) =>
    state &&
    state.storeInfo &&
    state.storeInfo.loginUser &&
    state.storeInfo.loginUser.customer_id;
const guest_user = (state) =>
    state && state.storeInfo && state.storeInfo.guest_user && state.storeInfo.guest_user.guestId;

const shouldOpenMiniCart = (state) => state && state.cart && state.cart.shouldOpenMiniCart;

const getCartDetailsReq = function* getCartDetailsReq({ payload }) {
    try {
        if (payload && payload.quote_id) {
            const { data } = yield call(api.getCart, payload);
            if (data && data.status) {
                yield put(actions.saveCartCountRequest((data.data && data.data.cart_count) || 0));
                yield put(actions.getCartItemRequestSuccess(data.data));
            } else {
                ApiFalseMessageToast();
                yield put(actions.getCartItemRequestFailed());
            }
        } else {
            yield put(actions.getCartItemRequestSuccess(null));
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.getCartItemRequestFailed());
    }
};

const getCartCountReq = function* getCartCountReq({ payload }) {
    try {
        if (payload.quote_id) {
            const { data } = yield call(api.getCartCount, payload);
            if (data && data.status) {
                yield put(actions.saveCartCountRequest(data.count));
            } else {
                ApiFalseMessageToast();
            }
        }
    } catch (err) {
        ApiErrorToast(err);
    }
};

const getMyCartReq = function* getMyCartReq({ payload }) {
    try {
        const { quote_id, store_id } = payload;
        const customer_id = yield select(login_user);
        const guest_id = yield select(guest_user);
        const openMiniCart = yield select(shouldOpenMiniCart);

        const data = yield call(api.getMyCart, {
            quote_id: quote_id,
            store_id: store_id,
            customer_id: customer_id || guest_id,
        });

        if (data && data.data.status) {
            yield put(actions.getMyCartRequestSuccess(data.data.data));
            if (!data.data.data) {
                yield put(authActions.saveReservationStartTime(null));
            }
        } else {
            // toast.error(data.data.message);
            if (data && data.new_quote_id) {
                yield put(storeInfoAction.setQuoteId(data.new_quote_id));
            }
            yield put(actions.getMyCartRequestSuccess(null));
        }

        if (openMiniCart) {
            yield put(actions.setMiniCartToOpen(true));
            yield put(actions.shouldOpenMiniCart(false));
            yield delay(5000)
            if (openMiniCart) {
                yield put(actions.setMiniCartToOpen(false));
            }
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.getMyCartRequestFailed());
    }
};

const getMyCartWithoutLoaderReq = function* getMyCartWithoutLoaderReq({ payload }) {
    try {
        const { quote_id, store_id } = payload;
        const customer_id = yield select(login_user);
        const guest_id = yield select(guest_user);
        const data = yield call(api.getMyCart, {
            quote_id: quote_id,
            store_id: store_id,
            customer_id: customer_id || guest_id,
        });
        if (data && data.status) {
            yield put(actions.getMyCartWithoutLoaderRequestSuccess(data.data.data));
        } else {
            ApiFalseMessageToast();
            yield put(actions.getMyCartWithoutLoaderRequestFailed(null));
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.getMyCartWithoutLoaderRequestFailed());
    }
};

const removeCartItemReq = function* removeCartItemReq({ payload }) {
    try {
        const { quote_id, sku, store_id } = payload;
        const { data } = yield call(api.removeCartItem, { quote_id, sku, store_id });

        if (data && data.status) {
            const customer_id = yield select(login_user);
            const guest_id = yield select(guest_user);
            yield put(
                actions.getMyCartRequest({
                    quote_id: quote_id,
                    store_id: store_id,
                    customer_id: customer_id || guest_id
                }),
            );
            data.message && toast.success(data.message);
        } else {
            yield put(actions.removeCartItemRequestFailed());
            ApiFalseMessageToast();
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.removeCartItemRequestFailed());
    }
};

const updateCartQtyReq = function* updateCartQtyReq({ payload }) {
    try {
        const { dataPayload, store_id, customer_id } = payload;
        const { data } = yield call(api.updateCartQty, dataPayload);

        if (data && data.status) {

            yield put(
                actions.getMyCartRequest({
                    store_id,
                    quote_id: dataPayload.quote_id,
                    customer_id: customer_id

                }),
            );
            data.message && toast.success(data.message);
            yield put(actions.updateCartDialog(true));
        } else {
            yield put(actions.updateCartItemQtyRequestFailed());
            ApiFalseMessageToast();
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.updateCartItemQtyRequestFailed());
    }
};

const addGiftWrapReq = function* addGiftWrapReq({ payload }) {
    try {
        const data = yield call(api.addGiftWrap, payload);
        if (data && data.status) {
            yield put(
                actions.getMyCartWithoutLoaderRequest({
                    store_id: payload.store_id,
                    quote_id: payload.quote_id,
                }),
            );
            data.message && toast.success(data.message);
            yield put(actions.addGiftWrapRequestSuccess());
        } else {
            yield put(actions.addGiftWrapRequestFailed());
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.addGiftWrapRequestFailed());
    }
};

const getMyCouponCartReq = function* getMyCouponCartReq({ payload }) {
    try {
        const { quote_id, couponCode, store_id } = payload;
        const data = yield call(api.getMyCouponCart, {
            cartId: quote_id,
            couponCode: couponCode,
        });

        const customer_id = yield select(login_user);
        const guest_id = yield select(guest_user);

        const newPayload = {
            store_id: store_id,
            customer_id: customer_id || guest_id,
            quote_id: quote_id,
        }
        if (data && data.status) {
            yield put(actions.getMyCartRequest(newPayload));
        } else {
            ApiFalseMessageToast();
            yield put(actions.getMyCartRequestSuccess(null));
        }
    } catch (err) {
        ApiErrorToastTranslated('api.wrongCoupon.Text');
        ApiErrorToast(err);
        // yield put(actions.getMyCartRequestFailed());
    }
};

const getRemoveCouponReq = function* getRemoveCouponReq({ payload }) {
    try {
        const { quote_id, couponCode, store_id } = payload;
        const data = yield call(api.removeCoupon, {
            quote_id: quote_id,
        });

        const customer_id = yield select(login_user);
        const guest_id = yield select(guest_user);

        const newPayload = {
            store_id: store_id,
            customer_id: customer_id || guest_id,
            quote_id: quote_id,
        }

        if (data && data.status) {
            yield put(actions.getMyCartRequest(newPayload));
        } else {
            ApiFalseMessageToast();
            yield put(actions.getMyCartRequestSuccess(null));
        }
    } catch (err) {
        console.log('err', err)
        ApiErrorToast(err);
        yield put(actions.getMyCartRequestFailed());
    }
};

export default function* sagas() {
    yield takeLatest(types.GET_CART_ITEM_REQUEST, getCartDetailsReq);
    yield takeLatest(types.GET_CART_COUNT_REQUEST, getCartCountReq);
    yield takeLatest(types.GET_MY_CART, getMyCartReq);
    yield takeLatest(types.GET_MY_COUPON_CART, getMyCouponCartReq);
    yield takeLatest(types.GET_REMOVE_COUPON, getRemoveCouponReq);
    yield takeLatest(types.REMOVE_CART_ITEM_REQUEST, removeCartItemReq);
    yield takeLatest(types.UPDATE_CART_ITEM_QTY_REQUEST, updateCartQtyReq);
    yield takeLatest(types.ADD_GIFT_WRAP_REQUEST, addGiftWrapReq);
    yield takeLatest(types.GET_MY_CART_WITHOUT_LOADER, getMyCartWithoutLoaderReq);
}
