import React from 'react';
import LocalStorage from 'utils/LocalStorage';
import { FixDecimal } from '../../utils/Utils';
let _ = require('lodash');

export const showDiscountPrice = (data) => {
    if (data && data.price && data.price.specialprice && parseInt(data.price.specialprice) > 0) {
        return (
            <span>
                <span style={{ color: 'gray', textDecoration: 'line-through' }}>{`${data.currency} ${
                    data && data.price && FixDecimal(data.price.price)
                }`}</span>
                <span>{`  ${data.currency} ${data && data.price && FixDecimal(data.price.specialprice)}`}</span>
            </span>
        );
    } else {
        return <span>{`${data.currency} ${data && data.price && FixDecimal(data.price.price)}`}</span>;
    }
};

export const filterData = (products, filter) => {
    let filters = {};
    if (filter && filter.length !== 0) {
        LocalStorage.setFilter(filter);
        // filters = filter;
    } else {
        // if (LocalStorage.getFilter() !== null) {
        //     filters = LocalStorage.getFilter();
        // }
    }
    // const productdata = (products) => {
    //     return products.filter((product) => {
    //         if (!filterKeys.length) return true;
    //         return filterKeys.every((key) => {
    //             if (!filters[key].length) return true;
    //             return filters[key].some((item) => {
    //                 return (
    //                     product.filtersdata[key] &&
    //                     Object.keys(product.filtersdata[key]).some((productfilterdata, index) => {
    //                         let filterarray = [];
    //                         filterarray = productfilterdata.split(',');
    //                         if (filterarray.length > 0) {
    //                             if (filterarray.indexOf(item.value) !== -1) {
    //                                 return true;
    //                             }
    //                         }
    //                         return false;
    //                     })
    //                 );
    //             });
    //         });
    //     });
    // };

    // const filterKeys = Object.keys(filters);
    // products = _.values(products);
    // console.log(productdata(products))
    return products;
};

export const filterByPrice = (products, range) => {
    // let returnProducts = [];
    // if (range[0] > range[1]) {
    //     returnProducts = products;
    // } else {
    //     products &&
    //         products.map((product, index) => {
    //             if (
    //                 product.price &&
    //                 product.price.specialprice &&
    //                 product.price.specialprice !== 0 &&
    //                 product.price.specialprice >= range[0] &&
    //                 product.price.specialprice <= range[1]
    //             ) {
    //                 returnProducts.push(product);
    //             } else if (
    //                 product.price &&
    //                 (product.price.price || product.price.price === 0) &&
    //                 product.price.price >= range[0] &&
    //                 product.price.price <= range[1]
    //             ) {
    //                 returnProducts.push(product);
    //             }
    //         });
    // }

    return products;
};

export const sortByValue = (products, value, original) => {
    let sortedData = {};
    if (value === 'featured') {
        let sortTime = _.values(products).sort((a, b) => a.created_at - b.created_at);
        sortedData = _.values(sortTime).sort((a, b) => a.order_featured - b.order_featured);
    } else if (value === 'price-desc') {
        sortedData = _.values(products).sort((a, b) => b.price.price - a.price.price);
    } else if (value === 'price-asc') {
        sortedData = _.values(products).sort((a, b) => a.price.price - b.price.price);
    } else if (value === 'newin') {
        sortedData = _.values(products).sort((a, b) => a.newin - b.newin);
    } else if (value === 'bestseller') {
        sortedData = _.values(products).sort((a, b) => a.bestSellers - b.bestSellers);
    }

    return sortedData;
};

export const getUrlKey = () => {
    const pathSearch = window.location.pathname.includes('/search');
    const pathBrand = window.location.pathname.includes('/brand/');
    let url_key = pathSearch ? 'search' : pathBrand ? 'brand' : 'productList';
    return url_key;
};
