import { call, put, takeLatest } from 'redux-saga/effects';
import { ApiErrorToast, ApiFalseMessageToast } from '../../../../utils/CommonApiMessage';
import api from '../../api';
import { actions, types } from './actions';

const getOrdersReq = function* getOrdersReq({ payload }) {
    try {
        const { data } = yield call(api.getOrderHistory, payload);
        if (data && data.status) {
            yield put(actions.getOrderHistroyRequestSuccess(data.orders_details));
        } else {
            ApiFalseMessageToast();
            yield put(actions.getOrderHistroyRequestFailed());
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.getOrderHistroyRequestFailed());
    }
};

const getOrderDetailsReq = function* getOrderDetailsReq({ payload }) {
    try {
        const { data } = yield call(api.getOrderDetails, payload);
        if (data && data.status) {
            yield put(actions.getOrderDetailRequestSuccess(data.orders_details));
        } else {
            yield put(actions.getOrderDetailRequestFailed());
            ApiFalseMessageToast();
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.getOrderDetailRequestFailed());
    }
};

const getOrderViewReq = function* getOrderViewReq({ payload }) {
    try {
        const { data } = yield call(api.orderViewRequest, payload);
        if (data && data.status) {
            yield put(actions.getOrderViewDetailRequestSuccess(data.orders_details));
        } else {
            ApiFalseMessageToast();
            yield put(actions.getOrderViewDetailRequestFailed());
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.getOrderViewDetailRequestFailed());
    }
};

export default function* sagas() {
    yield takeLatest(types.GET_ORDER_HISTROY_REQUEST, getOrdersReq);
    yield takeLatest(types.GET_ORDER_DETAIL_REQUEST, getOrderDetailsReq);
    yield takeLatest(types.GET_ORDER_VIEW_DETAIL_REQUEST, getOrderViewReq);
}
