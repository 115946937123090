import { encryptPayload } from '../utils/Utils';
import api from './apiServices';

//Guest card
const getGuestCard = (payload) => api.post(`/global/guest-carts`, encryptPayload(payload));
//End Guest card

//Home and Menu
const getMenuList = (payload) => api.post(`/global/menu`, encryptPayload(payload));
const getHomepage = (payload) => api.post(`/global/home`, encryptPayload(payload));
const getNewArriwal = (payload) => api.get(`/global/productsbycategory?${payload}`); //category_id=309&store_id=4&count=6
//End Home and Menu

//Login
const ecomLogin = (payload) => api.post(`/login/login`, encryptPayload(payload));
const socialGoogleLogin = (payload) => api.post(`/login/sociallogingoogle`, encryptPayload(payload)); //google_login
const socialFacebookLogin = (payload) => api.post(`/login/socialloginfb`, encryptPayload(payload)); //facebook_login
//End Login

// Forgot Password
const forgotPassword = (payload) => api.post(`/reset/forgotpassword`, encryptPayload(payload));
// End Forgot Password

// Reset Password
const resetPassword = (payload) => api.post(`/reset/resetpassword/`, encryptPayload(payload));
// End Reset Password

//register
const registerUser = (payload) => api.post(`/register`, encryptPayload(payload));
//end regsiter

//static pages
const getContactUs = (store_id) => api.get(`staticPage/contactus?storeId=${store_id}`);
const getCMSPageData = (payload) => api.post(`/staticPage/cmspage`, encryptPayload(payload));
const getFaqData = (store_id) => api.get(`/staticPage/customerservice?store_id=${store_id}`);
const getPrivacy = (store_id) => api.get(`staticPage/cmsPageIdentifier/privacy-policy/storeId/?storeId=${store_id}`);
const getAboutUs = (store_id) => api.get(`staticPage/cmsPageIdentifier/about/storeId/?storeId=${store_id}`);
//end statis pages

//PDP
const getPDPData = (payload) => api.post(`/pdp/getPDPData`, encryptPayload(payload));
const addToCartGuestReq = (payload, guestId, store_code) =>
    api.post(`/pdp/guest-carts/items`, encryptPayload([guestId, store_code, payload]));
const checkPDPOrPLPUrl = (payload) => api.post(`/pdp/getcatalogurl`, encryptPayload(payload));
const addToCartLoginReq = (payload, store_code) =>
    api.post(`/pdp/carts/mine/items`, encryptPayload([store_code, payload]));
const notifyMeReq = (payload) => api.post(`/pdp/pdp/notifyme`, encryptPayload(payload));
//End PDP

//PLP
const getProducts = (payload) => api.post('/plp/productlisting', encryptPayload(payload));
const getPLPSearch = (payload) => api.post(`/plp/searchresult`, encryptPayload(payload));
//End PLP

const getAutoSearch = (payload) => api.post(`/global/searchautosuggest`, encryptPayload(payload));

//Cart Api
const getCart = (payload) => api.post(`/cart/orob/mycart`, encryptPayload(payload));
const getCartCount = (payload) => api.post('/cart/orob/mycartcount', encryptPayload(payload));
const getMyCart = (payload) => api.post(`/cart/mycart/`, encryptPayload(payload));
const removeCartItem = (payload) => api.post(`/cart/deletecart`, encryptPayload(payload));
const updateCartQty = (payload) => api.post(`/cart/updatecart`, encryptPayload(payload));

const getBrands = (payload) => api.post(`/cart/getbrands`, encryptPayload(payload));

const addGiftWrap = (payload) => api.post(`/cart/addgiftwrap`, encryptPayload(payload));

//Delivery
const getDelivery = (payload) => api.post(`/delivery/getdelivery`, encryptPayload(payload));
const checkDeliveryOptions = (payload) => api.post(`/delivery/checkavldeliveryoption`, encryptPayload(payload));
const getCountryList = (payload) => api.get(`/delivery/directory/countries`);
const getStoreList = (payload) => api.post(`/delivery/storelocator_v1`, encryptPayload(payload));
const getHomeDeliveryOption = (payload) => api.post(`/delivery/gethomedeliveryoption`, encryptPayload(payload));
const setDelivery = (payload) => api.post(`/delivery/setdelivery`, encryptPayload(payload));
const saveAddress = (payload) => api.post(`/delivery/addaddress`, encryptPayload(payload));
//End Delivery

//Payment
const getPaymentOptions = (payload) => api.post(`/payment/getpayment`, encryptPayload(payload));
const setPaymentOptionReq = (payload) => api.post(`/payment/setpayment`, encryptPayload(payload));
const placeOrderReq = (payload) => api.post(`/payment/placeorder`, encryptPayload(payload));
const orderSummaryReq = (payload, jwt) =>
    api.post(`/payment/ordersummary`, encryptPayload(payload), { headers: { 'x-access-token': jwt } });
//end Payment

//Reservation Api
const getReservationConfig = () => api.get(`/reservation/getreservationconfig`);
const addAndupdateReservation = (payload) => api.post(`/reservation/webreservation`, encryptPayload(payload));
const removeReservation = (payload) => api.post(`/reservation/removereservation`, encryptPayload(payload));
const extendReservationTime = (payload) => api.post(`/reservation/extendreservation`, encryptPayload(payload));
//End Reservation Api

//Store
const getAllStoreInfo = () => api.get(`/store/storeinfo`);
const storeChange = (payload) => api.post(`/store/storechange`, encryptPayload(payload));
//End Store

//Adyen
const initiatePaymentForKlarna = (payload) => api.post(`/adyen/initiatePayment`, encryptPayload(payload));
const initiatePaymentForCardOrPaypalOrGooglePay = (payload) =>
    api.post(`/adyen/initiatePaymentForCardOrPaypalOrGooglePay`, encryptPayload(payload));
const submitAdditionalDetails = (payload) => api.post('/adyen/submitAdditionalDetails', encryptPayload(payload));
const additionalDetails = (payload) => api.post(`/adyen/additionalDetails`, encryptPayload(payload));
const paypalCancelled = (payload) => api.post(`/adyen/paypalCancelled`, encryptPayload(payload));

const getQuoteId = (payload) => api.post('/adyen/newquote', encryptPayload(payload));
const getPaymentData = () => api.post('/adyen/getPaymentMethods');
//End Adyen

// gdpr
const checkgdpr = (payload) => api.post('/global/checkgdpr', encryptPayload(payload));
const updategdpr = (payload) => api.post('/global/updategdpr', encryptPayload(payload));

// guestsubscribeEmail

const guestsubscribeEmail = (payload) => api.post('/global/gusetsubscribe', encryptPayload(payload));

const getstoreLocator = (payload) => api.get('/global/storelocator', { params: payload });

const socialregister = (payload) => api.post('/register/socialregister', encryptPayload(payload));

const getMyCouponCart = (payload) =>
    api.put(`/cart/cartcoupon/`, encryptPayload(payload));

const removeCoupon = (payload) => api.post(`/cart/remove/cartcoupon`, encryptPayload(payload));


// clerk apis
const searchPredictive = (payload) => api.post(`/clerk/search/predictive`, encryptPayload(payload));
const recommendationsTrending = (payload) => api.post(`/clerk/recommendations/trending`, encryptPayload(payload));
const recommendationsRecentlyBought = (payload) => api.post(`/clerk/recommendations/recently-bought`, encryptPayload(payload));

// retry payment apis
const retryPaymentInfo = (payload) => {
    return api.post(`/retrypayment/getRetryInfo`, encryptPayload(payload))
};

const retryPaymentSave = (payload) => {
    return api.post(`/adyen/saveRetryPayment`, encryptPayload(payload))
};

const pushLog = (payload) => {
    return api.post(`/logger-service/push-log`, encryptPayload(payload))
}


const APIList = {
    getMenuList,
    getHomepage,
    getNewArriwal,
    ecomLogin,
    registerUser,
    socialGoogleLogin,
    socialFacebookLogin,
    forgotPassword,
    resetPassword,
    getContactUs,
    getCMSPageData,
    getFaqData,
    getPrivacy,
    getAboutUs,
    getPDPData,
    getGuestCard,
    addToCartGuestReq,
    checkPDPOrPLPUrl,
    getProducts,
    getPLPSearch,
    getAutoSearch,
    searchPredictive,
    addToCartLoginReq,
    notifyMeReq,
    getCart,
    getCartCount,
    getMyCart,
    removeCartItem,
    updateCartQty,
    getBrands,
    addGiftWrap,
    getDelivery,
    checkDeliveryOptions,
    getCountryList,
    getPaymentOptions,
    getStoreList,
    setPaymentOptionReq,
    placeOrderReq,
    getHomeDeliveryOption,
    setDelivery,
    orderSummaryReq,
    saveAddress,
    getReservationConfig,
    addAndupdateReservation,
    removeReservation,
    extendReservationTime,
    getAllStoreInfo,
    storeChange,
    initiatePaymentForKlarna,
    initiatePaymentForCardOrPaypalOrGooglePay,
    submitAdditionalDetails,
    additionalDetails,
    paypalCancelled,
    getQuoteId,
    checkgdpr,
    updategdpr,
    guestsubscribeEmail,
    getstoreLocator,
    getPaymentData,
    socialregister,

    getMyCouponCart,
    removeCoupon,

    searchPredictive,
    recommendationsTrending,
    recommendationsRecentlyBought,

    retryPaymentInfo,
    retryPaymentSave,

    pushLog
};

export default APIList;
