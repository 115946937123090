import { Grid, Typography } from '@material-ui/core';
import { actions as wishlistActions } from 'components/account/WishList/redux/actions';
import { actions as cartActions } from 'components/main/cart/Basket/redux/actions';
import React from 'react';
import { AiFillCaretRight, AiOutlineInbox, AiOutlinePoweroff } from 'react-icons/ai';
import { BiShoppingBag } from 'react-icons/bi';
import { BsHeart, BsPerson } from 'react-icons/bs';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { GrLocation } from 'react-icons/gr';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { actions as jwtActions } from '../../../../../../main/cart/Checkout/Payment/redux/actions';
import { actions as storeActions } from '../../../../../../storeInfo/redux/actions';

const LevelOneMenu = ({ data, OnSelect, setIsAssistanceOpen, setDrawerState }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const store_code = useSelector((state) => state && state.storeInfo && state.storeInfo.store_code);
    const loginUser = useSelector((state) => state && state.storeInfo && state.storeInfo.loginUser);

    /** According Url redirect to PLP Or PDP */
    const gotoURL = (url, closeMenu = true) => {
        if (!url) return;
        if (closeMenu) setDrawerState(false);
        history.push(`/${store_code}/${url}`);
    };

    const onL1Click = (category, blog) => {
        if (blog.toLowerCase() !== 'blog') {
            if (category[1]) {
                OnSelect(category);
            } else {
                gotoURL(category[0].url_key);
            }
        } else {
            window.open(process.env.REACT_APP_BLOG_URL, '_blank');
        }
    };

    return (
        <Grid>
            <Grid className="category-container">
                {Object.entries(data).map((category, index) => {
                    return (
                        <Grid
                            style={{ cursor: 'pointer' }}
                            key={index}
                            onClick={() => onL1Click(category[1], category[1][0].name)}
                        >
                            <Grid className="category-name">{category[1][0].name}</Grid>
                            {category[1][1] && (
                                <Grid className="category-arrow">
                                    <AiFillCaretRight />
                                </Grid>
                            )}
                        </Grid>
                    );
                })}
                <Grid className=" category-name">
                    <Link
                        to={`/${store_code}/brands`}
                        onClick={() => {
                            setDrawerState(false);
                        }}
                        style={{ color: 'black', textDecoration: 'none' }}
                    >
                        <Typography className="category-name">
                            <FormattedMessage id="NavigationBar.Brands.Text" defaultMessage="Brands" />
                        </Typography>
                    </Link>
                </Grid>
            </Grid>
            <Grid className="category-container">
                <Grid
                    className="category-inner-container"
                    onClick={() => {
                        history.push(`/${store_code}/my-account`);
                        setDrawerState(false);
                    }}
                >
                    <Grid className="category-inner-icon">
                        <BsPerson></BsPerson>
                    </Grid>
                    <Grid className="category-inner-name">
                        <FormattedMessage id="Components.MobileAccount" defaultMessage="My Account" />
                    </Grid>
                </Grid>
                <Grid
                    className="category-inner-container"
                    onClick={() => {
                        history.push(`/${store_code}/order-history`);
                        setDrawerState(false);
                    }}
                >
                    <Grid className="category-inner-icon">
                        <BiShoppingBag />
                    </Grid>
                    <Grid className="category-inner-name">
                        <FormattedMessage id="Myorders.Text" defaultMessage="My Orders" />
                    </Grid>
                </Grid>
                <Grid
                    className="category-inner-container"
                    onClick={() => {
                        history.push(`/${store_code}/shipping-and-return`);
                        setDrawerState(false);
                    }}
                >
                    <Grid className="category-inner-icon">
                        <AiOutlineInbox></AiOutlineInbox>
                    </Grid>
                    <Grid className="category-inner-name">
                        <FormattedMessage id="ShippingReturn" defaultMessage={`Shipping & Return`} />
                    </Grid>
                </Grid>
                <Grid
                    className="category-inner-container"
                    onClick={() => {
                        history.push(`/${store_code}/store-locator`);
                        setDrawerState(false);
                    }}
                >
                    <Grid className="category-inner-icon">
                        <GrLocation></GrLocation>
                    </Grid>
                    <Grid className="category-inner-name">
                        <FormattedMessage id="storeLocator" defaultMessage="Store Locator" />
                    </Grid>
                </Grid>
                <Grid
                    className="category-inner-container"
                    onClick={() => {
                        history.push(`/${store_code}/wishlist`);
                        setDrawerState(false);
                    }}
                >
                    <Grid className="category-inner-icon">
                        <BsHeart />
                    </Grid>
                    <Grid className="category-inner-name">
                        <FormattedMessage id="Wishlist" defaultMessage="Wishlist" />
                    </Grid>
                </Grid>
                <Grid
                    className="category-inner-container"
                    onClick={() => {
                        setIsAssistanceOpen(true);
                        setDrawerState(false);
                    }}
                >
                    <Grid className="category-inner-icon">
                        <IoIosHelpCircleOutline></IoIosHelpCircleOutline>
                    </Grid>
                    <Grid className="category-inner-name">
                        <FormattedMessage id="assistance" defaultMessage="Assistance" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid className="category-container">
                <Grid
                    className="category-inner-container"
                    onClick={() => {
                        history.push(`/${store_code}/about-us`);
                        setDrawerState(false);
                    }}
                >
                    <Grid className="category-inner-name">
                        <FormattedMessage id="aboutUJ" defaultMessage="About Urban Jungle" />
                    </Grid>
                    <Grid className="category-arrow">
                        <AiFillCaretRight />
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid className="category-container">
                <Grid className="category-inner-container">
                    <Grid className="category-inner-name">
                        <FormattedMessage id="blog" defaultMessage="Blog" />
                    </Grid>
                    <Grid className="category-arrow">
                        <AiFillCaretRight />
                    </Grid>
                </Grid>
            </Grid> */}
            <Grid className="category-container">
                <Grid className="category-inner-container">
                    <Grid className="category-inner-name follow-us">
                        <FormattedMessage id="FollowUs" defaultMessage="Follow Us" />
                    </Grid>
                    <Grid className="category-arrow follow-us-icon-container">
                        <a
                            href="https://www.facebook.com/urbanjunglestore/"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                setDrawerState(false);
                            }}
                        >
                            <FaFacebookF className="follow-us-icon" style={{ color: '#000' }} />
                        </a>
                        <a
                            href="https://www.instagram.com/urban_jungle_official/"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                setDrawerState(false);
                            }}
                        >
                            <FaInstagram style={{ color: '#000' }} />
                        </a>
                    </Grid>
                </Grid>
            </Grid>

            {loginUser && (
                <Grid
                    className="category-container logout-container"
                    onClick={() => {
                        dispatch(storeActions.setLoginUserDetails(null));
                        dispatch(storeActions.setQuoteId(null));
                        dispatch(wishlistActions.getAllWishListRequestSuccess([]));
                        dispatch(cartActions.getMyCartRequestSuccess(null));
                        dispatch(storeActions.setGDPRData(null));
                        dispatch(jwtActions.setOrderJWTToken(null));
                        dispatch(storeActions.ecomLogin(false));
                        history.push(`/${store_code}/sign-in`);
                        setDrawerState(false);
                    }}
                >
                    <Grid className="category-inner-container" style={{ display: 'flex', alignItems: 'center' }}>
                        <Grid className="category-inner-icon">
                            <AiOutlinePoweroff></AiOutlinePoweroff>
                        </Grid>
                        <Grid className="category-inner-name">
                            <FormattedMessage id="Logout" defaultMessage={`Logout`} />
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default LevelOneMenu;
