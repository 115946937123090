import authSagas from 'components/Global/redux/sagas';
import { all, fork, select, takeEvery } from 'redux-saga/effects';
import communicationPreferencesSagas from '../components/account/CommunicationPerderences/redux/sagas';
import myAddresses from '../components/account/MyAddresses/redux/sagas';
import myOrders from '../components/account/MyOrder/redux/sagas';
import customerSagas from '../components/account/MyProfile/redux/sagas';
import wishListSagas from '../components/account/WishList/redux/sagas';
import brandSaga from '../components/brands/redux/sagas';
import guestSubscribe from '../components/common/Footer/Redux/sagas';
import menuSagas from '../components/common/NavigationBar/redux/sagas';
import loginSagas from '../components/login/redux/sagas';
import cartSagas from '../components/main/cart/Basket/redux/sagas';
import deliverySagas from '../components/main/cart/Checkout/Delivery/redux/sagas';
import StorePickupSagas from '../components/main/cart/Checkout/Delivery/StorePickup/redux/sagas';
import orderSummarySagas from '../components/main/cart/Checkout/OrderConfirmation/redux/sagas';
import checkoutPaymentSagas from '../components/main/cart/Checkout/Payment/redux/sagas';
import homepageSagas from '../components/main/home/redux/sagas';
import PDPSagas from '../components/main/pdp/redux/sagas';
import staticSagas from '../components/main/static/redux/sagas';
import storeLocator from '../components/main/storeLocator/Redux/saga';
import plpSagas from '../components/plp/redux/sagas';
import registerSagas from '../components/register/redux/sagas';
import StoreInfoSagas from '../components/storeInfo/redux/sagas';
import AlertPopupSagas from '../components/common/AlertPopup/redux/sagas';

function* watchAndLog() {
    yield takeEvery('*', function* logger(action) {
        const state = yield select();
        if (process.env.NODE_ENV === 'development') {
            console.debug('action', action);
            console.debug('state after', state);
        }
    });
}

export default function* root() {
    yield all([
        fork(watchAndLog),
        fork(menuSagas),
        fork(homepageSagas),
        fork(loginSagas),
        fork(staticSagas),
        fork(PDPSagas),
        fork(StoreInfoSagas),
        fork(plpSagas),
        fork(registerSagas),
        fork(cartSagas),
        fork(brandSaga),
        fork(deliverySagas),
        fork(checkoutPaymentSagas),
        fork(StorePickupSagas),
        fork(orderSummarySagas),
        fork(myAddresses),
        fork(myOrders),
        fork(communicationPreferencesSagas),
        fork(authSagas),
        fork(customerSagas),
        fork(wishListSagas),
        fork(guestSubscribe),
        fork(storeLocator),
        fork(AlertPopupSagas),
    ]);
}
