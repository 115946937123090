const { createAction } = require('redux-actions');
const SUBSCRIBE_EMAIL_GUEST_REQUEST = 'UJ/SUBSCRIBE_EMAIL_GUEST_REQUEST';
const SUBSCRIBE_EMAIL_GUEST_REQUEST_SUCCESS = 'UJ/SUBSCRIBE_EMAIL_GUEST_REQUEST_SUCCESS';
const SUBSCRIBE_EMAIL_GUEST_REQUEST_FAILED = 'UJ/SUBSCRIBE_EMAIL_GUEST_REQUEST_FAILED';

const SubscribeEmailGuestRequest = createAction(SUBSCRIBE_EMAIL_GUEST_REQUEST);
const SubscribeEmailGuestRequestSuccess = createAction(SUBSCRIBE_EMAIL_GUEST_REQUEST_SUCCESS);
const SubscribeEmailGuestRequestFailed = createAction(SUBSCRIBE_EMAIL_GUEST_REQUEST_FAILED);

export const actions = {
    SubscribeEmailGuestRequest,
    SubscribeEmailGuestRequestSuccess,
    SubscribeEmailGuestRequestFailed,
};

export const types = {
    SUBSCRIBE_EMAIL_GUEST_REQUEST,
    SUBSCRIBE_EMAIL_GUEST_REQUEST_SUCCESS,
    SUBSCRIBE_EMAIL_GUEST_REQUEST_FAILED,
};
