import { useEffect, useState } from 'react';
import 'react-ig-feed/dist/index.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'swiper/swiper.min.css';
import './components/account/CancelOrder/cancelOrder.css';
import './components/account/CommunicationPerderences/communicationPref.css';
import './components/account/Components/MobileAccountTab/style.css';
import './components/account/Components/MobilePageHeader/MobilePage.css';
import './components/account/MyAccount/MyAccount.css';
import './components/account/MyAddresses/MyAddresses.css';
import './components/account/MyOrder/myOrder.css';
import './components/account/MyOrderDetails/myOrderDetails.css';
import './components/account/MyProfile/MyProfile.css';
import './components/account/WishList/myWishlist.css';
import './components/plp/components/ApplyFilters/applyFilter.css';
import './components/plp/components/CategoryBanner/categoryBanner.css';
import './components/plp/components/CategoryDescription/categoryDescription.css';
import './components/plp/components/Filter/filter.css';
import './components/plp/components/ProductItem/productItem.css';
import './components/plp/components/SortBy/sortBy.css';
import './components/plp/css/PLP.css';
import AppRouters from './routes';
import { history } from './store';
import { useSelector } from 'react-redux';

const App = () => {
    const clerkKeys = JSON.parse(process.env.REACT_APP_CLERKIO_STORE_LIST);
    const [isLoading, setLoading] = useState(true);
    const store_id = useSelector((state) => state && state.storeInfo && state.storeInfo.store_id);


    function fakeRequest() {
        return new Promise((resolve) => setTimeout(() => resolve(), 2));
    }
    useEffect(() => {
        fakeRequest().then(() => {
            const el = document.querySelector('.loader-container');
            if (el) {
                el.remove();
                setLoading(!isLoading);
            }
        });
    }, [isLoading]);

    useEffect(() => {
        let keyIndex = clerkKeys.find(keys => parseInt(keys.store_id) === parseInt(store_id));
        if (!keyIndex) {
            keyIndex = clerkKeys[0]
          }
        window.Clerk('config', {
            key: keyIndex.clerk_key
        });
    }, [store_id])


    if (isLoading) {
        return null;
    }

    return (
        <div className="App">
            <Router history={history}>
                <Switch>
                    <Route component={AppRouters} />
                </Switch>
            </Router>
        </div>
    );
};

export default App;
