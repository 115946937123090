import { createAction } from 'redux-actions';

//action types
const CHANGE_PASSWORD_REQUEST = 'UJ/CHANGE_PASSWORD_REQUEST';
const CHANGE_PASSWORD_REQUEST_SUCCESS = 'UJ/CHANGE_PASSWORD_REQUEST_SUCCESS';
const CHANGE_PASSWORD_REQUEST_FAILED = 'UJ/CHANGE_PASSWORD_REQUEST_FAILED';

const UPDATE_CUSTOMER_REQUEST = 'UJ/UPDATE_CUSTOMER_REQUEST';
const UPDATE_CUSTOMER_REQUEST_SUCCESS = 'UJ/UPDATE_CUSTOMER_REQUEST_SUCCESS';
const UPDATE_CUSTOMER_REQUEST_FAILED = 'UJ/UPDATE_CUSTOMER_REQUEST_FAILED';

const GET_CUSTOMER_REQUEST = 'UJ/GET_CUSTOMER_REQUEST';
const GET_CUSTOMER_REQUEST_SUCCESS = 'UJ/GET_CUSTOMER_REQUEST_SUCCESS';
const GET_CUSTOMER_REQUEST_FAILED = 'UJ/GET_CUSTOMER_REQUEST_FAILED';
//methods
const changePasswordRequest = createAction(CHANGE_PASSWORD_REQUEST);
const changePasswordRequestSuccess = createAction(CHANGE_PASSWORD_REQUEST_SUCCESS);
const changePasswordRequestFailed = createAction(CHANGE_PASSWORD_REQUEST_FAILED);

const updateCustomerRequest = createAction(UPDATE_CUSTOMER_REQUEST);
const updateCustomerRequestSuccess = createAction(UPDATE_CUSTOMER_REQUEST_SUCCESS);
const updateCustomerRequestFailed = createAction(UPDATE_CUSTOMER_REQUEST_FAILED);

const getCustomerRequest = createAction(GET_CUSTOMER_REQUEST);
const getCustomerRequestSuccess = createAction(GET_CUSTOMER_REQUEST_SUCCESS);
const getCustomerRequestFailed = createAction(GET_CUSTOMER_REQUEST_FAILED);
export const actions = {
    changePasswordRequest,
    changePasswordRequestSuccess,
    changePasswordRequestFailed,

    updateCustomerRequest,
    updateCustomerRequestSuccess,
    updateCustomerRequestFailed,

    getCustomerRequest,
    getCustomerRequestSuccess,
    getCustomerRequestFailed,
};

export const types = {
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_REQUEST_SUCCESS,
    CHANGE_PASSWORD_REQUEST_FAILED,

    UPDATE_CUSTOMER_REQUEST,
    UPDATE_CUSTOMER_REQUEST_SUCCESS,
    UPDATE_CUSTOMER_REQUEST_FAILED,

    GET_CUSTOMER_REQUEST,
    GET_CUSTOMER_REQUEST_SUCCESS,
    GET_CUSTOMER_REQUEST_FAILED,
};
