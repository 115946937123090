import { FormattedMessage } from 'react-intl';

const title = {
    gender: <FormattedMessage id="NavBar.Kind" default="By Gender" />,
    category: <FormattedMessage id="NavBar.Category" default="By Category" />,
    brand: <FormattedMessage id="NavBar.Brand" default="By Brand" />,
    trend: <FormattedMessage id="NavBar.Trend" default="By Trend" />,
    more: <FormattedMessage id="NavBar.More" default="'By More'" />,
};
export default title;
