import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandlers = {
    [types.GET_CONTACT_US_DATA]: (state) => ({
        ...state,
        contactUsLoading: true,
    }),
    [types.GET_CONTACT_US_DATA_FAILED]: (state) => ({
        ...state,
        contactUsLoading: false,
        contactus: {
            instagram: '',
            facebook: '',
            youtube: null,
            whatsapp: null,
            contactnumber_uae: '',
            contactnumber_ksa: '',
            contactnumber_int: '',
            contact_address: '',
            contact_email: '',
        },
    }),
    [types.GET_CONTACT_US_DATA_SUCCESS]: (state, { payload }) => ({
        ...state,
        contactUsLoading: false,
        contactus: payload,
    }),

    [types.GET_CMS_PAGE_DATA_REQUEST]: (state) => ({
        ...state,
        cmsPageLoading: true,
    }),
    [types.GET_CMS_PAGE_DATA_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        cmsPageLoading: false,
        cmsData: payload || {},
    }),
    [types.GET_CMS_PAGE_DATA_REQUEST_FAILED]: (state) => ({
        ...state,
        cmsPageLoading: false,
        cmsData: {},
    }),
    [types.GET_FAQ_DATA_REQUEST]: (state) => ({
        ...state,
        faqLoading: true,
    }),
    [types.GET_FAQ_DATA_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        faqLoading: false,
        faqData: payload || [],
    }),
    [types.GET_FAQ_DATA_REQUEST_FAILED]: (state) => ({
        ...state,
        faqLoading: false,
        faqData: [],
    }),
    [types.GET_PRIVACY_DATA]: (state) => ({
        ...state,
        privacyLoading: true,
    }),
    [types.GET_PRIVACY_DATA_FAILED]: (state) => ({
        ...state,
        privacyLoading: false,
        privacy: {},
    }),
    [types.GET_PRIVACY_DATA_SUCCESS]: (state, { payload }) => ({
        ...state,
        privacyLoading: false,
        privacy: payload,
    }),
    [types.GET_ABOUT_US_DATA]: (state) => ({
        ...state,
        aboutUsLoading: true,
    }),
    [types.GET_ABOUT_US_DATA_FAILED]: (state) => ({
        ...state,
        aboutUsLoading: false,
        aboutUs: {},
    }),
    [types.GET_ABOUT_US_DATA_SUCCESS]: (state, { payload }) => ({
        ...state,
        aboutUsLoading: false,
        aboutUs: payload,
    }),
};

export default handleActions(actionHandlers, {
    contactUsLoading: false,
    contactus: {
        instagram: '',
        facebook: '',
        youtube: null,
        whatsapp: null,
        contactnumber_uae: '',
        contactnumber_ksa: '',
        contactnumber_int: '',
        contact_address: '',
        contact_email: '',
    },

    cmsPageLoading: false,
    cmsData: {},
    faqData: [],
    faqLoading: false,
    privacyLoading: false,
    privacy: {},
    aboutUsLoading: false,
    aboutUs: {},
});
