import api from 'api';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { routesgtmevents } from 'service/GTMEvent';
import { ApiErrorToast, ApiFalseMessageToast } from '../../../../../../utils/CommonApiMessage';
import { actions, types } from './actions';
import { actions as paymentActions } from '../../Payment/redux/actions';

const jwtToken = (state) => state && state.jwt && state.jwt.orderJWT;

const getOrderSummaryReq = function* getOrderSummaryReq({ payload }) {
    routesgtmevents(window.location.href, 'Order summary', 'purchase');
    try {
        const jwt = yield select(jwtToken);
        const { data } = yield call(api.orderSummaryReq, payload.reqData, jwt);

        api.pushLog({message: "getOrderSummaryReq orderSummaryReq response", data: {request: payload.reqData, response: data}});
        if (data && data.status) {
            yield put(actions.getOrderSummaryReqSuccess(data.order_data));
            let retryReqData = {
                orderIncrementId: parseInt(data.order_data.order_number),
            }
            yield put(actions.getRetryPaymentInfoReq({ retryReqData }));
            if (payload.reqData.screen === "RETRY_SCREEN") {
                yield put(paymentActions.getPaymentOptionReq({
                    quote_id: data.order_data.order_quote_id,
                    store_id: payload.reqData.store_id,
                    retryAttempt: true,
                }));
            }
        } else {
            yield put(actions.getOrderSummaryReqFailed());
            ApiFalseMessageToast();
        }
    } catch (err) {
        if (err.response && err.response.data && err.response.data === 'Invalid Token') {
            payload.history.push(`/${payload.storeCode}/not-found`);
        } else {
            ApiErrorToast(err);
        }
        yield put(actions.getOrderSummaryReqFailed());
        api.pushLog({level: "error", message: "getOrderSummaryReq orderSummaryReq error", data: err});
    }
};

const getRetryPaymentInfoReq = function* getRetryPaymentInfoReq({ payload }) {
    const { retryReqData, orderSummaryReq = {}, retryExpired = false } = payload;
    routesgtmevents(window.location.href, 'Order summary', 'Retry Payment');
    try {
        const jwt = yield select(jwtToken);
        const { data } = yield call(api.retryPaymentInfo, retryReqData, jwt);

        api.pushLog({message: "getRetryPaymentInfoReq retryPaymentInfo response", data: {request: retryReqData, response: data}});
        if (data && data.status) {
            if (retryExpired) { yield put(actions.getOrderSummaryReq(orderSummaryReq)) }
            else { yield put(actions.getRetryPaymentInfoReqSuccess(data)); }
        } else {
            yield put(actions.getRetryPaymentInfoReqFailed());
            ApiFalseMessageToast();
        }
    } catch (err) {
        if (err.response && err.response.data && err.response.data === 'Invalid Token') {
            retryReqData.history.push(`/${retryReqData.storeCode}/not-found`);
        } else {
            ApiErrorToast(err);
        }
        yield put(actions.getOrderSummaryReqFailed());
        api.pushLog({level: "error", message: "getRetryPaymentInfoReq error", data: err});
    }
};

const getRedirectUrlReq = function* getRedirectUrlReq({ payload }) {
    try {
        yield call(api.submitAdditionalDetails, payload.reqData.adyen);
        const jwt = yield select(jwtToken);
        const { data } = yield call(api.orderSummaryReq, payload.reqData.orderSummary, jwt);

        api.pushLog({message: "getRedirectUrlReq orderSummaryReq response", data: {request: payload.reqData.orderSummary, response: data}});
        if (data && data.status) {
            yield put(actions.getOrderSummaryReqSuccess(data.order_data));
        } else {
            yield put(actions.getOrderSummaryReqFailed());
            ApiFalseMessageToast();
        }
    } catch (err) {
        if (err.response && err.response.data && err.response.data === 'Invalid Token') {
            payload.history.push(`/${payload.storeCode}/not-found`);
        } else {
            ApiErrorToast(err);
        }
        yield put(actions.getOrderSummaryReqFailed());
        api.pushLog({level: "error", message: "getRedirectUrlReq error", data: err});
    }
};

export default function* sagas() {
    yield takeLatest(types.GET_ORDER_SUMMARY_REQ, getOrderSummaryReq);
    yield takeLatest(types.GET_REDIRECT_ORDER_SUMMARY_REQ, getRedirectUrlReq);
    yield takeLatest(types.GET_RETRY_PAYMENT_REQ, getRetryPaymentInfoReq);
}
