import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ApiErrorToast, ApiFalseMessageToast } from '../../../../utils/CommonApiMessage';
import api from '../../api';
import { actions, types } from './actions';

const getAllAccountAddressesReq = function* getAllAccountAddressesReq({ payload }) {
    try {
        const { data } = yield call(api.getAllAddress, payload);
        if (data && data.status) {
            yield put(
                actions.getAllAccountAddressRequestSuccess({
                    addresses: data && data.addressData,
                    carrier_code: data.carrier_code,
                }),
            );
        } else {
            ApiFalseMessageToast();
            yield put(actions.getAllAccountAddressRequestFailed());
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.getAllAccountAddressRequestFailed());
    }
};

const saveOrEditAccountAddressReq = function* saveOrEditAccountAddressReq({ payload }) {
    try {
        // const { UserId, store_id } = payload;
        const { data } = yield call(api.saveAddress, payload);
        yield put(actions.saveAccountAddressRequestSuccess());
        yield put(
            actions.getAllAccountAddressRequest({
                customerid: data.customer_details.customer_id,
                quote_id: data.customer_details.quote_id,
            }),
        );
        if (data && data.status) {
            toast.success(data && data.message);
        } else {
            ApiFalseMessageToast();
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.saveAccountAddressRequestFailed());
    }
};

const deleteAccountAddressReq = function* deleteAccountAddressReq({ payload }) {
    try {
        const { addressId, quote_id, customerid } = payload;
        const { data } = yield call(api.deleteAddress, {
            addressId: addressId,
            quote_id: quote_id,
            customerid: customerid,
        });
        yield put(actions.deleteAccountAddressRequestSuccess());
        yield put(
            actions.getAllAccountAddressRequest({
                customerid: payload.customerid,
                quote_id: quote_id,
            }),
        );
        if (data && data.status) {
            toast.success(data && data.message);
        } else {
            ApiFalseMessageToast();
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.deleteAccountAddressRequestFailed());
    }
};

export default function* sagas() {
    yield takeLatest(types.GET_ALL_ACCOUNT_ADDRESSES_REQUEST, getAllAccountAddressesReq);
    yield takeLatest(types.SAVE_ACCOUNT_ADDRESS_REQUEST, saveOrEditAccountAddressReq);
    yield takeLatest(types.DELETE_ACCOUNT_ADDRESS_REQUEST, deleteAccountAddressReq);
}
