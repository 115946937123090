import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../api';
import { ApiErrorToast, ApiFalseMessageToast } from '../../../../utils/CommonApiMessage';
import { actions, types } from './actions';

const getMenuListReq = function* getMenuListReq({ payload }) {
    try {
        const res = yield call(api.getMenuList, payload);
        const data = res.data;
        if (data.status) {
            yield put(actions.getMenuRequestSuccess(data || []));
        } else {
            yield put(actions.getMenuRequestFailed());
            ApiFalseMessageToast();
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.getMenuRequestFailed());
    }
};

const getAutoSearchReq = function* getAutoSearchReq({ payload }) {
    try {
        const { q, storeid } = payload;
        const { data } = yield call(api.searchPredictive, { q, storeid });
        if (data.status) {
            yield put(actions.getAutoSearchRequestSuccess(data || {}));
        } else {
            yield put(actions.getAutoSearchRequestFailed());
            ApiFalseMessageToast();
        }
    } catch (err) {
        yield put(actions.getAutoSearchRequestFailed());
        ApiErrorToast(err);
    }
};

export default function* sagas() {
    yield takeLatest(types.GET_MENU_REQUEST, getMenuListReq);
    yield takeLatest(types.GET_AUTOSEARCH_REQUEST, getAutoSearchReq);
}
