import api from 'api';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { routesgtmevents } from 'service/GTMEvent';
import { ApiErrorToast, ApiFalseMessageToast } from '../../../../../../utils/CommonApiMessage';
import { actions as authActions } from '../../../../../Global/redux/actions';
import { actions as storeInfoActions } from '../../../../../storeInfo/redux/actions';
import { actions as mycartActions } from '../../../Basket/redux/actions';
import { actions, types } from './actions';

const storeInfo = (state) => state && state.storeInfo;
const checkoutPayment = (state) => state && state.checkoutPayment;
// const loginUser = (state) => state && state.storeInfo.loginUser;

const getPaymentOptionsReq = function* getPaymentOptionsReq({ payload }) {
    routesgtmevents(window.location.href, 'Checkout Payment', 'checkout');
    try {
        const { data } = yield call(api.getPaymentOptions, payload);

        api.pushLog({message: "getPaymentOptionReq getPaymentOptions response", data: {request: payload, response: data}});
        if (data && data.status) {
            yield put(
                actions.getPaymentOptionReqSuccess({
                    paymentOption: data && data.data,
                    cardData: data && data.cart_data,
                }),
            );
        } else {
            yield put(actions.getPaymentOptionReqFailed());
            ApiFalseMessageToast();
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.getPaymentOptionReqFailed());
        api.pushLog({level: "error", message: "getPaymentOptionReq error", data: err });
    }
};

const setPaymentOptionReq = function* setPaymentOptionReq({ payload }) {
    try {
        const { reqData, history, aydenData, func, ipAddres, isPaymentRetry } = payload;
        
        const { data } = yield call(api.setPaymentOptionReq, reqData);

        api.pushLog({message: "setPaymentOptionReq setPaymentOptionReq response", data: {request: reqData, response: data}});

        if (data.status) {
            yield put(actions.placeOrderReq({ reqData, history, aydenData, func, ipAddres, isPaymentRetry }));
        } else {
            yield put(actions.setPaymentOptionReqFailed());
            ApiFalseMessageToast();
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.setPaymentOptionReqFailed());
        api.pushLog({level: "error", message: "setPaymentOptionReq error", data: err });
    }
};

const placeOrderReq = function* placeOrderReq({ payload }) {
    try {
        const { reqData, history, aydenData, func, ipAddres, isPaymentRetry } = payload;
        const storeInfoData = yield select(storeInfo);
        yield put(authActions.saveReservationStartTime(null));

        const payloadPlaceOrderReq = {
            isPaymentRetry,
            order_id: reqData.order_id,
            store_id: reqData.store_id,
            quote_id: reqData.quote_id,
        }

        const resPlaceOrder = yield call(api.placeOrderReq, payloadPlaceOrderReq);

        api.pushLog({message: "placeOrderReq placeOrderReq response", data: {request: payloadPlaceOrderReq, response: resPlaceOrder}});

        const data = resPlaceOrder.data;
        const jwt = resPlaceOrder.headers && resPlaceOrder.headers['x-access-token'];
        yield put(actions.setOrderJWTToken(jwt));
        if (data.status) {
            try {
                if (reqData.payment_code === 'cashondelivery') {
                    yield put(actions.placeOrderReqSuccess());
                    history.push(
                        `/${storeInfoData && storeInfoData.store_code}/order-summary?orderId=${data && data.order_data && data.order_data.order_number
                        }`,
                    );
                } else {
                    let response = null;
                    if (aydenData.paymentMethod.type === 'klarna_account') {
                        const payloadPaymentForKlarna = {
                            ...aydenData,
                            orderId: data && data.order_data && data.order_data.order_number,
                            origin: window.location.origin,
                            countryCode:
                                reqData.store_id === 8
                                    ? 'IT'
                                    : storeInfoData && storeInfoData.country && storeInfoData.country.toUpperCase(),
                            shopperReference: reqData.quote_id,
                            shopperLocale:
                                storeInfoData && parseInt(storeInfoData.store_id) === 2 ? 'it_IT' : 'en_US',
                            store_id: reqData.store_id,
                            shopperIP: ipAddres,
                            store_code: storeInfoData.store_code,
                            returnUrl: `${window.location.origin}/${storeInfoData.store_code
                                }/order-summary?orderId=${data && data.order_data && data.order_data.order_number}`,
                        }

                        response = yield call(
                            api.initiatePaymentForKlarna,
                            payloadPaymentForKlarna,
                            { crossdomain: true },
                        );
                        api.pushLog({message: "placeOrderReq initiatePaymentForKlarna response", data: {request: payloadPaymentForKlarna, response: response}});
                    } else {
                        const payloadPaymentForCardOrPaypalOrGooglePay = {
                            ...aydenData,
                            orderId: data && data.order_data && data.order_data.order_number,
                            origin: window.location.origin,
                            countryCode:
                                reqData.store_id === 8
                                    ? 'AU'
                                    : storeInfoData && storeInfoData.country && storeInfoData.country.toUpperCase(),
                            shopperReference: reqData.quote_id,
                            shopperIP: ipAddres,
                            store_id: reqData.store_id,
                            store_code: storeInfoData.store_code,
                            returnUrl: `${window.location.origin}/${storeInfoData.store_code
                                }/order-summary?orderId=${data && data.order_data && data.order_data.order_number}`,
                        };

                        response = yield call(
                            api.initiatePaymentForCardOrPaypalOrGooglePay,
                            payloadPaymentForCardOrPaypalOrGooglePay,
                            { crossdomain: true },
                        );
                        api.pushLog({message: "placeOrderReq initiatePaymentForCardOrPaypalOrGooglePay response", data: {request: payloadPaymentForCardOrPaypalOrGooglePay, response: response}});
                    }
                    

                    let data1 = response && response.data;
                    if (data1 && data1.adyen && data1.adyen.resultCode === 'RedirectShopper') {
                        if (aydenData.paymentMethod.type === 'scheme') {
                            func(data1);
                        } else {
                            window.location.href =
                                data1 && data1.adyen && data1.adyen.redirect && data1.adyen.redirect.url;

                            if (storeInfoData.loginUser) {
                                yield put(storeInfoActions.setQuoteId(data.order_data.new_quote_id));
                                yield put(
                                    storeInfoActions.setLoginUserDetails({
                                        ...storeInfoData.loginUser,
                                        quote_id: data.order_data.new_quote_id,
                                    }),
                                );
                            } else {
                                yield put(storeInfoActions.getGuestCartRequest());
                                yield put(storeInfoActions.setQuoteId(null));
                                yield put(mycartActions.getMyCartRequestFailed());
                            }
                        }
                    } else if (aydenData.paymentMethod.type === 'paypal') {
                        func(data1);
                    } else {
                        history.push(
                            `/${storeInfoData && storeInfoData.store_code}/order-summary?orderId=${data && data.order_data && data.order_data.order_number
                            }`,
                        );
                        if (storeInfoData.loginUser) {
                            yield put(storeInfoActions.setQuoteId(data.order_data.new_quote_id));
                            yield put(
                                storeInfoActions.setLoginUserDetails({
                                    ...storeInfoData.loginUser,
                                    quote_id: data.order_data.new_quote_id,
                                }),
                            );
                        } else {
                            yield put(storeInfoActions.getGuestCartRequest());
                            yield put(storeInfoActions.setQuoteId(null));
                            yield put(mycartActions.getMyCartRequestFailed());
                        }
                    }

                    yield put(actions.placeOrderReqSuccess());
                }
            } catch (err) {
                history.push(
                    `/${storeInfoData && storeInfoData.store_code}/order-summary?orderId=${data && data.order_data && data.order_data.order_number
                    }`,
                );
                if (storeInfoData.loginUser) {
                    yield put(storeInfoActions.setQuoteId(data.order_data.new_quote_id));
                    yield put(
                        storeInfoActions.setLoginUserDetails({
                            ...storeInfoData.loginUser,
                            quote_id: data.order_data.new_quote_id,
                        }),
                    );
                } else {
                    yield put(storeInfoActions.getGuestCartRequest());
                    yield put(storeInfoActions.setQuoteId(null));
                    yield put(mycartActions.getMyCartRequestFailed());
                }
                ApiErrorToast(err);
                api.pushLog({level: "error", message: "placeOrderReq initiatePaymentForCardOrPaypalOrGooglePay/initiatePaymentForKlarna error", data: err });
            }
        } else {
            ApiFalseMessageToast();
            yield put(actions.placeOrderReqFailed());
            history.push(`/${storeInfoData && storeInfoData.store_code}/cart`);
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.placeOrderReqFailed());
        api.pushLog({level: "error", message: "placeOrderReq placeOrderReq error", data: err });
    }
};

const retryPaymentOrderRequest = function* retryPaymentOrderRequest({ payload }) {
    try {
        const storeInfoData = yield select(storeInfo);
        const checkoutPaymentData = yield select(checkoutPayment);
        const { reqData, aydenData, history, func, ipAddres } = payload;
        const payloadRetryPaymentSave = {
            ...aydenData,
            currency: checkoutPaymentData && checkoutPaymentData.cartData && checkoutPaymentData.cartData.currency,
            payment_code: reqData.payment_code,
            payment_option: reqData.payment_option,
            orderSummary: reqData.orderSummary,
            orderId: reqData.order_id,
            origin: window.location.origin,
            countryCode:
                reqData.store_id === 8
                    ? 'IT'
                    : storeInfoData && storeInfoData.country && storeInfoData.country.toUpperCase(),
            shopperReference: reqData.quote_id,
            shopperIP: ipAddres,
            store_id: reqData.store_id,
            store_code: storeInfoData.store_code,
            returnUrl: `${window.location.origin}/${storeInfoData.store_code
                }/order-summary?orderId=${reqData.order_id}`,
        };

        const { data } = yield call(api.retryPaymentSave, payloadRetryPaymentSave);
        
        api.pushLog({message: "retryPaymentOrderRequest retryPaymentSave response", data: {request: payloadRetryPaymentSave, response: data}});
        if (data.status) {
            let data1 = data && data.data;
            // Paypal handles the url redirect.
            if (aydenData.paymentMethod.type === 'paypal') {
                func(data1);
            } else {
                history.push(
                    `/${storeInfoData && storeInfoData.store_code}/order-summary?orderId=${reqData.order_id}`,
                );
            }
            yield put(actions.placeOrderReqSuccess());
        } else {
            yield put(actions.setPaymentOptionReqFailed());
            ApiFalseMessageToast();
        }
    } catch (err) {
        ApiErrorToast(err);
        yield put(actions.setPaymentOptionReqFailed());
        api.pushLog({level: "error", message: "retryPaymentOrderRequest error", data: err });
    }
};

export default function* sagas() {
    yield takeLatest(types.GET_PAYMENT_OPTION_REQ, getPaymentOptionsReq);
    yield takeLatest(types.SET_PAYMENT_OPTION_REQ, setPaymentOptionReq);
    yield takeLatest(types.PLACE_ORDER_REQ, placeOrderReq);
    yield takeLatest(types.RETRY_PAYMENT_REQ, retryPaymentOrderRequest);
}
