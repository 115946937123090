import { createAction } from 'redux-actions';

//Reservation
const GET_RESERVATION_CONFIG_REQUEST = 'ELC@OROB/GET_RESERVATION_CONFIG_REQUEST';
const GET_RESERVATION_CONFIG_REQUEST_SUCCESS = 'ELC@OROB/GET_RESERVATION_CONFIG_REQUEST_SUCCESS';
const GET_RESERVATION_CONFIG_REQUEST_FAILED = 'ELC@OROB/GET_RESERVATION_CONFIG_REQUEST_FAILED';

const ADD_UPDATE_RESERVATION_REQUEST = 'ELC@OROB/ADD_UPDATE_RESERVATION_REQUEST';
const ADD_UPDATE_RESERVATION_REQUEST_SUCCESS = 'ELC@OROB/ADD_UPDATE_RESERVATION_REQUEST_SUCCESS';
const ADD_UPDATE_RESERVATION_REQUEST_FAILED = 'ELC@OROB/ADD_UPDATE_RESERVATION_REQUEST_FAILED';

const REMOVE_RESERVATION_REQUEST = 'ELC@OROB/REMOVE_RESERVATION_REQUEST';
const REMOVE_RESERVATION_REQUEST_SUCCESS = 'ELC@OROB/REMOVE_RESERVATION_REQUEST_SUCCESS';
const REMOVE_RESERVATION_REQUEST_FAILED = 'ELC@OROB/REMOVE_RESERVATION_REQUEST_FAILED';

const EXTEND_RESERVATION_TIME_REQUEST = 'ELC@OROB/EXTEND_RESERVATION_TIME_REQUEST';
const EXTEND_RESERVATION_TIME_REQUEST_SUCCESS = 'ELC@OROB/EXTEND_RESERVATION_TIME_REQUEST_SUCCESS';
const EXTEND_RESERVATION_TIME_REQUEST_FAILED = 'ELC@OROB/EXTEND_RESERVATION_TIME_REQUEST_FAILED';

const SAVE_RESERVATION_START_TIME_REQUEST = 'ELC@OROB/SAVE_RESERVATION_START_TIME_REQUEST';

// action methods

const getReservationConfigRequest = createAction(GET_RESERVATION_CONFIG_REQUEST);
const getReservationConfigRequestSuccess = createAction(GET_RESERVATION_CONFIG_REQUEST_SUCCESS);
const getReservationConfigRequestFailed = createAction(GET_RESERVATION_CONFIG_REQUEST_FAILED);

const addUpdateReservationRequest = createAction(ADD_UPDATE_RESERVATION_REQUEST);
const addUpdateReservationRequestSuccess = createAction(ADD_UPDATE_RESERVATION_REQUEST_SUCCESS);
const addUpdateReservationRequestFailed = createAction(ADD_UPDATE_RESERVATION_REQUEST_FAILED);

const removeReservationRequest = createAction(REMOVE_RESERVATION_REQUEST);
const removeReservationRequestSuccess = createAction(REMOVE_RESERVATION_REQUEST_SUCCESS);
const removeReservationRequestFailed = createAction(REMOVE_RESERVATION_REQUEST_FAILED);

const extendReservationTimeRequest = createAction(EXTEND_RESERVATION_TIME_REQUEST);
const extendReservationTimeRequestSuccess = createAction(EXTEND_RESERVATION_TIME_REQUEST_SUCCESS);
const extendReservationTimeRequestFailed = createAction(EXTEND_RESERVATION_TIME_REQUEST_FAILED);

const saveReservationStartTime = createAction(SAVE_RESERVATION_START_TIME_REQUEST);

export const actions = {
    getReservationConfigRequest,
    getReservationConfigRequestSuccess,
    getReservationConfigRequestFailed,

    addUpdateReservationRequest,
    addUpdateReservationRequestSuccess,
    addUpdateReservationRequestFailed,

    removeReservationRequest,
    removeReservationRequestSuccess,
    removeReservationRequestFailed,

    extendReservationTimeRequest,
    extendReservationTimeRequestSuccess,
    extendReservationTimeRequestFailed,

    saveReservationStartTime,
};

export const types = {
    GET_RESERVATION_CONFIG_REQUEST,
    GET_RESERVATION_CONFIG_REQUEST_SUCCESS,
    GET_RESERVATION_CONFIG_REQUEST_FAILED,

    ADD_UPDATE_RESERVATION_REQUEST,
    ADD_UPDATE_RESERVATION_REQUEST_SUCCESS,
    ADD_UPDATE_RESERVATION_REQUEST_FAILED,

    REMOVE_RESERVATION_REQUEST,
    REMOVE_RESERVATION_REQUEST_SUCCESS,
    REMOVE_RESERVATION_REQUEST_FAILED,

    EXTEND_RESERVATION_TIME_REQUEST,
    EXTEND_RESERVATION_TIME_REQUEST_SUCCESS,
    EXTEND_RESERVATION_TIME_REQUEST_FAILED,

    SAVE_RESERVATION_START_TIME_REQUEST,
};
