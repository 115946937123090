import { actions as authActions } from 'components/Global/redux/actions';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import APIList from '../../../api';
import { ApiErrorToast } from '../../../utils/CommonApiMessage';
import { actions as storeAction } from '../../storeInfo/redux/actions';
import { actions, types } from './actions';

const storecode = (state) => state && state.storeInfo && state.storeInfo.store_code;

const registerUserReq = function* registerUserReq({ payload }) {
    try {
        const store_code = yield select(storecode);
        let data = null;
        let ecomRegister = 0;
        if (payload.social) {
            data = yield call(APIList.socialregister, payload.reqData);
            ecomRegister = 0;
        } else {
            data = yield call(APIList.registerUser, payload.reqData);
            ecomRegister = 1;
        }

        if (data.data.status) {
            toast.success(data.message || `User registered successfully!`);
            yield put(actions.registerUserRequestSuccess());
            if (ecomRegister) {
                yield put(storeAction.ecomLogin(true));
            } else {
                yield put(storeAction.ecomLogin(false));
            }
            yield put(storeAction.setLoginUserDetails(data.data.customer_details));

            yield put(storeAction.setQuoteId(data.data.customer_details && data.data.customer_details.quote_id));
            yield put(
                storeAction.setGDPRData({
                    gdpr_optin: data.data.customer_details && data.data.customer_details.gdpr_optin,
                    gdprmarketing_optin: data.data.customer_details && data.data.customer_details.gdprmarketing_optin,
                    gdprthirdparts_optin: data.data.customer_details && data.data.customer_details.gdprthirdparts_optin,
                    gdpranalytics_optin: data.data.customer_details && data.data.customer_details.gdpranalytics_optin,
                    subscribed_to_newsletter:
                        data.data.customer_details && data.data.customer_details.subscribed_to_newsletter,
                }),
            );
            if (payload.isCheckout) {
                yield put(
                    authActions.addUpdateReservationRequest({
                        reqData: {
                            store_id: payload.reqData && payload.reqData.store_id,
                            quote_id: data.data.customer_details && data.data.customer_details.quote_id,
                            reservation_type: 'checkout',
                        },
                        history: payload.history,
                        isNotRedirect: true,
                    }),
                );
                payload.history.push(`/${store_code}/delivery-details`);
            } else {
                payload.history.push(`/${store_code}`);
            }
        } else {
            data.data.message &&
                data.data.message !== '' &&
                data.data.message.length !== 0 &&
                toast.error(data.data.message);
            yield put(actions.registerUserRequestFailed());
        }
    } catch (err) {
        yield put(actions.registerUserRequestFailed());
        ApiErrorToast(err);
    }
};

export default function* sagas() {
    yield takeLatest(types.REGISTER_USER_REQUEST, registerUserReq);
}
