import { handleActions } from 'redux-actions';
import { types } from './actions';

const actionHandler = {
    [types.GET_ALL_WISHLIST_REQUEST]: (state) => ({
        ...state,
        loader: true,
    }),
    [types.GET_ALL_WISHLIST_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
        addLoader: false,
    }),
    [types.GET_ALL_WISHLIST_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        loader: false,
        addLoader: false,
        wishListItems: payload || [],
    }),

    [types.REMOVE_WISHLIST_ITEM_REQUEST]: (state) => ({
        ...state,
        loader: true,
        addLoader: true,
    }),
    [types.REMOVE_WISHLIST_ITEM_REQUEST_FAILED]: (state) => ({
        ...state,
        loader: false,
        addLoader: false,
    }),
    [types.REMOVE_WISHLIST_ITEM_REQUEST_SUCCESS]: (state) => ({
        ...state,
        loader: false,
        addLoader: false,
    }),

    [types.ADD_WISHLIST_ITEM_REQUEST]: (state) => ({
        ...state,
        addLoader: true,
    }),
    [types.ADD_WISHLIST_ITEM_REQUEST_FAILED]: (state) => ({
        ...state,
        addLoader: false,
    }),
    [types.ADD_WISHLIST_ITEM_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        addLoader: false,
        wishlistArr: payload,
    }),

    [types.ADD_WISHLIST_REMOVECART_ITEM_REQUEST]: (state) => ({
        ...state,
        loader: true,
        addLoader: true,
    }),
    [types.ADD_WISHLIST_REMOVECART_ITEM_REQUEST_SUCCESS]: (state) => ({
        ...state,
        loader: false,
        addLoader: false,
    }),
    [types.ADD_WISHLIST_REMOVECART_ITEM_REQUEST_FAILED]: (state, { payload }) => ({
        ...state,
        addLoader: false,
        loader: false,
        wishlistArr: payload,
    }),
};

export default handleActions(actionHandler, {
    loader: false,
    addLoader: false,
    wishListItems: [],
    wishlistArr: [],
});
