import { createAction } from 'redux-actions';

const CHECK_DELIVERY_OPTIONS = 'UJ/CHECK_DELIVERY_OPTIONS';
const CHECK_DELIVERY_OPTIONS_SUCCESS = 'UJ/CHECK_DELIVERY_OPTIONS_SUCCESS';
const CHECK_DELIVERY_OPTIONS_FAILED = 'UJ/CHECK_DELIVERY_OPTIONS_FAILED';

const GET_DELIVERY = 'UJ/GET_DELIVERY';
const GET_DELIVERY_SUCCESS = 'UJ/GET_DELIVERY_SUCCESS';
const GET_DELIVERY_FAILED = 'UJ/GET_DELIVERY_FAILED';

const GET_COUNTRY_LIST = 'UJ/GET_COUNTRY_LIST';
const GET_COUNTRY_LIST_SUCCESS = 'UJ/GET_COUNTRY_LIST_SUCCESS';
const GET_COUNTRY_LIST_FAILED = 'UJ/GET_COUNTRY_LIST_FAILED';

const GET_HOME_DELIVERY_OPTIONS = 'UJ/GET_HOME_DELIVERY_OPTIONS';
const GET_HOME_DELIVERY_OPTIONS_SUCCESS = 'UJ/GET_HOME_DELIVERY_OPTIONS_SUCCESS';
const GET_HOME_DELIVERY_OPTIONS_FAILED = 'UJ/GET_HOME_DELIVERY_OPTIONS_FAILED';

const SET_DELIVERY = 'UJ/SET_DELIVERY';
const SET_DELIVERY_SUCCESS = 'UJ/SET_DELIVERY_SUCCESS';
const SET_DELIVERY_FAILED = 'UJ/SET_DELIVERY_FAILED';

const SAVE_DELIVERY_ADDRESS_REQUEST = 'UJ/SAVE_DELIVERY_ADDRESS_REQUEST';
const SAVE_DELIVERY_ADDRESS_REQUEST_SUCCESS = 'UJ/SAVE_DELIVERY_ADDRESS_REQUEST_SUCCESS';
const SAVE_DELIVERY_ADDRESS_REQUEST_FAILED = 'UJ/SAVE_DELIVERY_ADDRESS_REQUEST_FAILED';

const SAVE_DELIVERY_ADDRESS = 'BB/SAVE_DELIVERY_ADDRESS';

const UPDATE_ADDRESS_REQUEST = 'UJ/UPDATE_ADDRESS_REQUEST';

const saveDeliveryAddress = createAction(SAVE_DELIVERY_ADDRESS);

const checkDeliveryOptionsRequest = createAction(CHECK_DELIVERY_OPTIONS);
const checkDeliveryOptionsSuccess = createAction(CHECK_DELIVERY_OPTIONS_SUCCESS);
const checkDeliveryOptionsFailed = createAction(CHECK_DELIVERY_OPTIONS_FAILED);

const getDeliveryRequest = createAction(GET_DELIVERY);
const getDeliverySuccess = createAction(GET_DELIVERY_SUCCESS);
const getDeliveryFailed = createAction(GET_DELIVERY_FAILED);

const getCountryList = createAction(GET_COUNTRY_LIST);
const getCountryListSuccess = createAction(GET_COUNTRY_LIST_SUCCESS);
const getCountryListFailed = createAction(GET_COUNTRY_LIST_FAILED);

const getHomeDeliveryOptionsRequest = createAction(GET_HOME_DELIVERY_OPTIONS);
const getHomeDeliveryOptionsSuccess = createAction(GET_HOME_DELIVERY_OPTIONS_SUCCESS);
const getHomeDeliveryOptionsFailed = createAction(GET_HOME_DELIVERY_OPTIONS_FAILED);

const setDelivery = createAction(SET_DELIVERY);
const setDeliverySuccess = createAction(SET_DELIVERY_SUCCESS);
const setDeliveryFailed = createAction(SET_DELIVERY_FAILED);

const saveDeliveryAddressRequest = createAction(SAVE_DELIVERY_ADDRESS_REQUEST);
const saveDeliveryAddressRequestSuccess = createAction(SAVE_DELIVERY_ADDRESS_REQUEST_SUCCESS);
const saveDeliveryAddressRequestFailed = createAction(SAVE_DELIVERY_ADDRESS_REQUEST_FAILED);

const updateUserAddress = createAction(UPDATE_ADDRESS_REQUEST);

export const actions = {
    checkDeliveryOptionsRequest,
    checkDeliveryOptionsSuccess,
    checkDeliveryOptionsFailed,

    getDeliveryRequest,
    getDeliverySuccess,
    getDeliveryFailed,

    getCountryList,
    getCountryListSuccess,
    getCountryListFailed,

    getHomeDeliveryOptionsRequest,
    getHomeDeliveryOptionsSuccess,
    getHomeDeliveryOptionsFailed,

    setDelivery,
    setDeliverySuccess,
    setDeliveryFailed,

    saveDeliveryAddressRequest,
    saveDeliveryAddressRequestSuccess,
    saveDeliveryAddressRequestFailed,

    saveDeliveryAddress,

    updateUserAddress
};

export const types = {
    CHECK_DELIVERY_OPTIONS,
    CHECK_DELIVERY_OPTIONS_SUCCESS,
    CHECK_DELIVERY_OPTIONS_FAILED,

    GET_DELIVERY,
    GET_DELIVERY_SUCCESS,
    GET_DELIVERY_FAILED,

    GET_COUNTRY_LIST,
    GET_COUNTRY_LIST_SUCCESS,
    GET_COUNTRY_LIST_FAILED,

    GET_HOME_DELIVERY_OPTIONS,
    GET_HOME_DELIVERY_OPTIONS_SUCCESS,
    GET_HOME_DELIVERY_OPTIONS_FAILED,

    SET_DELIVERY,
    SET_DELIVERY_SUCCESS,
    SET_DELIVERY_FAILED,

    SAVE_DELIVERY_ADDRESS_REQUEST,
    SAVE_DELIVERY_ADDRESS_REQUEST_SUCCESS,
    SAVE_DELIVERY_ADDRESS_REQUEST_FAILED,

    SAVE_DELIVERY_ADDRESS,

    UPDATE_ADDRESS_REQUEST
};
