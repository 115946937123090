import { actions as wishlistActions } from 'components/account/WishList/redux/actions';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { addToCart, productDetails, routesgtmevents } from 'service/GTMEvent';
import APIList from '../../../../api';
import { ApiErrorToast, ApiFalseMessageToast } from '../../../../utils/CommonApiMessage';
import { actions as storeInfoAction } from '../../../storeInfo/redux/actions';
import { actions as cartActions } from '../../cart/Basket/redux/actions';
import { actions, types } from './actions';

const login_user = (state) =>
    state && state.storeInfo && state.storeInfo.loginUser && state.storeInfo.loginUser.customer_id;
const guest_user = (state) =>
    state && state.storeInfo && state.storeInfo.guest_user && state.storeInfo.guest_user.guestId;

const getPDPDataReq = function* getPDPDataReq({ payload }) {
    try {
        const { data } = yield call(APIList.getPDPData, payload);
        if (data.status) {
            routesgtmevents(window.location.href, data.product && data.product[0].meta_title, 'product');
            yield put(actions.getPDPDataReqSuccess(data.product && data.product[0]));
            productDetails(data.product && data.product[0]);
            yield getPDPTrendingDataReq({ ...payload, productId: data.product[0].id })
            yield getPDPBestSellerDataReq({ ...payload, productId: data.product[0].id })
        } else {
            yield put(actions.getPDPDataReqFailed());
        }
    } catch (err) {
        yield put(actions.getPDPDataReqFailed());
        // ApiErrorToast(err);
    }
};

const getPDPTrendingDataReq = function* getPDPTrendingDataReq({ store_id, productId }) {
    try {
        const { data } = yield call(APIList.recommendationsTrending, { storeid: store_id, productId });
        if (data.status) {
            yield put(actions.getPDPTrendingDataReqSuccess(data.data && data.data));
        } else {
            yield put(actions.getPDPTrendingDataReqFailed());
        }
    } catch (err) {
        yield put(actions.getPDPTrendingDataReqFailed());
        // ApiErrorToast(err);
    }
};

const getPDPBestSellerDataReq = function* getPDPBestSellerDataReq({ store_id, productId }) {
    try {
        const { data } = yield call(APIList.recommendationsRecentlyBought, { storeid: store_id, productId });
        if (data.status) {
            yield put(actions.getPDPBestSellerDataReqSuccess(data.data && data.data));
        } else {
            yield put(actions.getPDPBestSellerDataReqFailed());
        }
    } catch (err) {
        yield put(actions.getPDPBestSellerDataReqFailed());
        // ApiErrorToast(err);
    }
};

const getQuickPDPDataReq = function* getQuickPDPDataReq({ payload }) {
    try {
        const { data } = yield call(APIList.getPDPData, payload);

        if (data.status) {
            routesgtmevents(window.location.href, data.product && data.product[0].meta_title, 'product');
            yield put(actions.getQuickPDPDataReqSuccess(data.product && data.product[0]));
        } else {
            yield put(actions.getQuickPDPDataReqFailed());
        }
    } catch (err) {
        yield put(actions.getQuickPDPDataReqFailed());
        // ApiErrorToast(err);
    }
};

const addToCartReq = function* addToCartReq({ payload }) {
    try {
        const { payloadData, guestId, loginUser, store_id, store_code, removeWishlist, product } = payload;
        if (!loginUser) {
            const { data } = yield call(APIList.addToCartGuestReq, payloadData, guestId, store_code);
            if (data) {
                // toast.success(
                //     data.message || <FormattedMessage defaultMessage="Add To cart Successfully!" id="Addtocart" />,
                // );

                let size =
                    payloadData.cartItem &&
                    payloadData.cartItem &&
                    payloadData.cartItem.extension_attributes &&
                    payloadData.cartItem.extension_attributes.size_value;
                addToCart(product, size);
                yield put(storeInfoAction.setQuoteId(data.quote_id));
                yield put(storeInfoAction.getGuestCartRequestSuccess(data.guest_quote_id));
                const customer_id = yield select(login_user);
                const guest_id = yield select(guest_user);
                yield put(
                    cartActions.getMyCartRequest({
                        quote_id: data.quote_id,
                        store_id: store_id,
                        customer_id: customer_id || guest_id,
                    }),
                );
            }
            yield put(actions.addToCartReqSuccess());
        } else {
            const { data } = yield call(APIList.addToCartLoginReq, payloadData, store_code);
            if (data) {
                // toast.success(
                //     data.message || <FormattedMessage defaultMessage="Add To cart Successfully!" id="Addtocart" />,
                // );
                let size =
                    payloadData.cartItem &&
                    payloadData.cartItem &&
                    payloadData.cartItem.extension_attributes &&
                    payloadData.cartItem.extension_attributes.size_value;
                addToCart(product, size);
                yield put(storeInfoAction.setQuoteId(data.quote_id));
                const customer_id = yield select(login_user);
                const guest_id = yield select(guest_user);
                yield put(
                    cartActions.getMyCartRequest({
                        quote_id: data.quote_id,
                        store_id: store_id,
                        customer_id: customer_id || guest_id,
                    }),
                );
            }
            yield put(actions.addToCartReqSuccess());
            if (removeWishlist) {
                yield put(wishlistActions.removeWishlistRequest(removeWishlist));
            }
        }
    } catch (err) {
        yield put(actions.addToCartReqFailed());
        if (err.response.status === 500) {
            toast.error(<FormattedMessage defaultMessage="Requested Quantity not available." id="AddtocartError" />);
        }
        //ApiErrorToast(err);
        // toast.error(err.response && err.response.data && err.response.data.message);
    }
};

const getNotifyMeReq = function* getNotifyMeReq({ payload }) {
    try {
        const { data } = yield call(APIList.notifyMeReq, payload);
        if (data.status) {
            yield put(actions.notifyMeReqSuccess());
            data.message && toast.success(data.message);
        } else {
            yield put(actions.notifyMeReqFailed());
            ApiFalseMessageToast();
        }
    } catch (err) {
        yield put(actions.notifyMeReqFailed());
        ApiErrorToast(err);
    }
};

export default function* sagas() {
    yield takeLatest(types.GET_PDP_DATA_REQ, getPDPDataReq);
    yield takeLatest(types.GET_PDP_TRENDING_DATA_REQ, getPDPTrendingDataReq);
    yield takeLatest(types.ADD_TO_CART_REQ, addToCartReq);
    yield takeLatest(types.NOTIFY_ME_REQ, getNotifyMeReq);
    yield takeLatest(types.GET_QUICK_PDP_DATA_REQ, getQuickPDPDataReq);
}
