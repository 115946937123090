import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../api';
import { ApiErrorToast } from '../../../../utils/CommonApiMessage';
import { actions, types } from './actions';

const getContactUsData = function* getContactUsData({ payload }) {
    try {
        const { store_id } = payload;
        const { data } = yield call(api.getContactUs, store_id);
        if (data.status) {
            yield put(actions.getContactUsDataSuccess(data));
        } else {
            yield put(actions.getContactUsDataSuccess(data));
        }
    } catch (err) {
        yield put(actions.getContactUsDataFailed());
        ApiErrorToast(err);
    }
};

const getCmsDataReq = function* getCmsDataReq({ payload }) {
    try {
        const { page, lan, store_id } = payload;
        const { data } = yield call(api.getCMSPageData, { page, lan, store_id });
        if (data.status) {
            yield put(actions.getCmsPageDataReqSuccess(data.data));
        } else {
            yield put(actions.getCmsPageDataReqFailed());
        }
    } catch (err) {
        yield put(actions.getCmsPageDataReqFailed());
        ApiErrorToast(err);
    }
};

const getFAQDataReq = function* getFAQDataReq({ payload }) {
    try {
        const { store_id } = payload;
        const { data } = yield call(api.getFaqData, store_id);
        if (data.status) {
            yield put(actions.getFaqDataReqSuccess(data.data));
        } else {
            yield put(actions.getFaqDataReqFailed());
        }
    } catch (err) {
        yield put(actions.getFaqDataReqFailed());
        ApiErrorToast(err);
    }
};

const getPrivacyData = function* getPrivacyData({ payload }) {
    try {
        const { store_id } = payload;
        const { data } = yield call(api.getPrivacy, store_id);
        if (data.status) {
            yield put(actions.getPrivacyDataSuccess(data));
        } else {
            yield put(actions.getPrivacyDataSuccess(data));
        }
    } catch (err) {
        yield put(actions.getPrivacyDataFailed());
        ApiErrorToast(err);
    }
};
const getAboutUsData = function* getAboutUsData({ payload }) {
    try {
        const { store_id } = payload;
        const { data } = yield call(api.getAboutUs, store_id);
        if (data.status) {
            yield put(actions.getAboutUsDataSuccess(data));
        } else {
            yield put(actions.getAboutUsDataSuccess(data));
        }
    } catch (err) {
        yield put(actions.getAboutUsDataFailed());
        ApiErrorToast(err);
    }
};

export default function* sagas() {
    yield takeLatest(types.GET_CONTACT_US_DATA, getContactUsData);
    yield takeLatest(types.GET_CMS_PAGE_DATA_REQUEST, getCmsDataReq);
    yield takeLatest(types.GET_FAQ_DATA_REQUEST, getFAQDataReq);
    yield takeLatest(types.GET_PRIVACY_DATA, getPrivacyData);
    yield takeLatest(types.GET_ABOUT_US_DATA, getAboutUsData);
}
