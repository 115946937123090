export const whereWeRedirectContinueShoppingHomeOrNot = () => {
    const location = window && window.location;
    const pathArray = (location && location.pathname && location.pathname.split('/')) || [];
    const lastPathName = pathArray[pathArray.length - 1];
    if (
        lastPathName === 'checkout-signIn' ||
        lastPathName === 'delivery-details' ||
        lastPathName === 'checkout-payment' ||
        lastPathName === 'order-summary'||
        lastPathName === 'retry-payment'
    ) {
        return true;
    }
    return false;
};

export const whereWeRedirectCheckoutCartOrNot = () => {
    const location = window && window.location;
    const pathArray = (location && location.pathname && location.pathname.split('/')) || [];
    const lastPathName = pathArray[pathArray.length - 1];
    if (
        lastPathName === 'checkout-signIn' ||
        lastPathName === 'delivery-details' ||
        lastPathName === 'checkout-payment' ||
        lastPathName === 'order-summary'||
        lastPathName === 'retry-payment'
    ) {
        return false;
    }
    return true;
};
